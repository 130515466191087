import React from 'react';
import './CourseSidebar.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleCourseSidebar } from '../../Redux/Actions';
import { CourseDetail } from '../';

class CourseSidebar extends React.Component {

    state = {
        isCourseOpen: false,
        isPrevOpen: false,
        id: 1
    }

    static getDerivedStateFromProps(props, state) {
        return {
            isCourseOpen: props.isCourseOpen,
            isPrevOpen: state.isCourseOpen,
            id: props.id
        }
    }

    closeSidebar = () => {
        this.props.toggleCourseSidebar(false);
    }

    render() {
        const { isCourseOpen, isPrevOpen,id } = this.state;
        return (
            <div className={`coursesidebar-component ${isCourseOpen ? 'active' : isPrevOpen ? 'inactive' : ''}`}>
                <div className="coursesidebar-wrapper">
                    <div className="coursesidebar-content">
                        {id && <CourseDetail id={id} showHeader={true}/>}
                    </div>
                    <div className="coursesidebar-block" onClick={ () => this.closeSidebar() }></div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isCourseOpen: state.Sidebar.isCourseOpen,
        id: state.Sidebar.id,
    }
}

export default connect(mapStateToProps, { toggleCourseSidebar })(withRouter(CourseSidebar));