import React from 'react';
import './App.scss';
import { Sidebar, MobileSidebar, VideoModal, JoinLesson, CreditModal, QualificationModal, AuthCheck, CourseSidebar } from './Components';
import { 
    MyClientsPage, ScheduleLessonPage, SettingsPage, MessagePage, CompletedLessonPage, PendingLessonPage, 
    AlertsPage, TrainingPage, PaymentHistoryPage, MonthlyReportPage, WithdrawPage, BlogPage, BlockSchedulePage, HelpPage, ViralPage,
    TaxPage
} from './Pages';
import Page from './Pages/Page';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import BeatLoader from 'react-spinners/BeatLoader'
import { EventEmitter } from './Utils/events';
import { css } from "@emotion/react";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

const Pages = [
    { url: '/', title: 'My Clients', page: props => <MyClientsPage {...props} /> },
    { url: '/clients', title: 'My Clients', page: props => <MyClientsPage {...props} /> },
    { url: '/clients/:id', title: 'My Clients', page: props => <MyClientsPage {...props} /> },
    { url: '/schedule-lesson', title: 'Schedule a lesson', page: props => <ScheduleLessonPage {...props} /> },
    { url: '/schedule-lesson/:client_id', title: 'Schedule a lesson', page: props => <ScheduleLessonPage {...props} /> },
    { url: '/profile', title: 'My Settings', page: props => <SettingsPage {...props} /> },
    { url: '/message', title: 'Message', page: props => <MessagePage {...props} /> },
    { url: '/completed-lesson', title: 'Completed Lessons', page: props => <CompletedLessonPage {...props} /> },
    { url: '/completed-lesson/:id', title: 'Completed Lessons', page: props => <CompletedLessonPage {...props} /> },
    { url: '/pending-lesson', title: 'Pending Lessons', page: props => <PendingLessonPage {...props} /> },
    { url: '/pending-lesson/:id', title: 'Pending Lessons', page: props => <PendingLessonPage {...props} /> },
    { url: '/alerts', title: 'Alerts', page: props => <AlertsPage {...props} />},
    { url: '/alerts/:id', title: 'Alerts', page: props => <AlertsPage {...props} />},
    { url: '/training', title: 'Training', page: props => <TrainingPage {...props} />},
    { url: '/payment-history', title: 'Payment History', page: props => <PaymentHistoryPage {...props} />},
    { url: '/monthly-report', title: 'Monthly Report', page: props => <MonthlyReportPage {...props} />},
    { url: '/withdraw', title: 'Withdraw', page: props => <WithdrawPage {...props} />},
    { url: '/blog', title: 'Blog', page: props => <BlogPage {...props} />},
    { url: '/block-schedule', title: 'Block Schedule', page: props => <BlockSchedulePage {...props} />},
    { url: '/help', title: 'Help', page: props => <HelpPage {...props} />},
    { url: '/viral', title: 'Viral', page: props => <ViralPage {...props} />},
    { url: '/tax', title: 'Tax', page: props => <TaxPage {...props} />},
];

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

class App extends React.Component {
    state = {
        isBlur: false,
        isSidebarOpen: true,
        isSidebarPrevOpen: true,
        isResize: false,
        loading: false
    }

    static getDerivedStateFromProps(props, state) {
        return {
            isBlur: props.isMobileSidebarOpen || props.isModalOpen || props.isCourseSidebarOpen,
            isSidebarOpen: props.isSidebarOpen,
            isSidebarPrevOpen: state.isSidebarOpen,
            isResize: props.isJoinLessonOpen
        }
    }

    render() {
        const { isBlur, loading, isSidebarOpen, isSidebarPrevOpen, isResize } = this.state;
        EventEmitter.subscribe('isLoading', (event) => this.setState({loading: event}));

        if (isBlur) {
            if (document.body) {
                const body = document.body
                body.classList.add('modal-open');
            }
        } else {
            if (document.body) {
                const body = document.body
                body.classList.remove('modal-open');
            }
        }

        return (
            <BrowserRouter>
                <div className="App">
                    <JoinLesson />
                    <div id="main" className={`${isBlur? 'blur': ''} ${isResize ? 'resize' : ''}`}>
                        <MobileSidebar />
                        <CourseSidebar />
                        <div className={`content-wrapper ${isSidebarOpen ? isSidebarPrevOpen ? '' : 'open' : 'closed'}`}>
                            <Switch>
                                <Route exact path="/auth" component={AuthCheck} />
                                {Pages.map((item, index) => 
                                    <Route exact path={item.url} key={index}
                                        render={() => this.props.isLoggedIn === true
                                        ?  <Page title={item.title} page={item.page} />
                                        : <Redirect to="/auth" />}
                                    />
                                )}
                            </Switch>
                        </div>
                        <Sidebar />
                        <VideoModal />
                        <CreditModal />
                        <QualificationModal />
                    </div>
                </div>
                <div className={loading ? "overlay-loader" : "d-none"}>
                    <BeatLoader
                        css={override}
                        size={30}
                        color={"#FFF"}
                        loading={loading}
                    />
                </div>
                <NotificationContainer />
            </BrowserRouter>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isMobileSidebarOpen: state.Sidebar.isMobileOpen,
        isCourseSidebarOpen: state.Sidebar.isCourseOpen,
        isSidebarOpen: state.Sidebar.isOpen,
        isModalOpen: state.Modal.isVideoModalOpen || state.Modal.isCreditModalOpen || state.Modal.isReviewModalOpen || state.Modal.isQualificationModalOpen,
        isJoinLessonOpen: state.Lesson.isOpen,
        isLoggedIn: state.Authentication.isLoggedIn,
    }
}

export default connect(mapStateToProps, {})(App);
