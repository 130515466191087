/* @flow */

import { combineReducers } from 'redux';

import Sidebar from './Sidebar';
import Modal from './Modal';
import Lesson from './Lesson';
import Authentication from './Authentication';
import Client from './Client';
import Other from './Other';
import Chat from './Chat';
import Payment from './Payment';
import City from './City';
import Level from './Level';

export default combineReducers ({
    Sidebar,
    Modal,
    Lesson,
    Authentication,
    Client,
    Other,
    Chat,
    Payment,
    City,
    Level,
})