import {
    GET_PENDING_LESSON, GET_PENDING_LESSON_SUCCESS, GET_PENDING_LESSON_FAIL, 
    GET_COMPLETED_LESSON, GET_COMPLETED_LESSON_SUCCESS, GET_COMPLETED_LESSON_FAIL, 
    CANCEL_LESSON, CANCEL_LESSON_SUCCESS, CANCEL_LESSON_FAIL, 
    GET_ALL_COURSES, GET_ALL_COURSES_SUCCESS, GET_ALL_COURSES_FAIL, 
    SCHEDULE_LESSON, SCHEDULE_LESSON_SUCCESS, SCHEDULE_LESSON_FAIL, 
    LEAVE_NOTE, LEAVE_NOTE_SUCCESS, LEAVE_NOTE_FAIL,
    GET_AVAILABILITY, GET_AVAILABILITY_SUCCESS, GET_AVAILABILITY_FAIL,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
    getLessons as getLessonsApi,
    cancelLesson as cancelLessonApi,
    getAllCourses as getAllCoursesApi,
    scheduleLesson as scheduleLessonApi,
    leaveNote as leaveNoteApi,
    getAvailability as getAvailabilityApi,    
} from '../../Api';

function* getPendingLesson(payload){
    try {
        const result = yield getLessonsApi("pending");
        if (result.data && result.data.length > 0) {
            yield put({ type: GET_PENDING_LESSON_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_PENDING_LESSON_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_PENDING_LESSON_FAIL, data: [] })
    }
}

export function* watchGetPendingLesson(){
    yield takeLatest(GET_PENDING_LESSON, getPendingLesson)
}

function* getCompletedLesson(payload){
    try {
        const result = yield getLessonsApi("finished");
        if (result.data && result.data.length > 0) {
            yield put({ type: GET_COMPLETED_LESSON_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_COMPLETED_LESSON_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_COMPLETED_LESSON_FAIL, data: [] })
    }
}

export function* watchGetCompletedLesson(){
    yield takeLatest(GET_COMPLETED_LESSON, getCompletedLesson)
}

function* cancelLesson(payload){
    try {
        const result = yield cancelLessonApi(payload.data.lesson_id, payload.data.data);        
        if (result.is_success) {
            yield put({ type: CANCEL_LESSON_SUCCESS, message: result.message })
        } else {
            yield put({ type: CANCEL_LESSON_FAIL, message: result.message })
        }
    } catch (err) {
        yield put({ type: CANCEL_LESSON_FAIL, data: [] })
    }
}

export function* watchCancelLesson(){
    yield takeLatest(CANCEL_LESSON, cancelLesson)
}

function* leaveNote(payload){
    try {
        const result = yield leaveNoteApi(payload.data.lesson_id, payload.data.note);
        if (result.is_success) {
            yield put({ type: LEAVE_NOTE_SUCCESS, message: result.message })
        } else {
            yield put({ type: LEAVE_NOTE_FAIL, message: result.message })
        }
    } catch (err) {
        yield put({ type: LEAVE_NOTE_FAIL, data: [] })
    }
}

export function* watchLeaveNote(){
    yield takeLatest(LEAVE_NOTE, leaveNote)
}

function* getAllCourses(payload){
    try {
        const result = yield getAllCoursesApi()
        if (result.data && result.data.length > 0) {
            yield put({ type: GET_ALL_COURSES_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_ALL_COURSES_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_ALL_COURSES_FAIL, data: [] })
    }
}

export function* watchGetAllCourses(){
    yield takeLatest(GET_ALL_COURSES, getAllCourses)
}

function* scheduleLesson(payload){
    try {
        const result = yield scheduleLessonApi(payload.data);
        if (result.is_success) {
            yield put({ type: SCHEDULE_LESSON_SUCCESS, msg: result.message })
        } else {
            yield put({ type: SCHEDULE_LESSON_FAIL, msg: result.message })
        }
    } catch (err) {
        yield put({ type: SCHEDULE_LESSON_FAIL, data: [] })
    }
}

export function* watchScheduleLesson(){
    yield takeLatest(SCHEDULE_LESSON, scheduleLesson)
}

function* getAvailability(payload){
    try {
        const result = yield getAvailabilityApi(payload.data);
        if (result.availability) {
            yield put({ type: GET_AVAILABILITY_SUCCESS, data: result })
        } else {
            yield put({ type: GET_AVAILABILITY_FAIL, msg: result })
        }
    } catch (err) {
        yield put({ type: GET_AVAILABILITY_FAIL, data: [] })
    }
}

export function* watchgetAvailability(){
    yield takeLatest(GET_AVAILABILITY, getAvailability)
}