import {
    GET_ALL_CITIES, GET_ALL_CITIES_SUCCESS, GET_ALL_CITIES_FAIL, GET_SCHOOL_LIST_SUCCESS, GET_SCHOOL_LIST_FAIL, GET_SCHOOL_LIST,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
	getAllCities as getAllCitiesApi,
} from '../../Api';

function* getAllCities(payload){
    try {
        const result = yield getAllCitiesApi(payload.data);
        if (result.data && result.data.length > 0) {
            yield put({ type: GET_ALL_CITIES_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_ALL_CITIES_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_ALL_CITIES_FAIL, data: [] })
    }
}

export function* watchGetAllCities(){
    yield takeLatest(GET_ALL_CITIES, getAllCities)
}