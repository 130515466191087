import {
    GET_MESSAGE_USER_LIST, GET_MESSAGE_USER_LIST_SUCCESS, GET_MESSAGE_USER_LIST_FAIL, 
    GET_MESSAGES_DETAIL, GET_MESSAGES_DETAIL_SUCCESS, GET_MESSAGES_DETAIL_FAIL, 
    SEND_MESSAGE, SEND_MESSAGE_SUCCESS, SEND_MESSAGE_FAIL,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
    getMessageUserList as getMessageUserListApi,
    getMessageDetail as getMessageDetailApi,
    sendMessage as sendMessageApi,
} from '../../Api';

function* getMessageUserList(payload){
    try {
        const result = yield getMessageUserListApi();
        if (result.data && result.data.length > 0) {
            yield put({ type: GET_MESSAGE_USER_LIST_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_MESSAGE_USER_LIST_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_MESSAGE_USER_LIST_FAIL, data: [] })
    }
}

export function* watchgetMessageUserList(){
    yield takeLatest(GET_MESSAGE_USER_LIST, getMessageUserList)
}

function* getMessageDetail(payload){
    try {
        const result = yield getMessageDetailApi(payload.data.chat_id);

        if (result.data) {
            yield put({ type: GET_MESSAGES_DETAIL_SUCCESS, data: result.data})
        } else {
            yield put({ type: GET_MESSAGES_DETAIL_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_MESSAGES_DETAIL_FAIL, data: [] })
    }
}

export function* watchgetMessageDetail(){
    yield takeLatest(GET_MESSAGES_DETAIL, getMessageDetail)
}

function* sendMessage(payload){
    try {
        const result = yield sendMessageApi(payload.data);
        if (result.data) {
            yield put({ type: SEND_MESSAGE_SUCCESS, data: result.data })
        } else {
            yield put({ type: SEND_MESSAGE_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: SEND_MESSAGE_FAIL, data: [] })
    }
}

export function* watchSendMessage(){
    yield takeLatest(SEND_MESSAGE, sendMessage)
}