import { Api } from './Api';

export function* getAllClients() {
    const jsonData = yield Api.get("/connections/mystudents");
    return jsonData;
}

export function* getSchools() {
    const jsonData = yield Api.get("/schools");
    return jsonData;
}