import { GET_CLIENT_LIST, GET_CLIENT_LIST_SUCCESS, GET_CLIENT_LIST_FAIL, GET_SCHOOL_LIST, GET_SCHOOL_LIST_FAIL, GET_SCHOOL_LIST_SUCCESS } from '../Type';

const INITIAL = {
    client_list: [],
	client_list_success: false,
	
	school_list: [],
    school_list_success: false,
}

export default (state = INITIAL, action) => {
    switch (action.type) {
        case GET_CLIENT_LIST: 
        case GET_CLIENT_LIST_FAIL: {
            return {
                ...state,
                client_list_success: false,
            }
        }
        case GET_CLIENT_LIST_SUCCESS: {
            return {
                ...state,
                client_list: action.data || [],
                client_list_success: true,
            }
		}
		case GET_SCHOOL_LIST:
		case GET_SCHOOL_LIST_FAIL: {
			return {
				...state,
				school_list_success: false,
			}
		}
		case GET_SCHOOL_LIST_SUCCESS: {
			return {
				...state,
				school_list: action.data,
				school_list_success: true,
			}
		}

        default:
            return state;
    }
}