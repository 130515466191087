import React from 'react';
import './AlertsPage.scss';
import search from '../../Assets/Icon/search.svg';
import { InputBox } from '../../Components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import success from '../../Assets/Icon/success.png';
import { getAlerts, showInterest } from '../../Redux/Actions';
import _ from 'lodash'
import Helper from '../../Utils/Helper';

class AlertsPage extends React.Component {
    state = {
        Alerts: [],
        searchText: '',
        depth: 0,
        selectedAlert: {},
        show_success: false,
        comment: '',
        show_interest: 0,
    }

    static getDerivedStateFromProps(props, state) {
        return {
            depth: props.depth,
            Alerts: props.alert_list
        }
    }

    componentDidMount() {
        this.props.getAlerts();
        if (this.props.match.params.id) {
            let index = this.state.Alerts.findIndex(temp => temp.id === parseInt(this.props.match.params.id, 10));
            this.selectAlert(this.state.Alerts[index]);
        }
    }

    componentDidUpdate(prevProps) {
        
        if (prevProps.show_interest_loading && !this.props.show_interest_loading) {
            if(this.props.show_interest_success){
                this.setState({show_success: true})
                this.props.getAlerts();
                Helper.showToast('success', 3000, this.props.alert_message)
            } else {
                Helper.showToast('error', 3000, this.props.alert_message)
            }
        }
    }

    selectAlert = async (alert) => {
        let temp = alert || {};
        this.setState({ 
            selectedAlert: temp,
            show_success: false,
            comment: ''
        });
        await this.props.formatTitle();
        this.props.updateTitle('push', alert.title, 2);
    }

    showInterest = (id, interest) => {       
        this.setState({
            show_interest: interest
        })
        this.props.showInterest({
            alert_id: id,
            is_interested: interest,
	        comment: this.state.comment,
        })
    }

    render() {
        const { searchText, depth, selectedAlert, Alerts, show_success, comment, show_interest } = this.state;
        const list = Alerts.filter(alert => alert.course.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
        return (
            <div className="alerts-page">
                <div className={`instructor-list v-r ${depth === 0 ? 'activate' : ''}`}>
                    <InputBox className="search-contact" value={searchText} icon={search} placeholder="Search..." onChange={val=>this.setState({searchText:val})} />
                    <div className="instructors v-r scroll-area">
                        {list.map((item, index) => <div className={`instructor-container ${selectedAlert.id === item.id ? 'selected' : ''}`} key={index} onClick={e => this.selectAlert(item)}>
                            <div className="instructor v-c">
                                <div className={`info v-r ${item.is_new ? 'new' : ''}`}>
                                    <h4>{item.course.name}</h4>
                                    <p>{moment(item.created_at.date).format('MMM DD, YYYY')}</p>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
                <div className={`content-area v-r ${depth >= 1 ? 'activate' : ''}`}>
                    {selectedAlert.response && <div className="instructor-content v-r v-c h-c">
                        {show_success || (selectedAlert.response.is_interested === 1 || selectedAlert.response.is_interested === 0)?  <React.Fragment>
                            <img src={success} alt="success" />
                                <h3>You clicked "{_.isEmpty(selectedAlert.response)? (show_interest ===1 ? 'Interested' : 'Not Interested') : (selectedAlert.response.is_interested === 1 ? 'Interested' : 'Not Interested')}"</h3>
                                {(selectedAlert.response.is_interested === 1) || (_.isEmpty(selectedAlert.response) && show_interest ===1 )? 
                                    <h3>Admin will connect you to the student if you are selected for the job.</h3>
                                    : 
                                    <h3></h3>
                                    // <h3>You wouldn’t be considered for this job.</h3>
                                }
                        </React.Fragment> : <React.Fragment>
                            <h3>{selectedAlert.course.name}</h3>
                            <p>{selectedAlert.content}</p>
                            <textarea className="alert-comment" placeholder="Type your comment" value={comment} onChange={e => this.setState({ comment: e.target.value })} />
                            <div className="btn-area">
                                <button className={`btn-2 primary-btn interest-btn ${this.props.show_interest_loading ? 'disabled-btn' : ''}`} onClick={e => this.showInterest(selectedAlert.id, 1)}>
                                    {(this.props.show_interest_loading && show_interest === 1) ? <div className="spinner small" /> : <p style={{margin: 0}}>I am interested</p>}
                                </button>
                                <button className={`btn-2 btn-2-outline interest-btn ${this.props.show_interest_loading ? 'disabled-btn' : ''}`} onClick={e => this.showInterest(selectedAlert.id, 0)}>
                                    {(this.props.show_interest_loading && show_interest === 0) ? <div className="spinner small" /> : <p style={{margin: 0}}>I am not interested</p>}
                                </button>
                            </div>
                        </React.Fragment>}
                    </div>}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        alert_list: state.Other.alert_list,

        show_interest_loading: state.Other.show_interest_loading,
        show_interest_success: state.Other.show_interest_success,
        alert_message: state.Other.alert_message,        
    }
}

export default connect(mapStateToProps, { 
    getAlerts, 
    showInterest 
})(withRouter(AlertsPage));