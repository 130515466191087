import { data } from 'jquery';
import { 
    GET_ALERT_LIST, GET_ALERT_LIST_FAIL, GET_ALERT_LIST_SUCCESS, ALERT_SHOW_INTEREST, ALERT_SHOW_INTEREST_SUCCESS, ALERT_SHOW_INTEREST_FAIL, 
	GET_BLOG_LIST, GET_BLOG_LIST_FAIL, GET_BLOG_LIST_SUCCESS, UPDATE_BLOG, UPDATE_BLOG_SUCCESS, UPDATE_BLOG_FAIL, 
	GET_BLOCKED_SCHEDULE, GET_BLOCKED_SCHEDULE_SUCCESS, GET_BLOCKED_SCHEDULE_FAIL, BLOCK_SCHEDULE, BLOCK_SCHEDULE_SUCCESS, BLOCK_SCHEDULE_FAIL, DELETE_BLOCK_SCHEDULE, DELETE_BLOCK_SCHEDULE_SUCCESS, DELETE_BLOCK_SCHEDULE_FAIL,
	GET_CATEGORY_LIST, GET_CATEGORY_LIST_FAIL, GET_CATEGORY_LIST_SUCCESS, GET_TOPIC_LIST, GET_TOPIC_LIST_SUCCESS, GET_TOPIC_LIST_FAIL
} from '../Type';

const INITIAL = {
    alert_list: [],
    alert_list_success: false,

    show_interest_loading: false,
	show_interest_success: false,
    alert_message: '',
	
	category_list: [],
	category_list_success: false,

	topic_list: [],
	topic_list_success: false,

    blog_list: [],
    get_blog_success: false,

    update_blog_loading: false,
    update_blog_success: false,
	update_blog_msg: '',
	
	blocked_list: [],
	blocked_list_loading: false,

	block_schedule_loading: false,
    block_schedule_success: false,
    block_schedule_message: '',

    delete_block_schedule_loading: false,
    delete_block_schedule_success: false,
    delete_block_schedule_message: ''
}

export default (state = INITIAL, action) => {
    switch (action.type) {
        case GET_ALERT_LIST: 
        case GET_ALERT_LIST_FAIL: {
            return {
                ...state,
                alert_list_success: false,
            }
        }
        case GET_ALERT_LIST_SUCCESS: {
            return {
                ...state,
                alert_list: action.data || [],
                alert_list_success: true,
            }
        }
        case ALERT_SHOW_INTEREST: {
            return {
                ...state,
                show_interest_loading: true,
                show_interest_success: false,
                alert_message: ''
            }
        }
        case ALERT_SHOW_INTEREST_SUCCESS: {
            return {
                ...state,
                show_interest_loading: false,
                show_interest_success: true,
                alert_message: action.message
            }
        }
        case ALERT_SHOW_INTEREST_FAIL: {
            return {
                ...state,
                show_interest_loading: false,
                show_interest_success: false,
                alert_message: action.message
            }
		}
		case GET_CATEGORY_LIST: 
        case GET_CATEGORY_LIST_FAIL: {
            return {
                ...state,
                category_list_success: false,
            }
        }
        case GET_CATEGORY_LIST_SUCCESS: {
            return {
                ...state,
                category_list: action.data || [],
                category_list_success: true,
            }
		}
		case GET_TOPIC_LIST: 
        case GET_TOPIC_LIST_FAIL: {
            return {
                ...state,
                topic_list_success: false,
            }
        }
        case GET_TOPIC_LIST_SUCCESS: {
            return {
                ...state,
                topic_list: action.data || [],
                topic_list_success: true,
            }
        }
        case GET_BLOG_LIST: 
        case GET_BLOG_LIST_FAIL: {
            return {
                ...state,
                get_blog_success: false,
            }
        }
        case GET_BLOG_LIST_SUCCESS: {
            return {
                ...state,
                blog_list: action.data || [],
                get_blog_success: true,
            }
        }
        case UPDATE_BLOG: {
            return {
                ...state,
                update_blog_loading: true,
                update_blog_success: false,
                update_blog_msg: '',
            }
        }
        case UPDATE_BLOG_SUCCESS: {
            return {
                ...state,
                update_blog_loading: false,
                update_blog_success: true,
                update_blog_msg: '',
            }
        }
        case UPDATE_BLOG_FAIL: {
            return {
                ...state,
                update_blog_loading: false,
                update_blog_success: false,
                update_blog_msg: action.msg,
            }
		}
		case GET_BLOCKED_SCHEDULE: {
			return {
				...state,
				blocked_list_loading: true,
			}
		}
        case GET_BLOCKED_SCHEDULE_FAIL: {
            return {
				...state,
				blocked_list_loading: false,
            }
        }
        case GET_BLOCKED_SCHEDULE_SUCCESS: {
            return {
                ...state,
                blocked_list: action.data || [],
                blocked_list_loading: false,
            }
		}
		case BLOCK_SCHEDULE: {
            return {
                ...state,
				block_schedule_loading: true,
				block_schedule_success: false,
				block_schedule_message: '',
            }
        }
        case BLOCK_SCHEDULE_SUCCESS: {
            return {
                ...state,
                block_schedule_loading: false,
                block_schedule_success: true,
				block_schedule_message: action.message,
            }
        }
        case BLOCK_SCHEDULE_FAIL: {
            return {
                ...state,
                block_schedule_loading: false,
                block_schedule_success: false,
				block_schedule_message: action.message,
            }
        }

        case DELETE_BLOCK_SCHEDULE: {
            return {
                ...state,
				delete_block_schedule_loading: true,
				delete_block_schedule_success: false,
				delete_block_schedule_message: '',
            }
        }
        case DELETE_BLOCK_SCHEDULE_SUCCESS: {
            return {
                ...state,
                delete_block_schedule_loading: false,
                delete_block_schedule_success: true,
				delete_block_schedule_message: action.message,
            }
        }
        case DELETE_BLOCK_SCHEDULE_FAIL: {
            return {
                ...state,
                delete_block_schedule_loading: false,
                delete_block_schedule_success: false,
				delete_block_schedule_message: action.message,
            }
        }

        default:
            return state;
    }
}