import { Api } from './Api';

export function* getLessons(type){
    const jsonData = yield Api.get(`/transactions?status=${type}`);
    return jsonData;
}

export function* cancelLesson(id, data) {
    const jsonData = yield Api.post(`/transactions/${id}`, data, true);
    return jsonData;
}

export function* leaveNote(id, note) {
    const jsonData = yield Api.post(`/transactions/${id}`, note, true);
    return jsonData;
}

export function* getAllCourses(){
    const jsonData = yield Api.get("/courses");
    return jsonData;
}

export function* scheduleLesson(data){
    const jsonData = yield Api.post("/transactions", data, true);
    return jsonData;
}

export function* getAvailability(data){
    const jsonData = yield Api.get(`/transactions/availability?tutor_id=${data.tutor_id}&day=${data.day}&timezone=${data.timezone}`);
    return jsonData;
}