import arrowLeft from './Assets/Icon/arrow-left.png';
import arrowRight from './Assets/Icon/arrow-right.png';

import avatar1 from './Assets/Avatar/avatar1.png';
import avatar2 from './Assets/Avatar/avatar2.png';
import avatar3 from './Assets/Avatar/avatar3.png';
import avatar4 from './Assets/Avatar/avatar4.png';
import avatar5 from './Assets/Avatar/avatar5.png';

import game from './Assets/Course/game.png';
import machine from './Assets/Course/machine.png';
import python from './Assets/Course/python.png';
import javascript from './Assets/Course/javascript.png';
import html5 from './Assets/Course/html5.png';
import imac from './Assets/Course/imac.png';
import document from './Assets/Course/document.png';
import ice from './Assets/Course/ice.png';

const Avatars = [avatar1, avatar2, avatar3, avatar4, avatar5];

export const CourseCategories = [
    { id: 1, logo: game, name: 'Game Design 101' },
    { id: 2, logo: machine, name: 'AR Studio' },
    { id: 3, logo: python, name: 'Python' },
    { id: 4, logo: javascript, name: 'Javascript' },
    { id: 5, logo: html5, name: 'Html5' },
    { id: 6, logo: imac, name: 'Desktop Software' },
    { id: 7, logo: document, name: 'Script' },
    { id: 8, logo: ice, name: 'Icon Coding' },
];

export const State = [
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FM',
    'FL',
    'GA',
    'GU',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VI',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY'
]


export const StarSVGViewBox = "0 0 16 15";
export const StarSVGIconPath = "M7.90577 0.587492L9.82622 5.13287L14.7428 5.55524C15.0838 5.58469 15.2225 6.01017 14.9637 6.23414L11.2344 9.46512L12.3519 14.2717C12.4294 14.6057 12.0675 14.8684 11.7746 14.6909L7.54927 12.1427L3.32397 14.6909C3.03025 14.8676 2.6691 14.6049 2.7466 14.2717L3.86415 9.46512L0.134072 6.23337C-0.124778 6.00939 0.0131721 5.58392 0.354947 5.55447L5.27155 5.13209L7.192 0.587492C7.3253 0.271292 7.77247 0.271292 7.90577 0.587492Z";

export const CarouselLeftButton = `
    <div style='width:50px;height:50px;border-radius:50%;display:flex;align-items:center;justify-content:center;background:white;box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);'>
        <img src='${arrowLeft}' alt='nav-arrow' />
    </div>
`;
export const CarouselRightButton = `
    <div style='width:50px;height:50px;border-radius:50%;display:flex;align-items:center;justify-content:center;background:white;box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);'>
        <img src='${arrowRight}' alt='nav-arrow' />
    </div>
`;

export const GetAvatar = (origin) => {
	let index = Math.floor(Math.random() * 10) % 5;
	return origin === 'https://icodeedu.com/storage/users/default.png' ? Avatars[index] : origin
}