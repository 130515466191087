import { 
    GET_ALERT_LIST, 
    ALERT_SHOW_INTEREST, 
    GET_BLOG_LIST, 
    UPDATE_BLOG, 
    GET_BLOCKED_SCHEDULE, 
    BLOCK_SCHEDULE, 
    GET_TOPIC_LIST, 
    GET_CATEGORY_LIST,
    DELETE_BLOCK_SCHEDULE
} from '../Type';

export const getAlerts = () => {
    return {
        type: GET_ALERT_LIST,
        data: {}
    }
}

export const showInterest = (data) => {
    return {
        type: ALERT_SHOW_INTEREST,
        data: data
    }
}

export const getBlogs = (user_id) => {
    return {
        type: GET_BLOG_LIST,
        data: {user_id}
    }
}

export const getCategories = () => {
    return {
        type: GET_CATEGORY_LIST,
        data: {}
    }
}

export const getTopics = () => {
	return {
		type: GET_TOPIC_LIST,
		data: {}
	}
}

export const updateBlog = (blog_id, data) => {
    return {
        type: UPDATE_BLOG,
        data: {
            blog_id,
            data
        }
    }
}

export const getBlockedSchedules = (start_date, end_date) => {
    return {
		type: GET_BLOCKED_SCHEDULE,
		data: { start_date, end_date }
	}
}

export const blockSchedule = (data) => {
    return {
		type: BLOCK_SCHEDULE,
		data: data
	}
}

export const deleteBlockSchedule = (data, deleteId) => {
    return {
		type: DELETE_BLOCK_SCHEDULE,
		data: {data, deleteId}
	}
}