import React from 'react';
import './CreditModal.scss';
import { Modal } from 'react-bootstrap';
import { toggleCreditModal } from '../../Redux/Actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import add from '../../Assets/Icon/add.svg';
import { ReactSVG } from 'react-svg'
import envelope from '../../Assets/Icon/envelope.svg';
import facebook from '../../Assets/Icon/facebook-logo.png';
import twitter from '../../Assets/Icon/twitter-logo.png';
import share from '../../Assets/Modal/share.png';
import {
	EmailShareButton,
	FacebookShareButton,
	TwitterShareButton
} from 'react-share';

class CreditModal extends React.Component {
    state = {
        isOpen: false,
    }

    static getDerivedStateFromProps(props, state) {
        return {
            isOpen: props.isOpen,
        }
    }

    closeModal = () => {
        this.props.toggleCreditModal(false);
    }

    onHide = () => {
    }

    copyMyLink = () => {
        this.myUrl.select();
        document.execCommand('copy');
    }

    render() {
            const { isOpen } = this.state;
            const ShareURL = `${process.env.REACT_APP_PUBLIC_DOMAIN}/start-trial?refer_id=${this.props.user.id}`;
        return (
            <Modal show={isOpen} onHide={this.onHide} className="credit-modal-component">
                <div className="credit-content">
                    <div className="close-btn"> <ReactSVG src={ add } className='icon-close' onClick={ () => this.closeModal() }/> </div>
                    <div className="img-area v-r v-c h-c">
                        <img src={share} alt="sharepic" />
                    </div>
                    <div className="content-area v-r">
                        <h1>Gift Coding Credit</h1>
                        <p>Give $25 in iCodeEdu credit to a friend and get $25 credit for your self after your friend complete their first instructing lesson.</p>
                        <h2>Share via link</h2>
                        <div className="url-area url-btn v-c">
                            <input className="url-input" value={ShareURL} readOnly ref={(input) => this.myUrl = input}/>
                            <div className="predend-text" onClick={e => this.copyMyLink()}>COPY</div>
                        </div>
                        <div className="v-c btn-area">
                            <EmailShareButton url={ShareURL} className="share-btn" >
                                <div className="url-btn v-c h-c">
                                    <ReactSVG src={ envelope } className='svg-icon'/>
                                    <p>Share via Email</p>
                                </div>
                            </EmailShareButton>
                                <FacebookShareButton url={ShareURL} className="share-btn" >
                                    <div className="url-btn v-c h-c">
                                        <img src={facebook} alt='facebook' />
                                        <p>Share via Facebook</p>
                                    </div>
                                </FacebookShareButton>
                            <TwitterShareButton url={ShareURL} className="share-btn" >
                                <div className="url-btn v-c h-c">
                                    <img src={twitter} alt='twitter' />
                                    <p>Share via Twitter</p>
                                </div>
                            </TwitterShareButton>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        isOpen: state.Modal.isCreditModalOpen,
        user: state.Authentication.user,
    }
}
  
export default connect(mapStateToProps, { toggleCreditModal })(withRouter(CreditModal));
