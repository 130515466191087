import React from 'react';
import './Sidebar.scss';
import logo from '../../Assets/Header/logo.png';
import logoicon from '../../Assets/Header/logo-icon.png';
import collapse from '../../Assets/Icon/collapse.svg';
import open from '../../Assets/Icon/open.svg';
import arrowup from '../../Assets/Icon/arrow-up.svg';
import arrowdown from '../../Assets/Icon/arrow-down.svg';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleSidebar } from '../../Redux/Actions';
import { ReactSVG } from 'react-svg';
import { Menus } from './Menus';
import { CSSTransition } from 'react-transition-group';

class Sidebar extends React.Component {
  state = {
    isOpen: true,
    isPrevOpen: true,
    menu: Menus
  }

  static getDerivedStateFromProps(props, state) {
    return {
      isOpen: props.isOpen,
      isPrevOpen: state.isOpen
    }
  }

  setStateAsync = (state) => {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    });
  }

  closeMenuHeaders = async() => {
    let temp = this.state.menu;
    for (let item of temp) {
      item.opened = false
    }
    await this.setState({ menu: temp })
  }

  toggleSidebar = async () => {
    await this.closeMenuHeaders();
    setTimeout(() => {
      this.props.toggleSidebar(!this.state.isOpen);
    }, 100);
  }

  toggleMenu = async (index) => {
    if (!this.state.isOpen) {
      await this.closeMenuHeaders();
    }
    let temp = this.state.menu;
    temp[index].opened = !temp[index].opened;
    this.setState({
      menu: temp
    })
  }

  subMenuBlur = (index) => {
    if (!this.state.isOpen) {
      let temp = this.state.menu;
      temp[index].opened = false;
      this.setState({
        menu: temp
      })
    }
  }

  subMenuItemClick = (index) => {
    let temp = this.state.menu;
    for (let item of temp) {
      item.subItemActive = false
    }
    temp[index].subItemActive = true;
    this.setState({ menu: temp })
  }

  renderMenu = () => {
    return this.state.menu.map((item, index) => (
        item.children.length === 0 ? 
          item.id === 3 ? <NavLink exact isActive={(match, location) => {return (location.pathname==='/'||location.pathname.substring(0,11)==='/instructors')} } to={item.link} className="menu-item header-menu-item v-c" key={index} onClick={e => this.subMenuItemClick(index)}><ReactSVG src={item.icon} className="svg-icon start-icon" /><p>{item.name}</p></NavLink> 
                        : <NavLink exact to={item.link} className="menu-item header-menu-item v-c" key={index} onClick={e => this.subMenuItemClick(index)}><ReactSVG src={item.icon} className="svg-icon start-icon" /><p>{item.name}</p></NavLink> 
        : <div key={index} style={{position: 'relative'}}>
          <div className={`menu-header v-c ${item.subItemActive ? 'sub-active' : ''}`} key={index} onClick={e => this.toggleMenu(index)} tabIndex={0} onBlur={e => this.subMenuBlur(index)}>
            <ReactSVG src={item.icon} className="svg-icon start-icon" /> 
            <p>{item.name}</p>
            <ReactSVG src={item.opened ? arrowup : arrowdown} className="svg-icon end-icon" /> 
          </div>
          <CSSTransition in={item.opened} timeout={200} classNames="sub-menu">
            <div className={`sub-menu v-r`}>
              {item.children.map((subitem, subindex) => <NavLink to={subitem.link} className="menu-item sub-menu-item" key={subindex} onClick={e => this.subMenuItemClick(index)}>
                {subitem.name}
              </NavLink>)}
            </div>
          </CSSTransition>
        </div>
    ));
  }

  render() {
    const { isOpen, isPrevOpen } = this.state;
    return (
      <div className={`sidebar-component v-r ${isOpen ? isPrevOpen ? '' : 'open' : 'closed'}`}>
        <div className="logo-area v-c">
          <img src={isOpen ? logo : logoicon} alt="logo" />
        </div>
        <div className="sidebar-content v-r">
          <div className="sidebar-menu v-r">
            {this.renderMenu()}
          </div>
          <div className="sidebar-resize v-c" onClick={e => this.toggleSidebar() }>
            <ReactSVG src={isOpen ? collapse : open} className="svg-icon" /> 
            {isOpen && <p>Collapse Sidebar</p> }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isOpen: state.Sidebar.isOpen,
  }
}

export default connect(mapStateToProps, { toggleSidebar })(withRouter(Sidebar));