import { GET_MESSAGE_USER_LIST, GET_MESSAGES_DETAIL, SEND_MESSAGE, } from '../Type';

export const getMessageUserList = () => {
    return {
      type: GET_MESSAGE_USER_LIST,
      data: {}
    }
}
  
export const getMessageDetail = (chat_id) => {
    return {
      type: GET_MESSAGES_DETAIL,
      data: { chat_id }
    }
}
  
export const sendMessage = (data) => {
    return {
      type: SEND_MESSAGE,
      data: data
    }
}