import { GET_PAYMENT_HISTORY, GET_MONTHLY_REPORT, GET_BANK_ACCOUNT, SET_BANK_ACCOUNT, WITHDRAW_MONEY } from '../Type';

export const getPaymentHistory = () => {
    return {
        type: GET_PAYMENT_HISTORY,
        data: {}
    }
}

export const getMonthlyReport = (period) => {
    return {
        type: GET_MONTHLY_REPORT,
        data: period
    }
}

export const getBankAccount = () => {
    return {
        type: GET_BANK_ACCOUNT,
        data: {}
    }
}

export const setBankAccount = (data) => {
    return {
        type: SET_BANK_ACCOUNT,
        data: data
    }
}

export const withdrawMoney = (data) => {
    return {
        type: WITHDRAW_MONEY,
        data: data
    }
}