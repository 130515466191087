import { GET_ALL_CITIES, GET_ALL_CITIES_SUCCESS, GET_ALL_CITIES_FAIL,} from '../Type';

const INITIAL = {
    cities: [],
	get_cities_success: false,
	get_cities_loading: true,
}

export default (state = INITIAL, action) => {
    switch (action.type) {
        case GET_ALL_CITIES: {
            return {
                ...state,
                get_cities_loading: true,
                get_cities_success: false,
                cities: []
            }
        }
        case GET_ALL_CITIES_FAIL: {
            return {
                ...state,
                cities: [],
                get_cities_success: false,
            	get_cities_loading: false,

            }
        }
        case GET_ALL_CITIES_SUCCESS: {
            return {
                ...state,
                cities: action.data || [],
                get_cities_success: true,
            	get_cities_loading: false,
            }
		}

        default:
            return state;
    }
}