import { 
    GET_MESSAGE_USER_LIST, GET_MESSAGE_USER_LIST_FAIL, GET_MESSAGE_USER_LIST_SUCCESS,
    SEND_MESSAGE_SUCCESS, SEND_MESSAGE, SEND_MESSAGE_FAIL,
    GET_MESSAGES_DETAIL, GET_MESSAGES_DETAIL_FAIL, GET_MESSAGES_DETAIL_SUCCESS 
} from '../Type';

const INITIAL = {
    messageUserList: [],
    get_message_user_list_success: false,
    get_message_user_list_lodaing: true,

    messageData: [],
    get_message_detail_success: false,
    get_message_detail_loading: true,

    send_message_success: false,
    send_message_loading: true,
    sendMessageStatus: null,
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    /**
     * Get message user list
     */
    case GET_MESSAGE_USER_LIST: {
        return {
            ...state,
            get_message_user_list_success: false,
            get_message_user_list_lodaing: true,
        }
    }
    case GET_MESSAGE_USER_LIST_FAIL: {
        return {
            ...state,
            get_message_user_list_success: false,
            get_message_user_list_lodaing: false,

        }
    }
    case GET_MESSAGE_USER_LIST_SUCCESS: {
        return {
            ...state,
            messageUserList: action.data || [],
            get_message_user_list_success: true,
            get_message_user_list_lodaing: false,
        }
    }

    /**
     * Get message detail
     */
    case GET_MESSAGES_DETAIL: {
        return {
            ...state,
            get_message_detail_success: false,
            get_message_detail_loading: true,
        }
    }
    case GET_MESSAGES_DETAIL_FAIL: {
        return {
            ...state,
            get_message_detail_success: false,
            get_message_detail_loading: false,

        }
    }
    case GET_MESSAGES_DETAIL_SUCCESS: {
        return {
            ...state,
            messageData: action.data,
            get_message_detail_success: true,
            get_message_detail_loading: false,
        }
    }

    /**
     * Send message
     */
    case SEND_MESSAGE: {
        return {
            ...state,
            send_message_success: false,
            send_message_loading: true,
            sendMessageStatus: null
        }
    }
    case SEND_MESSAGE_FAIL: {
        return {
            ...state,
            send_message_success: false,
            send_message_loading: false,
            sendMessageStatus: null
        }
    }
    case SEND_MESSAGE_SUCCESS: {
        return {
            ...state,
            send_message_success: true,
            send_message_loading: false,
            sendMessageStatus: action.data
        }
    }
    default:
      return state;
  }
}