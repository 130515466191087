/**
 * Sidebar
 */
export const MOBILE_SIDEBAR_TOGGLE            = 'MOBILE_SIDEBAR_TOGGLE';
export const SIDEBAR_TOGGLE                   = 'SIDEBAR_TOGGLE';
export const COURSE_SIDEBAR_TOGGLE            = 'COURSE_SIDEBAR_TOGGLE';

/**
 * Modal
 */
export const VIDEO_MODAL_TOGGLE               = 'VIDEO_MODAL_TOGGLE';
export const CREDIT_MODAL_TOGGLE              = 'CREDIT_MODAL_TOGGLE';
export const QUALIFICATION_MODAL_TOGGLE       = 'QUALIFICATION_MODAL_TOGGLE';

/**
 * Lesson
 */
export const JOIN_LESSON_TOGGLE               = 'JOIN_LESSON_TOGGLE';

export const GET_PENDING_LESSON               = 'GET_PENDING_LESSON';
export const GET_PENDING_LESSON_SUCCESS       = 'GET_PENDING_LESSON_SUCCESS';
export const GET_PENDING_LESSON_FAIL          = 'GET_PENDING_LESSON_FAIL';

export const GET_COMPLETED_LESSON             = 'GET_COMPLETED_LESSON';
export const GET_COMPLETED_LESSON_SUCCESS     = 'GET_COMPLETED_LESSON_SUCCESS';
export const GET_COMPLETED_LESSON_FAIL        = 'GET_COMPLETED_LESSON_FAIL';

export const CANCEL_LESSON                    = 'CANCEL_LESSON';
export const CANCEL_LESSON_SUCCESS            = 'CANCEL_LESSON_SUCCESS';
export const CANCEL_LESSON_FAIL               = 'CANCEL_LESSON_FAIL';

export const LEAVE_NOTE                       = 'LEAVE_NOTE';
export const LEAVE_NOTE_SUCCESS               = 'LEAVE_NOTE_SUCCESS';
export const LEAVE_NOTE_FAIL                  = 'LEAVE_NOTE_FAIL';

export const GET_LEVELS                  = 'GET_LEVELS';
export const GET_LEVELS_SUCCESS          = 'GET_LEVELS_SUCCESS';
export const GET_LEVELS_FAIL             = 'GET_LEVELS_FAIL';

export const GET_ALL_COURSES                  = 'GET_ALL_COURSES';
export const GET_ALL_COURSES_SUCCESS          = 'GET_ALL_COURSES_SUCCESS';
export const GET_ALL_COURSES_FAIL             = 'GET_ALL_COURSES_FAIL';

export const SCHEDULE_LESSON                  = 'SCHEDULE_LESSON';
export const SCHEDULE_LESSON_SUCCESS          = 'SCHEDULE_LESSON_SUCCESS';
export const SCHEDULE_LESSON_FAIL             = 'SCHEDULE_LESSON_FAIL';

export const GET_AVAILABILITY                  = 'GET_AVAILABILITY';
export const GET_AVAILABILITY_SUCCESS          = 'GET_AVAILABILITY_SUCCESS';
export const GET_AVAILABILITY_FAIL             = 'GET_AVAILABILITY_FAIL';

/**
 * Authentication
 */
export const AUTH_UPDATE_TOKEN                = 'AUTH_UPDATE_TOKEN';
export const AUTH_UPDATE_PLAN                 = 'AUTH_UPDATE_PLAN';
export const AUTH_SIGN_OUT					  = 'AUTH_SIGN_OUT';

export const GET_MY_INFO                      = 'GET_MY_INFO';
export const GET_MY_INFO_SUCCESS              = 'GET_MY_INFO_SUCCESS';
export const GET_MY_INFO_FAIL                 = 'GET_MY_INFO_FAIL';

export const UPDATE_MY_INFO                   = 'UPDATE_MY_INFO';
export const UPDATE_MY_INFO_SUCCESS           = 'UPDATE_MY_INFO_SUCCESS';
export const UPDATE_MY_INFO_FAIL              = 'UPDATE_MY_INFO_FAIL';

export const UPDATE_PASSWORD                  = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS          = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL             = 'UPDATE_PASSWORD_FAIL';

export const UPDATE_QUALIFICATION			  = 'UPDATE_QUALIFICATION';

/**
 * Client
 */
export const GET_CLIENT_LIST                  = 'GET_CLIENT_LIST';
export const GET_CLIENT_LIST_SUCCESS          = 'GET_CLIENT_LIST_SUCCESS';
export const GET_CLIENT_LIST_FAIL             = 'GET_CLIENT_LIST_FAIL';

export const GET_SCHOOL_LIST                  = 'GET_SCHOOL_LIST';
export const GET_SCHOOL_LIST_SUCCESS          = 'GET_SCHOOL_LIST_SUCCESS';
export const GET_SCHOOL_LIST_FAIL             = 'GET_SCHOOL_LIST_FAIL';

/**
 * Other
 */
export const GET_ALERT_LIST                   = 'GET_ALERT_LIST';
export const GET_ALERT_LIST_SUCCESS           = 'GET_ALERT_LIST_SUCCESS';
export const GET_ALERT_LIST_FAIL              = 'GET_ALERT_LIST_FAIL';

export const ALERT_SHOW_INTEREST              = 'ALERT_SHOW_INTEREST';
export const ALERT_SHOW_INTEREST_SUCCESS      = 'ALERT_SHOW_INTEREST_SUCCESS';
export const ALERT_SHOW_INTEREST_FAIL         = 'ALERT_SHOW_INTEREST_FAIL';

export const GET_CATEGORY_LIST				  = 'GET_CATEGORY_LIST';
export const GET_CATEGORY_LIST_SUCCESS		  = 'GET_CATEGORY_LIST_SUCCESS';
export const GET_CATEGORY_LIST_FAIL			  = 'GET_CATEGORY_LIST_FAIL';

export const GET_TOPIC_LIST				  	  = 'GET_TOPIC_LIST';
export const GET_TOPIC_LIST_SUCCESS		  	  = 'GET_TOPIC_LIST_SUCCESS';
export const GET_TOPIC_LIST_FAIL			  = 'GET_TOPIC_LIST_FAIL';

export const GET_BLOG_LIST                    = 'GET_BLOG_LIST';
export const GET_BLOG_LIST_SUCCESS            = 'GET_BLOG_LIST_SUCCESS';
export const GET_BLOG_LIST_FAIL               = 'GET_BLOG_LIST_FAIL';

export const UPDATE_BLOG                      = 'UPDATE_BLOG';
export const UPDATE_BLOG_SUCCESS              = 'UPDATE_BLOG_SUCCESS';
export const UPDATE_BLOG_FAIL                 = 'UPDATE_BLOG_FAIL';

export const GET_BLOCKED_SCHEDULE             = 'GET_BLOCKED_SCHEDULE';
export const GET_BLOCKED_SCHEDULE_SUCCESS     = 'GET_BLOCKED_SCHEDULE_SUCCESS';
export const GET_BLOCKED_SCHEDULE_FAIL        = 'GET_BLOCKED_SCHEDULE_FAIL';

export const BLOCK_SCHEDULE                   = 'BLOCK_SCHEDULE';
export const BLOCK_SCHEDULE_SUCCESS           = 'BLOCK_SCHEDULE_SUCCESS';
export const BLOCK_SCHEDULE_FAIL              = 'BLOCK_SCHEDULE_FAIL';

export const DELETE_BLOCK_SCHEDULE                   = 'DELETE_BLOCK_SCHEDULE';
export const DELETE_BLOCK_SCHEDULE_SUCCESS           = 'DELETE_BLOCK_SCHEDULE_SUCCESS';
export const DELETE_BLOCK_SCHEDULE_FAIL              = 'DELETE_BLOCK_SCHEDULE_FAIL';

/**
 * Chat
 */
export const GET_MESSAGE_USER_LIST             = 'GET_MESSAGE_USER_LIST';
export const GET_MESSAGE_USER_LIST_SUCCESS     = 'GET_MESSAGE_USER_LIST_SUCCESS';
export const GET_MESSAGE_USER_LIST_FAIL        = 'GET_MESSAGE_USER_LIST_FAIL';

export const GET_MESSAGES_DETAIL                = 'GET_MESSAGES_DETAIL';
export const GET_MESSAGES_DETAIL_SUCCESS        = 'GET_MESSAGES_DETAIL_SUCCESS';
export const GET_MESSAGES_DETAIL_FAIL           = 'GET_MESSAGES_DETAIL_FAIL';

export const SEND_MESSAGE                     = 'SEND_MESSAGE';
export const SEND_MESSAGE_SUCCESS             = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAIL                = 'SEND_MESSAGE_FAIL';


/**
 * Payment
 */
export const GET_PAYMENT_HISTORY              = 'GET_PAYMENT_HISTORY';
export const GET_PAYMENT_HISTORY_SUCCESS      = 'GET_PAYMENT_HISTORY_SUCCESS';
export const GET_PAYMENT_HISTORY_FAIL         = 'GET_PAYMENT_HISTORY_FAIL';

export const GET_MONTHLY_REPORT               = 'GET_MONTHLY_REPORT';
export const GET_MONTHLY_REPORT_SUCCESS       = 'GET_MONTHLY_REPORT_SUCCESS';
export const GET_MONTHLY_REPORT_FAIL          = 'GET_MONTHLY_REPORT_FAIL';

export const GET_BANK_ACCOUNT                 = 'GET_BANK_ACCOUNT';
export const GET_BANK_ACCOUNT_SUCCESS         = 'GET_BANK_ACCOUNT_SUCCESS';
export const GET_BANK_ACCOUNT_FAIL            = 'GET_BANK_ACCOUNT_FAIL';

export const SET_BANK_ACCOUNT                 = 'SET_BANK_ACCOUNT';
export const SET_BANK_ACCOUNT_SUCCESS         = 'SET_BANK_ACCOUNT_SUCCESS';
export const SET_BANK_ACCOUNT_FAIL            = 'SET_BANK_ACCOUNT_FAIL';

export const WITHDRAW_MONEY                   = 'WITHDRAW_MONEY';
export const WITHDRAW_MONEY_SUCCESS           = 'WITHDRAW_MONEY_SUCCESS';
export const WITHDRAW_MONEY_FAIL              = 'WITHDRAW_MONEY_FAIL';

/**
 * Client
 */
 export const GET_ALL_CITIES                  = 'GET_ALL_CITIES';
 export const GET_ALL_CITIES_SUCCESS          = 'GET_ALL_CITIES_SUCCESS';
 export const GET_ALL_CITIES_FAIL             = 'GET_ALL_CITIES_FAIL';
