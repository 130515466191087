import {
    GET_PAYMENT_HISTORY, GET_PAYMENT_HISTORY_SUCCESS, GET_PAYMENT_HISTORY_FAIL, GET_MONTHLY_REPORT, GET_MONTHLY_REPORT_SUCCESS, GET_MONTHLY_REPORT_FAIL, 
    GET_BANK_ACCOUNT, GET_BANK_ACCOUNT_SUCCESS, GET_BANK_ACCOUNT_FAIL, SET_BANK_ACCOUNT_SUCCESS, SET_BANK_ACCOUNT_FAIL, SET_BANK_ACCOUNT, WITHDRAW_MONEY, WITHDRAW_MONEY_SUCCESS, WITHDRAW_MONEY_FAIL,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
    getPaymentHistory as getPaymentHistoryApi,
    getMonthlyReport as getMonthlyReportApi,
    getBankAccount as getBankAccountApi,
    setBankAccount as setBankAccountApi,
    withdrawMoney as withdrawMoneyApi,
} from '../../Api';

function* getPaymentHistory(payload){
    try {
        const result = yield getPaymentHistoryApi();
        if (result.data && result.data.length > 0) {
            yield put({ type: GET_PAYMENT_HISTORY_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_PAYMENT_HISTORY_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_PAYMENT_HISTORY_FAIL, data: [] })
    }
}

export function* watchGetPaymentHistory(){
    yield takeLatest(GET_PAYMENT_HISTORY, getPaymentHistory)
}

function* getMonthlyReport(payload){
    try {
        const result = yield getMonthlyReportApi(payload.data);
        if (result.data) {
            yield put({ type: GET_MONTHLY_REPORT_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_MONTHLY_REPORT_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_MONTHLY_REPORT_FAIL, data: [] })
    }
}

export function* watchGetMonthlyReport(){
    yield takeLatest(GET_MONTHLY_REPORT, getMonthlyReport)
}

function* getBankAccount(payload){
    try {
        const result = yield getBankAccountApi();
        if (result) {
            yield put({ type: GET_BANK_ACCOUNT_SUCCESS, data: result })
        } else {
            yield put({ type: GET_BANK_ACCOUNT_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_BANK_ACCOUNT_FAIL, data: [] })
    }
}

export function* watchGetBankAccount(){
    yield takeLatest(GET_BANK_ACCOUNT, getBankAccount)
}

function* setBankAccount(payload){
    try {
        const result = yield setBankAccountApi(payload.data);

        if (result.is_success) {
            yield put({ type: SET_BANK_ACCOUNT_SUCCESS, message: result.message })
        } else {
            yield put({ type: SET_BANK_ACCOUNT_FAIL, message: result.message })
        }
    } catch (err) {
        yield put({ type: SET_BANK_ACCOUNT_FAIL, data: [] })
    }
}

export function* watchSetBankAccount(){
    yield takeLatest(SET_BANK_ACCOUNT, setBankAccount)
}

function* withdrawMoney(payload){
    try {
        const result = yield withdrawMoneyApi(payload.data);

        if (result.is_success) {
            yield put({ type: WITHDRAW_MONEY_SUCCESS, message: result.message })
        } else {
            yield put({ type: WITHDRAW_MONEY_FAIL, message: result.message })
        }
    } catch (err) {
        yield put({ type: WITHDRAW_MONEY_FAIL, data: [] })
    }
}

export function* watchWithdrawMoney(){
    yield takeLatest(WITHDRAW_MONEY, withdrawMoney)
}