class API {
    get = function* (url) {
        const token = sessionStorage.getItem('token') || '';
        let jsonData = yield fetch(process.env.REACT_APP_API_URL+url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
        .catch(err => console.log("API BUG", err));
        return jsonData;
    }

    post = function* postApi(url, data, isForm = false) {
        const token = sessionStorage.getItem('token') || '';
        let headers = {'Authorization': `Bearer ${token}`};
        if (!isForm) {
            headers['Content-Type'] = 'application/json';
        }
        let jsonData = yield fetch(process.env.REACT_APP_API_URL+url, {
            method: 'POST',
            body: !isForm ? JSON.stringify(data) : data,
            headers: headers
        }).then(res => res.json())
        .catch(err => console.log("API BUG", err));
        return jsonData;
    }

    put = function* putApi(url, data, isForm = false) {
        const token = sessionStorage.getItem('token') || '';
        let headers = {'Authorization': `Bearer ${token}`};
        if (!isForm) {
            headers['Content-Type'] = 'application/json';
        }
        let jsonData = yield fetch(process.env.REACT_APP_API_URL+url, {
            method: 'PUT',
            body: !isForm ? JSON.stringify(data) : data,
            headers: headers
        }).then(res => res.json())
        .catch(err => console.log("API BUG", err));
        return jsonData;
    }
}

export const Api = new API();
