import {
    GET_ALERT_LIST, GET_ALERT_LIST_SUCCESS, GET_ALERT_LIST_FAIL, ALERT_SHOW_INTEREST, ALERT_SHOW_INTEREST_SUCCESS, ALERT_SHOW_INTEREST_FAIL, 
	GET_BLOG_LIST, GET_BLOG_LIST_SUCCESS, GET_BLOG_LIST_FAIL, UPDATE_BLOG, UPDATE_BLOG_SUCCESS, UPDATE_BLOG_FAIL, 
	GET_BLOCKED_SCHEDULE, GET_BLOCKED_SCHEDULE_SUCCESS, GET_BLOCKED_SCHEDULE_FAIL, BLOCK_SCHEDULE_SUCCESS, BLOCK_SCHEDULE_FAIL, BLOCK_SCHEDULE, DELETE_BLOCK_SCHEDULE, DELETE_BLOCK_SCHEDULE_SUCCESS, DELETE_BLOCK_SCHEDULE_FAIL,
	GET_CATEGORY_LIST, GET_CATEGORY_LIST_SUCCESS, GET_CATEGORY_LIST_FAIL, GET_TOPIC_LIST_SUCCESS, GET_TOPIC_LIST, GET_TOPIC_LIST_FAIL
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
    getAlerts as getAlertsApi,
    showInterest as showInterestApi,
    getBlogs as getBlogsApi,
	updateBlog as updateBlogApi,
	getBlockedSchedules as getBlockedSchedulesApi,
	blockSchedule as blockScheduleApi,
	deleteBlockSchedule as deleteBlockScheduleApi,
	getCategories as getCategoriesApi,
	getTopics as getTopicsApi,
} from '../../Api';

function* getAlerts(payload){
    try {
        const result = yield getAlertsApi();
        if (result.data && result.data.length > 0) {
            yield put({ type: GET_ALERT_LIST_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_ALERT_LIST_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_ALERT_LIST_FAIL, data: [] })
    }
}

export function* watchGetAlerts(){
    yield takeLatest(GET_ALERT_LIST, getAlerts)
}

function* showInterest(payload){
    try {
        const result = yield showInterestApi(payload.data);
        if (!result.error) {
            yield put({ type: ALERT_SHOW_INTEREST_SUCCESS, message: result.message })
        } else {
            yield put({ type: ALERT_SHOW_INTEREST_FAIL, message: result.message })
        }
    } catch (err) {
        yield put({ type: ALERT_SHOW_INTEREST_FAIL, data: [] })
    }
}

export function* watchShowInterest(){
    yield takeLatest(ALERT_SHOW_INTEREST, showInterest)
}

function* getBlogs(payload){
    try {
        const result = yield getBlogsApi(payload.data.user_id);
        if (result.data && result.data.length > 0) {
            yield put({ type: GET_BLOG_LIST_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_BLOG_LIST_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_BLOG_LIST_FAIL, data: [] })
    }
}

export function* watchGetBlogs(){
    yield takeLatest(GET_BLOG_LIST, getBlogs)
}

function* getCategories(payload){
    try {
        const result = yield getCategoriesApi();        
        if (result.data && result.data.length > 0) {
            yield put({ type: GET_CATEGORY_LIST_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_CATEGORY_LIST_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_CATEGORY_LIST_FAIL, data: [] })
    }
}

export function* watchGetCategories(){
    yield takeLatest(GET_CATEGORY_LIST, getCategories)
}

function* getTopics(payload){
    try {
        const result = yield getTopicsApi();
        if (result.data && result.data.length > 0) {
            yield put({ type: GET_TOPIC_LIST_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_TOPIC_LIST_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_TOPIC_LIST_FAIL, data: [] })
    }
}

export function* watchGetTopics(){
    yield takeLatest(GET_TOPIC_LIST, getTopics)
}

function* updateBlog(payload){
    try {
        const result = yield updateBlogApi(payload.data.blog_id, payload.data.data);        
        if (result.data) {
            yield put({ type: UPDATE_BLOG_SUCCESS, data: result.data })
        } else {
			const errors = result.errors;
            yield put({ type: UPDATE_BLOG_FAIL, msg: errors[Object.keys(errors)[0]][0] })
        }
    } catch (err) {
        yield put({ type: UPDATE_BLOG_FAIL, msg: '' })
    }
}

export function* watchUpdateBlog(){
    yield takeLatest(UPDATE_BLOG, updateBlog)
}

function* getBlockedSchedules(payload){
    try {
        const result = yield getBlockedSchedulesApi(payload.data.start_date, payload.data.end_date);
        if (result.data && result.data.length > 0) {
            yield put({ type: GET_BLOCKED_SCHEDULE_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_BLOCKED_SCHEDULE_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_BLOCKED_SCHEDULE_FAIL, data: [] })
    }
}

export function* watchGetBlockedSchedules(){
    yield takeLatest(GET_BLOCKED_SCHEDULE, getBlockedSchedules)
}

function* blockSchedule(payload){
    try {
        const result = yield blockScheduleApi(payload.data);        
        if (result.is_success) {
            yield put({ type: BLOCK_SCHEDULE_SUCCESS, message: result.message })
        } else {
            yield put({ type: BLOCK_SCHEDULE_FAIL, message: result.message })
        }
    } catch (err) {
        yield put({ type: BLOCK_SCHEDULE_FAIL, data: [] })
    }
}

export function* watchBlockSchedule(){
    yield takeLatest(BLOCK_SCHEDULE, blockSchedule)
}

function* deleteBlockSchedule(payload){
    try {
        const result = yield deleteBlockScheduleApi(payload.data.deleteId, payload.data.data); 
        if (result.is_success) {
            yield put({ type: DELETE_BLOCK_SCHEDULE_SUCCESS, message: result.message })
        } else {
            yield put({ type: DELETE_BLOCK_SCHEDULE_FAIL, message: result.message })
        }
    } catch (err) {
        yield put({ type: DELETE_BLOCK_SCHEDULE_FAIL, data: [] })
    }
}

export function* watchdeleteBlockSchedule(){
    yield takeLatest(DELETE_BLOCK_SCHEDULE, deleteBlockSchedule)
}