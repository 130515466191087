import { Api } from './Api';

export function* getMessageUserList() {
    const jsonData = yield Api.get("/messages");
    return jsonData;
}

export function* getMessageDetail(id) {
    const jsonData = yield Api.get(`/messages?user_id=${id}`);
    return jsonData;
}

export function* sendMessage(data) {
    const jsonData = yield Api.post('/messages', data, true);
    return jsonData;
}