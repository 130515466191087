import { GET_CLIENT_LIST, GET_SCHOOL_LIST } from '../Type';

export const getAllClients = () => {
    return {
        type: GET_CLIENT_LIST,
        data: {}
    }
}

export const getSchoolList = () => {
	return {
		type: GET_SCHOOL_LIST,
		data: {}
	}
}