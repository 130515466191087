import { Api } from './Api';

export function* getMyInfo(){
    const jsonData = yield Api.get("/me");
    return jsonData;
}

export function* updateMyInfo(data, isForm) {
    const jsonData = yield Api.post('/me', data, isForm);
    return jsonData;
}

export function* updatePassword(data) {
    const jsonData = yield Api.post("/me", data);
    return jsonData;
}