import {
	GET_LEVELS, 
	GET_LEVELS_FAIL, 
	GET_LEVELS_SUCCESS, 
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
	getLevels as getLevelsApi,
} from '../../Api';

function* getLevels(){
	try {
		const result = yield getLevelsApi();
		if (result.data) {
			yield put({ type: GET_LEVELS_SUCCESS, data: result.data})
		} else {
			yield put({ type: GET_LEVELS_FAIL, data: result})			
		}
	} catch (err) {
		yield put({ type: GET_LEVELS_FAIL, data: {msg: 'NET ERROR'} })
	}
}

export function* watchGetLevels(){
	yield takeLatest(GET_LEVELS, getLevels)
}
