import React from 'react';
import './Course.scss';
import { CourseCategories } from '../../Constant';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleCourseSidebar } from '../../Redux/Actions';
import {Check} from '@material-ui/icons';

class Course extends React.Component {

    state ={
        isSelect: false,
    }

    componentDidMount() {
        const { info, classifications} = this.props;
        classifications.map((data, index)=> {
            data.course_id === info.id && this.setState({isSelect: true})
        })
	}

    toggleCheck = () => {
        this.setState({isSelect: !this.state.isSelect});        
        this.props.updateCourse(this.props.info.id);
    }

    showPreview = (e) => {
        e.preventDefault();
        this.props.toggleCourseSidebar(true, this.props.info.id);
    }

    render() {
        const { isSelect } = this.state;

        const { className, info, classifications} = this.props;
        let categoryIndex = CourseCategories.findIndex(temp => temp.id === info.category_id);
        return (
            <div className={`course-component v-r ${className || ''}`} >
                <div className="select-picker" onClick={()=>this.toggleCheck()}>
                    {isSelect &&
                        <Check className="check-icon"/>

                    }
                </div>
                <div className="sub-block thumb-container round-object">
                    <img src={info.images[0]} className="course-thumb round-object" alt="thumb" />
                    {/* <div className="age-container v-c h-c">Grade&nbsp;{info.age_min-5}&nbsp;{info.age_max !== null ? `- ${info.age_max-5}` : '+'}&nbsp;YEARS</div> */}
                    <div className="age-container v-c h-c">AGE&nbsp;{info.age_min}&nbsp;{info.age_max !== null ? `- ${info.age_max}` : '+'}&nbsp;YEARS</div>
                </div>
                <div className="sub-block info-container round-object">
                    <div className="course-header v-c">
                        <img src={CourseCategories[categoryIndex].logo} alt="category" className="category" />
                        <div className="header-info v-r">
                            <h2 className="sub-header-info">{info.name}</h2>
                        </div>
                    </div>
                    <p className="description">{info.short_description}</p>
                    <NavLink to={`/course/${info.id}`} className="btn-link show-mobile-flex" onClick={e => this.showPreview(e)}>PREVIEW</NavLink>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, { toggleCourseSidebar })(withRouter(Course));