import React from 'react';
import './QualificationModal.scss';
import { Modal } from 'react-bootstrap';
import { toggleQualificationModal, updateQualification } from '../../Redux/Actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import add from '../../Assets/Icon/add.svg';
import { ReactSVG } from 'react-svg'

class QualificationModal extends React.Component {
	state = {
		isOpen: false,
		index: this.props.qualification_info.index,
		info: this.props.qualification_info.data || { content: ''}
	}

	static getDerivedStateFromProps(props, state) {
		return {
			isOpen: props.isOpen,
		}
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.isOpen && this.props.isOpen) {
			this.setState({
				index: this.props.qualification_info.index,
				info: this.props.qualification_info.data || { content: ''}
			})
		}
	}

	closeModal = () => {
		this.props.toggleQualificationModal(false, this.props.qualification_info);
	}

	saveInfo = () => {
		const { index, info } = this.state;		
		this.props.updateQualification(index, info)
		this.props.toggleQualificationModal(false, this.state.info);
	}

	onHide = () => {
	}

	render() {
		const { isOpen, info } = this.state;
		return (
			<Modal show={isOpen} onHide={this.onHide} className="qualification-modal-component">
				<div className="qualification-content">
					<div className="close-btn"> <ReactSVG src={ add } className='icon-close' onClick={ () => this.closeModal() }/> </div>
					<div className="content-area v-r">
						<div className="modal-title">Qualification</div>
						<p className="explain">Here you can list all the subjects you have taken in that subject area, list your grades, your experience instructing that subject, and how your degree is related to that subject. This is where you convince the potential client who is reviewing your profile that you have what it takes to instructor that subject at a high level. We encourage you to keep it under five sentences with a minimum of three.</p>
						<textarea className="qualification-text" placeholder="Description" value={info.content} onChange={e => this.setState({info: {...info, content: e.target.value}})}/>
						<button className="btn-2 primary-btn" onClick={e => this.saveInfo()}>Save Changes</button>
					</div>
				</div>
			</Modal>
		);
	}
}

function mapStateToProps(state) {
	return {
		isOpen: state.Modal.isQualificationModalOpen,
		qualification_info: state.Modal.qualification_info,
	}
}
  
export default connect(mapStateToProps, { toggleQualificationModal, updateQualification })(withRouter(QualificationModal));
