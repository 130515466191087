import React from 'react';
import './TaxPage.scss';
import { InputBox, SelectBox } from '../../Components';
import { getMyInfo } from '../../Redux/Actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Helper from '../../Utils/Helper';
import _ from 'lodash';

class TaxPage extends React.Component {
    state = {
        name: '',
        ssn: '',
        address1: '',
        address2: '',
        tax_form: 'a'
    }

    componentDidMount() {
        //Helper.showSpinner();
        this.setState({
			name: this.props.user.first_name + ' ' + this.props.user.last_name,
			address1: this.props.user.address,
			address2: this.props.user.city + ', ' + this.props.user.state + ' ' + this.props.user.address_zipcode
        })
    }

    componentDidUpdate(prevProps) {  
    }

    render() {
        const { name, address1, address2, ssn, tax_form } = this.state;
        const tax_forms = ['Copy A - For IRS Center', 'Copy B - For Recipient', 'Copy C - For Payer', 'Copy 1 - For State Tax Department', 'Copy 2 - Filed with recipient\'s state income tax return'];
        const tax_form_ids = ['a', 'b', 'c', '1', '2'];
        return (
            <div className="withdraw-page v-r v-c">
                <div className="sub-section v-r" id="section-1">
                    <h3>Tax Form 2022</h3>
                    <form action="https://api.icodeedu.com/api/payments/tax" method="post" accept-charset="utf-8">
                    <div style={{display:'none'}}><input type="hidden" name="_method" value="POST" /><input type="hidden" name="uid" value={this.props.user.id} /></div>
                    
                    <div className="option-list v-r">
                        <div className="option-row">
                            <div className="option-group v-r">
                                <h2 className="option-title">Your name that will appear on the tax form</h2>
                                <input name="name" type="text" value={name} onChange={val => this.setState({name: val.target.value})} placeholder="Type your name" />
                            </div>
                        </div>
                        <div className="option-row">
                            <div className="option-group v-r">
                                <h2 className="option-title">Street Address</h2>
                                <input name="address1" type="text" value={address1} onChange={val => this.setState({address1: val.target.value})} placeholder="Type your address" />
                            </div>
                        </div>
                        <div className="option-row">
                            <div className="option-group v-r">
                                <h2 className="option-title">City or town, state or province, country and zip code</h2>
                                <input name="address2" type="text" value={address2} onChange={val => this.setState({address2: val.target.value})} placeholder="Type your address" />
                            </div>
                        </div>
                        <div className="option-row">
                            <div className="option-group v-r">
                                <h2 className="option-title">Your Social Security Number</h2>
                                <input name="ssn" type="text" placeholder="Type your ssn" />
                            </div>
                        </div>
                        <div className="option-row">
                            <div className="option-group v-r">
                                <h2 className="option-title">What tax form do you need?</h2>
                                <select name="tax_form">
                                  <option value="a">Copy A - For IRS Center</option>
                                  <option value="b">Copy B - For Recipient</option>
                                  <option value="c">Copy C - For Payer</option>
                                  <option value="1">Copy 1 - For State Tax Department</option>
                                  <option value="2">Copy 2 - Filed with recipient's state income tax return</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="submit"><input className="btn-2 primary-btn" type="submit" value="Get your tax" /></div>
                    </form>
                </div>
                
                
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
		user: state.Authentication.user
    }
}

export default connect(mapStateToProps, {
    getMyInfo
})(withRouter(TaxPage));
