import { all, fork } from 'redux-saga/effects';
import { watchGetMyInfo, watchUpdateMyInfo, watchUpdatePassword } from './Authentication';
import { watchGetAllClients, watchGetSchools } from './Client';
import { watchGetAlerts, watchShowInterest, watchGetBlogs, watchUpdateBlog, watchGetBlockedSchedules, watchBlockSchedule, watchdeleteBlockSchedule, watchGetCategories, watchGetTopics } from './Other';
import { watchGetPaymentHistory, watchGetMonthlyReport, watchGetBankAccount, watchSetBankAccount, watchWithdrawMoney } from './Payment';
import { watchGetPendingLesson, watchGetCompletedLesson, watchCancelLesson, watchGetAllCourses, watchScheduleLesson, watchLeaveNote, watchgetAvailability } from './Lesson';
import { watchgetMessageUserList, watchgetMessageDetail, watchSendMessage } from './Chat';
import { watchGetAllCities } from './City';
import { watchGetLevels } from './Level';


export default function* rootSaga() {
    yield all([
        fork(watchGetMyInfo),
        fork(watchUpdateMyInfo),
        fork(watchUpdatePassword),

		fork(watchGetAllClients),
		fork(watchGetSchools),

        fork(watchGetAlerts),
		fork(watchShowInterest),
		fork(watchGetCategories),
		fork(watchGetTopics),
        fork(watchGetBlogs),
		fork(watchUpdateBlog),
		fork(watchGetBlockedSchedules),
		fork(watchBlockSchedule),
		fork(watchdeleteBlockSchedule),

        fork(watchGetPendingLesson),
        fork(watchGetCompletedLesson),
        fork(watchCancelLesson),
        fork(watchGetAllCourses),
        fork(watchGetLevels),
        fork(watchScheduleLesson),
        fork(watchLeaveNote),
        fork(watchgetAvailability),        

        fork(watchGetPaymentHistory),
        fork(watchGetMonthlyReport),
        fork(watchGetBankAccount),
        fork(watchSetBankAccount),
        fork(watchWithdrawMoney),

        fork(watchgetMessageUserList),
        fork(watchgetMessageDetail),
        fork(watchSendMessage),

        fork(watchGetAllCities),        
    ]);
}