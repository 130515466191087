import React from 'react';
import './SettingsPage.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg';
import bin from '../../Assets/Icon/rubbish-bin.svg';
import add from '../../Assets/Icon/add.svg';
import tick from '../../Assets/Icon/tick.svg';
import { InputBox, SelectBox, SearchBox, CheckBox, Course } from '../../Components';
import { updateMyInfo, getSchoolList, toggleQualificationModal, getAllCourses, getAllCities, getLevels } from '../../Redux/Actions';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/scss/react-flags-select.scss';
import Helper from '../../Utils/Helper';
import moment from 'moment';
import _ from 'lodash'

import Media from 'react-media';
import { CarouselLeftButton, CarouselRightButton } from '../../Constant';
import Loadable from '@loadable/component';
const OwlCarousel = Loadable(() => import('react-owl-carousel'));

const Degress = [
	'', 'Bachelor', 'Masters', 'PhD', 'Dr',
]

const Menus = [
	'PROFILE', 'COURSES', 'EDUCATION', 'QUALIFICATIONS', 'AVAILABILITY', 'PASSWORD'
  ]

const HelpParams = [
  'available', 'unavailable'
];

const Hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

const WeekData = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

class SettingsPage extends React.Component {
	state = {
		menu: 'PROFILE',

		user: {},
		photo: '',

		city: '',
		region_id: '',

		classifications: [],

		schedule_data: [],
		last_password: '',
		new_password: '',
		current_level: 2,
		current_program_type: 'course'
	}
	
	componentDidMount() {
        //if (this.props.levels.length === 0) {
            this.props.getLevels();
        //};
		if(_.isEmpty(this.props.school_list)){
			this.props.getSchoolList();
		}
		//if(_.isEmpty(this.props.course_list)){
			this.props.getAllCourses();
		//}
		if (this.props.location.search && this.props.location.search.length > 0) {
			let section = this.props.location.search.split("=")[1];
			this.setState({
				menu: section.toUpperCase()
			})
		}
		
		const { user } = this.props;
		
		this.props.getAllCities('', user.region_id);

		let schedule_data = [];
		user.schedule.map((data, index)=>{
			schedule_data[index] = {...data, selected: false, status: "unavailable"}
		});
		

		this.setState({
			user: { 
				...user, 
				country_code: user.country_code || 'US',
			},
			classifications: user.classifications,
			photo: _.isEmpty(user.picture) ? '' :`${process.env.REACT_APP_IMAGE_DOMAIN}${user.picture}`,
			schedule_data: schedule_data,
			region_id: user.region_id
		})
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.search !== prevProps.location.search) {
			let section = this.props.location.search.length > 0 ? this.props.location.search.split("=")[1] : '';
			this.setState({
				menu: section.toUpperCase()
			})
		}
		
		if (prevProps.update_info_loading && !this.props.update_info_loading) {
			if(this.props.update_info_success){
				Helper.showToast('success', 3000, "SUCCESS!")
			} else {
				Helper.showToast('error', 3000, this.props.update_info_message)				
			}
		}

		if (prevProps.get_cities_loading && !this.props.get_cities_loading) {
			const { get_cities_success, cities, user } = this.props;
			if(get_cities_success){
				if(!this.state.city){
					this.setState({city: _.find(cities, {id: Number(user.region_id)}).name})
				}
			} else {
				
			}
		}
	}

	pickFileForPhoto = (e) => {
		e.preventDefault();
		if (!e.target.files[0]) return;
		this.setState({
			photo: URL.createObjectURL(e.target.files[0]),
			user: {...this.state.user, photo: e.target.files[0]}
		});
	}

	getHour = (hour) => {
		return `${hour <= 12 ? hour : hour === 24 ? 0 : hour - 12} ${hour < 12 ? 'am' : hour === 24 ? 'am' : 'pm'}`;
	}

	checkSchedule = (d, h) => {
		let { schedule_data } = this.state;
        let currentIndex = schedule_data.findIndex(temp => temp.day === d && temp.hour === h);
        let prevIndex = schedule_data.findIndex(temp => temp.day === d && temp.hour === h - 1);
        let nextIndex = schedule_data.findIndex(temp => temp.day === d && temp.hour === h + 1);

        let last_time = null;
        let current = currentIndex === -1 ? '' : schedule_data[currentIndex].status;
        let prev = prevIndex === -1 ? '' : schedule_data[prevIndex].status;
        let next = nextIndex === -1 ? '' : schedule_data[nextIndex].status;
        if (current !== prev && current === next && current.length > 0) {
            for (let i = h + 1; i < 24; i++) {
                let tempIndex = schedule_data.findIndex(temp => temp.day === d && temp.hour === i);
                let tempVal = tempIndex === -1 ? '' : schedule_data[tempIndex].status;
                if (current === tempVal) {
                    last_time = i;
                } else {
                    break;
                };
            };
        };
        return {
            current, prev, next, last_time,
            selected: currentIndex === -1 ? false : schedule_data[currentIndex].selected,
        };
	}

	updateSchedule = (d, h, current) => {
		if (current.length > 0) return;
        let { schedule_data } = this.state;
        schedule_data.push({
            day: d,
            hour: h,
            status: 'unavailable',
            selected: false
        })
		this.setState({schedule_data})
		this.saveInfo("availability")
	}

	selectSchedule = (e, d, h, current) => {
		e.stopPropagation();
		let { schedule_data } = this.state;
        let index = schedule_data.findIndex(temp => temp.day === d && temp.hour === h);
        schedule_data[index].selected = !schedule_data[index].selected;
        this.setState({schedule_data});
	}

	delSchedule = (d, h) => {
		let { schedule_data } = this.state;
        let index = schedule_data.findIndex(temp => temp.day === d && temp.hour === h);
        schedule_data.splice(index, 1);
        this.setState({schedule_data});
        this.saveInfo("availability")
	}

	checkSelectedCount = () => {
		let { schedule_data } = this.state;
		return (schedule_data.filter(temp => temp.selected === true) || []).length;
	}

	deleteSelected = () => {
		let { schedule_data } = this.state;
        let filtered = schedule_data.filter(temp => temp.selected === true) || [];
        if (filtered.length > 0) {
            for (let item of filtered) {
                let index = schedule_data.findIndex(temp => temp.day === item.day && temp.hour === item.hour);
                schedule_data.splice(index, 1);
            }
        }
        this.setState({schedule_data});
	}
	
	updateInfo = (field, val) => {		
		this.setState({
			user: {
				...this.state.user,
				[field]: val,
			}
		})
	}

	updateCourse = (id) => {
		const { classifications } = this.state;
		const { course_list, } = this.props;
		var findIndex = _.findIndex(classifications, {course_id: id})
		if(findIndex > -1){
			classifications.splice(findIndex, 1)
		} else {
			let course_item = _.find(course_list, {id: id})
			classifications.push(
				{level_id: course_item.level_id, course_id: course_item.id, content: ''}
			)
		}
		this.setState({classifications})
	  }
	
	saveInfo = (updating_category) => {
		const { user, classifications, schedule_data, last_password, new_password, region_id } = this.state;
		let formData = new FormData();		
		
		switch (updating_category) {
			case 'profile': {
				if(user.photo){
					formData.append('picture', user.photo);
				}
				formData.append('first_name', user.first_name);
				formData.append('last_name', user.last_name);
				formData.append('email', user.email);
				formData.append('country_code', user.country_code);
				formData.append('phone', user.phone);
				formData.append('address', user.address);
				formData.append('address_zipcode', user.address_zipcode);
				formData.append('region_id', region_id );
				formData.append('fun_facts', user.fun_facts);
				formData.append('about_me', user.about_me);
				formData.append('experience', user.experience);
				formData.append('approach', user.approach);
				formData.append('zoom_link', user.zoom_link);
			}
			break;

			case 'course': {
				var deploy = {
					classifications: classifications
				}
			}
			break;

			case 'availability': {
				var deploy = {
					schedule: schedule_data
				}
			}
			break;

			case 'education': {
				formData.append('school1_id', user.school1_id);
				formData.append('major', user.major);
				formData.append('major_gpa', user.major_gpa);
				formData.append('school2_id', user.school2_id);
				formData.append('degree2', user.degree2);
				formData.append('degree2_type', user.degree2_type);
				formData.append('school3_id', user.school3_id);
				formData.append('degree3', user.degree3);
				formData.append('degree3_type', user.degree3_type);
			}
			break;
			
			case 'qualification': {
				var deploy = {
					classifications: this.props.user.classifications
				}
			}
			break;

			case 'password': {
				formData.append('old_password', last_password);
				formData.append('password', new_password);
			}
			break;			
			
			default: console.log("profile updating")
		}
		
		this.props.updateMyInfo(deploy || formData, deploy? false : true);
	}
	
	editQualification = (index, data) => {
		this.props.toggleQualificationModal(true, {index, data});
	}

	updateSkill = (item) => {
		const { user } = this.state;
		let { skills, qualifications } = user;
		let course_index = this.props.course_list.findIndex(temp => temp.id === item.id);
		let course = this.props.course_list[course_index];

		let skill_index = skills.indexOf(item.id);
		let qualification_index = qualifications.findIndex(temp => temp.title === course.name);
		if (skill_index > -1) {
			skills.splice(skill_index, 1);
			if (qualification_index > -1) {
				qualifications.splice(qualification_index, 1);
			}
		} else {
			skills.push(item.id)
			if (qualification_index === -1) {
				qualifications.push({
					title: course.name,
					description: ''
				})
			}
		}
		this.setState({ user })
	}

	inputProgress_1 = (property, value) => {
		if (value && value.id) {
		  this.setState({ [property]: value.id });
		} else {
		  this.setState({ [property]: value });
		}
	};

	cityTxt = (cityName) => {
		if(cityName.length%2 === 0){
		  this.props.getAllCities(cityName, '');
		};
	  };

	render() {
		const { menu,  user, photo, last_password, new_password, classifications, city, current_level, current_program_type } = this.state;
		const { school_list, course_list, cities } = this.props;

		let	school1_name = !_.isEmpty(school_list) && !_.isEmpty(user)  ? _.find(school_list, {id: Number(user.school1_id)}).name : '';
		let	school2_name = !_.isEmpty(school_list) && !_.isEmpty(user)  ? _.find(school_list, {id: Number(user.school2_id)}).name : '';
		let	school3_name = !_.isEmpty(school_list) && !_.isEmpty(user)  ? _.find(school_list, {id: Number(user.school3_id)}).name: '';
		
		const courses = course_list.filter(temp => (temp.level_id === current_level) && (temp.obj_type === current_program_type));
		const levels = this.props.levels.filter(level => course_list.filter(course => course.level_id == level.id && course.obj_type === current_program_type).length > 0);
		return (
			<div className="settings-page v-r">
				<div className="settings-header">
					<div className="container settings-container header-container v-c">
						{Menus.map((item, index) => <p className={`head-menu ${menu === item ? 'active' : ''}`} key={index} onClick={e => this.setState({menu: item})}>
							{item}
						</p>)}
					</div>
				</div>
				<div className="settings-body">
					<div className={`container settings-container ${menu === 'AVAILABILITY' || menu ==='COURSES' ? 'availability-container' : ''}`}>
						{menu === 'PROFILE' && <div id="section-profile" className="v-r">
							<div className="option-group v-r picture-option">
								<h2 className="option-title">Main Picture</h2>
								<div className="photo-area v-c">
									<img src={photo} className="avatar" alt="avatar" />
									<input type="file" accept="image/*" id="image_picker" style={{display:'none'}} onChange={e => this.pickFileForPhoto(e)}></input>
									<label htmlFor="image_picker"><div className="btn-2 btn-2-outline upload_btn v-c h-c">Upload Photo</div></label>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-6">
									<div className="option-group v-r">
										<h2 className="option-title">First Name</h2>
										<InputBox value={user.first_name} placeholder="Type First Name" onChange={val => this.updateInfo('first_name', val)}/>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="option-group v-r">
										<h2 className="option-title">Last Name</h2>
										<InputBox value={user.last_name} placeholder="Type Last Name" onChange={val => this.updateInfo('last_name', val)}/>
									</div>
								</div>
							</div>
							<div className="option-group v-r">
								<h2 className="option-title">Email</h2>
								<InputBox value={user.email} placeholder="Type Email" onChange={val => this.updateInfo('email', val)}/>
							</div>
							<div className="option-group v-r">
								<h2 className="option-title">Address</h2>
								<InputBox value={user.address} placeholder="Type Address" onChange={val => this.updateInfo('address', val)}/>
							</div>
							<div className="row">
								<div className="col-lg-6">
									<div className="option-group v-r">
										<h2 className="option-title">City</h2>
										{/* <InputBox value={user.city} placeholder="Type City" onChange={val => this.updateInfo('city', val)}/> */}
										<SearchBox
											placeholder="City"
											show_arrow
											options={cities || []}
											onChange={(e) =>
												this.inputProgress_1("region_id", e.id)
											}
											className={`input-box`}
											isCity={true}
											value={city}
											changeTxt={(e)=>this.cityTxt(e)}
										/>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="option-group v-r">
										<h2 className="option-title">Zipcode</h2>
										<InputBox value={user.address_zipcode} placeholder="Type Zipcode" onChange={val => this.updateInfo('address_zipcode', val)}/>
									</div>
								</div>
							</div>
							<div className="option-group v-r">
								<h2 className="option-title">Phone</h2>
								<div className="v-c">
									{user.country_code && <ReactFlagsSelect className="custom-flag" defaultCountry={user.country_code} onSelect={val => this.updateInfo('country_code', val)} searchable={true} showSelectedLabel={false} />}
									<InputBox value={user.phone} placeholder="Type Phone Number" onChange={val => this.updateInfo('phone', val)}/>
								</div>
							</div>
							<div className="option-group v-r">
								<h2 className="option-title">Fun Facts</h2>
								<textarea className="option-note" value={user.fun_facts} onChange={e => this.updateInfo('fun_facts', e.target.value)} placeholder="Type fun facts..."/>
							</div>
							<div className="option-group v-r">
								<h2 className="option-title">About me</h2>
								<textarea className="option-note" value={user.about_me} onChange={e => this.updateInfo('about_me', e.target.value)} placeholder="Type about you..."/>
							</div>
							<div className="option-group v-r">
								<h2 className="option-title">Experience</h2>
								<textarea className="option-note" value={user.experience} onChange={e => this.updateInfo('experience', e.target.value)} placeholder="Type your experience..."/>
							</div>
							<div className="option-group v-r">
								<h2 className="option-title">Approach</h2>
								<textarea className="option-note" value={user.approach} onChange={e => this.updateInfo('approach', e.target.value)} placeholder="Type your approach..."/>
							</div>
							<div className="option-group v-r">
								<h2 className="option-title">Zoom Link</h2>
								<InputBox value={user.zoom_link} placeholder="Your Zoom link" onChange={val => this.updateInfo('zoom_link', val)}/>
							</div>
							{user.first_name &&user.last_name && user.email && user.phone && user.address_zipcode && user.address && user.city && user.about_me && user.approach && user.experience && user.fun_facts?
								<button className={`btn-2 primary-btn save-btn ${this.props.update_info_loading ? 'disabled' : ''}`} onClick={e => this.saveInfo('profile')}>
									{this.props.update_info_loading ? <div className="spinner small" /> : 'Save Changes'}
								</button>
								:
								<button className="btn-2 save-btn">
									Save Changes
								</button>
							}
						</div>}
						{menu === 'EDUCATION' && <div id="section-profile" className="v-r">
							<p className="education-p">This tap allows you to showcase your educational background and degrees. You are required to upload a transcript and test prep scores if you interested in instructing test prep such as ACT, SAT, GRE, LSAT etc. The minimum GPA in your area of subject specialization is 3.0.</p>
							<div className="education-group v-r">
                                <div className="option-group v-r">
                                    <h2 className="option-title">Undergraduate</h2>
                                    <SearchBox value={school1_name} placeholder="Select Graduate University" options={school_list} onChange={val => this.updateInfo('school1_id', val.id)}/>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="option-group v-r">
                                            <h2 className="option-title">Major</h2>
                                            <InputBox value={user.major} placeholder="Major" onChange={val => this.updateInfo('major', val)}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="option-group v-r">
                                            <h2 className="option-title">Major GPA</h2>
                                            <InputBox value={user.major_gpa} placeholder="Type Major GPA" onChange={val => this.updateInfo('major_gpa', val)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="education-group v-r">
                                <div className="option-group v-r">
                                    <h2 className="option-title">Graduate School(optional)</h2>
                                    <SearchBox value={school2_name} placeholder="Select Graduate School" options={school_list} onChange={val => this.updateInfo('school2_id', val.id)}/>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="option-group v-r">
                                            <h2 className="option-title">Degree</h2>
                                            <InputBox value={user.degree2} placeholder="Degree" onChange={val => this.updateInfo('degree2', val)}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="option-group v-r">
                                            <h2 className="option-title">Select a type</h2>
                                            <SelectBox value={user.degree2_type} className="option-select" placeholder="Select a type" options={Degress} onChange={val => this.updateInfo('degree2_type', val)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="education-group v-r">
                                <div className="option-group v-r">
                                    <h2 className="option-title">Graduate School(optional)</h2>
                                    <SearchBox value={school3_name} placeholder="Select Graduate School" options={school_list} onChange={val => this.updateInfo('school3_id', val.id)}/>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="option-group v-r">
                                            <h2 className="option-title">Degree</h2>
                                            <InputBox value={user.degree3} placeholder="Degree" onChange={val => this.updateInfo('degree3', val)}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="option-group v-r">
                                            <h2 className="option-title">Select a type</h2>
                                            <SelectBox value={user.degree3_type} className="option-select" placeholder="Select a type" options={Degress} onChange={val => this.updateInfo('degree3_type', val)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {user.school1_id > 1 && user.major && user.major_gpa?
								<button className={`btn-2 primary-btn save-btn ${this.props.update_info_loading ? 'disabled' : ''}`} onClick={e => this.saveInfo('education')}>
									{this.props.update_info_loading ? <div className="spinner small" /> : 'Save Changes'}
								</button>
								:
								<button className="btn-2 save-btn">
									Save Changes
								</button>
							}
						</div>}
						{menu === 'COURSES' && <div id="section-profile" className="v-r">
							<p className="subject-p">Please select the courses and camps you want to tutor for each grade level. </p>
							<div className="checksection-component v-r">
								<div className="checksection-name v-c">
									<div className="tech-row v-c">
				                        {[{name: 'course', label: 'Courses'}, {name: 'camp', label: 'Camps'}].map((item, index) => <div className={`tech v-c h-c ${current_program_type === item.name ? 'active' : ''}`} key={index} onClick={e=>this.setState({current_program_type: item.name})}>
				                            {item.label}
				                        </div>)}
				                    </div>
				                    <div className="agegroup-row v-c">
				                        {levels.map((item, index) => <div className={`age-group ${current_level === item.id ? 'active' : ''}`} key={index} onClick={e=>this.setState({current_level: item.id})}>
				                            {item.name}
				                        </div>)}
				                    </div>
								</div>
								<div className="checksection-content">
									<Media queries={{
										lg: "(min-width: 992px)",
										md: "(min-width: 768px)",
										}}>
										{matches => (
											<OwlCarousel
												key={current_level+'-'+current_program_type}
												className="owl-theme"
												rewind
												nav
												stagePadding={5}
												margin={15}
												items={ matches.lg ? 3 : matches.md ? 2 : 1}
												dots={false}
												loop={false}
												navText={[CarouselLeftButton, CarouselRightButton]}
												>
												{ courses.map((item, index) => 
													<Course key={index} info={item} classifications={classifications} updateCourse={(e) => this.updateCourse(e)}/>
												)}
											</OwlCarousel>
										)}
									</Media>
								</div>
								{!_.isEmpty(classifications)?
									<button className={`btn-2 primary-btn save-btn ${this.props.update_info_loading ? 'disabled' : ''}`} onClick={e => this.saveInfo('course')}>
										{this.props.update_info_loading ? <div className="spinner small" /> : 'Save Changes'}
									</button>
									:
									<button className="btn-2 save-btn">
										Save Changes
									</button>
								}
							</div>
						</div>}
						{menu === 'QUALIFICATIONS' && <div id="section-profile" className="v-r">
							<p className="subject-p">This allows you to enter a brief detailed description of why you are qualified to tutor each of the subjects you have selected. We recommend a minimum of three sentences per subject qualification description. If you need more help with this section of the application, please watch the help video located on the left side of the page.</p>
							<div className="qualificationsection-component v-r">
								<div className="qualificationsection-name v-c">
									<div className="qualificationsection-title">Qualifications</div>
								</div>
								<table className="qualificationsection-table">
									<thead>
										<tr>
											<th>Course</th>
											<th>Qualification</th>
											<th>Status</th>
										</tr>
									</thead>
									<tbody>
										{(this.props.user.classifications || []).map((item, index) => <tr key={index}>
											<td>{_.find(course_list, {id: item.course_id})?.name}</td>
											<td><p onClick={e => this.editQualification(index, item)}>{item.content ? 'Edit' : 'Enter Content'}</p></td>
											<td><ReactSVG src={item.content ? tick : add} className={`svg-icon ${item.content ? 'completed' : 'incompleted'}`}/></td>
										</tr>)}
									</tbody>
								</table>
							</div>
							<button className={`btn-2 primary-btn save-btn ${this.props.update_info_loading ? 'disabled' : ''}`} onClick={e => this.saveInfo('qualification')}>
								{this.props.update_info_loading ? <div className="spinner small" /> : 'Save Changes'}
							</button>
						</div>}
						{menu === 'AVAILABILITY' && <div id="section-profile" className="availavbility-section">
							<div className="pre-setting">
								<div className="help-area">
									{HelpParams.map((item, index) => <div className="help-item v-c" key={index}>
										<div className={`help-color ${item}`} />
										<p>{item}</p>
									</div>)}
									{/* <button className={`btn-2 primary-btn save-btn ${this.props.update_info_loading ? 'disabled' : ''}`} onClick={e => this.saveInfo('profile')}>
										{this.props.update_info_loading ? <div className="spinner small" /> : 'Save Changes'}
									</button> */}
									{/* {this.checkSelectedCount() > 0 && <div className="v-c delete-all" onClick={e => this.deleteSelected()}>
										<ReactSVG src={bin} className="svg-icon" />
										<p>Delete Selected</p>
									</div>} */}
								</div>
								<p className="pre-explain">
								If you want to block certain time every week, click on that timeslot. It will auto save.
								<br /><br />
								If you want to remove a block you previously set, click on that timeslot, then click delete button.</p>
							</div>
							<div className="setting-area">
							<h3>This is your weekly availability schedule</h3>
								<table className="setting-table">
									<thead>
										<tr>
											<th className="start-col"></th>
											{WeekData.map((item, index) => <th key={index} className={`header-col ${item === moment(new Date()).format("ddd") ? 'active' : ''}`}>
												<p>{item}</p>
											</th>)}
										</tr>
									</thead>
									<tbody>
										{Hours.map((hour, hrIndex) => <tr key={hrIndex}>
											<td className="start-col">{this.getHour(hour)}</td>
                                            {WeekData.map((item, index) => {
                                                let data = this.checkSchedule(item, hour);
                                                return (
                                                <td key={index} className={`${data.current}`} onClick={e => this.updateSchedule(item, hour, data.current)}>
                                                    {data.current.length > 0 && <div onClick={e => this.selectSchedule(e, item, hour, data.current)}
                                                        className={`inner-schedule ${data.current} ${data.current === data.prev ? 'has-prev' : ''} ${data.current === data.next ? 'has-next' : ''} ${data.selected ? 'selected' : ''} `} >
                                                        {data.current !== data.prev && <p>
                                                            <span>{this.getHour(hour)} - </span>
                                                            <span>{this.getHour(data.last_time ? data.last_time + 1 : hour + 1)}</span>
                                                        </p>}
                                                        {data.selected && <div className="del-btn" onMouseDown={e => this.delSchedule(item, hour)}><ReactSVG className="svg-icon" src={bin}/></div>}
                                                    </div>}
                                                </td>)
                                            })}
                                        </tr>)}
									</tbody>
								</table>
							</div>
						</div>}
						{menu === 'PASSWORD' && <div id="section-password" className="v-r">
							<div className="option-group v-r">
								<h2 className="option-title">Last Password</h2>
								<InputBox type="password" value={last_password} placeholder="Type Last Password" onChange={val => this.setState({last_password: val})}/>
							</div>
							<div className="option-group v-r">
								<h2 className="option-title">New Password</h2>
								<InputBox type="password" value={new_password} placeholder="Type New Password" onChange={val => this.setState({new_password: val})}/>
							</div>
							{last_password && new_password?
								<button className={`btn-2 primary-btn save-btn ${this.props.update_info_loading ? 'disabled' : ''}`} onClick={e => this.saveInfo('password')}>
									{this.props.update_info_loading ? <div className="spinner small" /> : 'Save Changes'}
								</button>
								:
								<button className="btn-2 save-btn">
									Save Changes
								</button>
							}
						</div>}
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.Authentication.user,
		update_info_loading: state.Authentication.update_info_loading,
		update_info_success: state.Authentication.update_info_success,
		update_info_message: state.Authentication.update_info_message,		

		school_list: state.Client.school_list,
		course_list: state.Lesson.course_list,
        levels: state.Level.levels,

		cities: state.City.cities,
		get_cities_success: state.City.get_cities_success,
		get_cities_loading: state.City.get_cities_loading,
	}
}

export default connect(mapStateToProps, { 
	updateMyInfo, 
	getSchoolList, 
	toggleQualificationModal, 
	getAllCourses,
	getAllCities,
	getLevels
})(withRouter(SettingsPage));