import { Api } from './Api';

export function* getAlerts() {
    const jsonData = yield Api.get("/alerts");
    return jsonData;
}

export function* showInterest(data) {
    const jsonData = yield Api.post("/interests", data);
    return jsonData;
}

export function* getCategories() {
    const jsonData = yield Api.get('/blogs/categories');
    return jsonData;
}

export function* getTopics() {
    const jsonData = yield Api.get('/blogs/topics');
    return jsonData;
}

export function* getBlogs(user_id) {
    const jsonData = yield Api.get(`/blogs`);
    return jsonData;
}

export function* updateBlog(blog_id, data) {
    const jsonData = blog_id === 0 ? yield Api.post('/blogs', data, true) : yield Api.post(`/blogs/${blog_id}`, data, true);
    return jsonData;
}

export function* getBlockedSchedules(start_date, end_date) {
    const jsonData = yield Api.get(`/transactions?tran_type=self_block&start_time=${start_date}&end_time=${end_date}`);
    return jsonData;
}

export function* blockSchedule(data) {
	const jsonData = yield Api.post('/transactions', data);
	return jsonData;
}

export function* deleteBlockSchedule(id, data) {
    const jsonData = yield Api.post(`/transactions/${id}`, data, true);
    return jsonData;
}

