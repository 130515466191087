import React from 'react';
import './BlogPage.scss';
import search from '../../Assets/Icon/search.svg';
import { InputBox, SelectBox, SelectBox2 } from '../../Components';
import { ReactSVG } from 'react-svg';
import addCircle from '../../Assets/Icon/add-circular-outlined-button.svg';
import RichTextEditor from 'react-rte';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getBlogs, updateBlog, getCategories, getTopics } from '../../Redux/Actions';
import Helper from '../../Utils/Helper';

const IMAGE_WIDTH_LIMIT = 200;
const IMAGE_HEIGHT_LIMIT = 200;

class BlogPage extends React.Component {
	state = {
		searchText: '',
		depth: 0,
		selectedBlog: {},
		showBox: 'DRAFT',
		Blogs: [],
		saving: ''
	}

	componentDidMount() {
		this.props.getCategories();
		this.props.getTopics();
		this.props.getBlogs(this.props.user.id)
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.update_blog_success && this.props.update_blog_success) {
			this.props.formatTitle();
			this.setState({ selectedBlog: {}})
			this.props.getBlogs(this.props.user.id)
		}
		if (prevProps.update_blog_msg.length === 0 && this.props.update_blog_msg.length > 0) {
			Helper.showToast('error', 3000, this.props.update_blog_msg)
		}
	}

	static getDerivedStateFromProps(props, state) {
		return {
			depth: props.depth,
			Blogs: props.blog_list
		}
	}

	startNewBlog = () => {
		this.selectBlog({
			id: 0,
            title: '',
            status: 'draft',
            views: 0,
            topic: {
                id: 0,
                name: ""
            },
            category: '',
            keywords: '',
            picture: null,
            upload_blogImg: null,
            content: ''
		})
	}

	selectBlog = (item) => {
		this.setState({
			selectedBlog: {
				...item,
				content: RichTextEditor.createEmptyValue().setContentFromString(item.content, 'html')
			}
		})
		this.props.updateTitle('push', 'Blog', 2);
	}

	pickFileForPhoto = (e) => {
		e.preventDefault();
		if (!e.target.files[0]) return;

		let imageFile = e.target.files[0];
		let fr = new FileReader();
		let self = this;
		fr.onload = () => {
			let img = new Image();
			img.onload = function() {
				if (img.width < IMAGE_WIDTH_LIMIT || img.height < IMAGE_HEIGHT_LIMIT) {
					alert(`Please select image file over ${IMAGE_WIDTH_LIMIT}*${IMAGE_HEIGHT_LIMIT}`);
					return;
				}
				self.getImage(imageFile, imageFile);
			};
			img.src = fr.result;
		};
		fr.readAsDataURL(e.target.files[0]);
	}

	getImage = (img, file) => {
		const { selectedBlog } = this.state;
		this.setState({selectedBlog: {...selectedBlog, picture: URL.createObjectURL(img), upload_blogImg: img, file: file}});
	}

	saveBlog = (status) => {
		let { id, title, upload_blogImg, content, topic, picture } = this.state.selectedBlog;
		if (!topic.name || !title || !picture || !content.toString('markdown')) {
			Helper.showToast('error', 3000, "Please input all fields")
			return;
		}

        const formData = new FormData()
        if(upload_blogImg){
            formData.append('picture', upload_blogImg);
        }
        formData.append('topic_id', topic.id);
        formData.append('title', title);
        formData.append('content', content.toString('html'));
		formData.append('status', status);
		
		if (id !== 0) formData.append('_method', 'PUT');
		this.setState({ saving: status });
		this.props.updateBlog(id, formData);
	}

	onChangeEditor = (e) => {
		this.setState({
			selectedBlog: {
				...this.state.selectedBlog,
				content: e
			}
		})
	}

	render() {
		const { searchText, depth, selectedBlog, showBox, Blogs, saving } = this.state;
		const list = Blogs.filter(blog => blog.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1 && blog.status.toLowerCase() === showBox.toLowerCase());

		return (
		<div className="blog-page">
			<div className={`blog-list v-r ${depth === 0 ? 'activate' : ''}`}>
			<InputBox className="search-contact" value={searchText} icon={search} placeholder="Search..." onChange={val=>this.setState({searchText:val})} />
			<div className="filter-area v-c h-c">
				<SelectBox value={showBox} className="box-select" onChange={val => this.setState({showBox: val})} options={['DRAFT', 'PUBLISHED']}/>
				<div className="new-message v-c" onClick={e => this.startNewBlog()}> <ReactSVG src={addCircle} className="svg-icon" /> NEW BLOG </div>
			</div>
			<div className="blogs v-r scroll-area">
				{list.map((item, index) => <div className={`blog-container ${selectedBlog.id === item.id ? 'selected' : ''}`} key={index} onClick={e => this.selectBlog(item)}>
					<div className="blog v-r h-c">
						<div className="info v-r h-c">
						<div className={`name-area `}>
							<h4 className="v-c">{item.title}</h4>
						</div>
						<p className="blog-details v-c">
							<span>Status: </span>
							<span className="detail-value">&nbsp;{item.status}</span>
						</p>
						</div>
					</div>
				</div>)}
			</div>
			</div>
			<div className={`content-area v-r ${depth >= 1 ? 'activate' : ''}`}>
				{(selectedBlog.id >= 0) && <div className="content-header">{selectedBlog.id === 0 ? 'Create New Blog' : selectedBlog.title}</div>}
				{(selectedBlog.id >= 0) && <div className="content-edit v-r">
					<div className="row">
						{/* <div className="col-lg-6">
							<div className="option-group v-r">
								<h2 className="option-title">Category</h2>
								<SelectBox2 className="option-select" value={selectedBlog.cat_id} options={this.props.category_list} placeholder="Select category" onChange={val => this.setState({selectedBlog: {...selectedBlog, cat_id: val}})}/>
							</div>
						</div> */}
						<div className="col-lg-6">
							<div className="option-group v-r">
								<h2 className="option-title">Suggested Topic</h2>
								<SelectBox2 className="option-select" value={selectedBlog.topic.name} options={this.props.topic_list} placeholder="Select topic" onChange={val => this.setState({selectedBlog: {...selectedBlog, topic: val}})}/>
							</div>
						</div>
					</div>
					<div className="option-group v-r">
						<h2 className="option-title">Title</h2>
						<InputBox className="option-select" value={selectedBlog.title} placeholder="Type title" onChange={val => this.setState({selectedBlog: {...selectedBlog, title: val}})}/>
					</div>
					{/* <div className="option-group v-r">
						<h2 className="option-title">Keywords</h2>
						<InputBox className="option-select" value={selectedBlog.keywords} placeholder="Type keywords" onChange={val => this.setState({selectedBlog: {...selectedBlog, keywords: val}})}/>
					</div> */}
					<div className="option-group v-r">
						<h2 className="option-title">Upload blog cover (Minium {IMAGE_WIDTH_LIMIT}*{IMAGE_HEIGHT_LIMIT}) </h2>
						<input type="file" accept="image/*" id="image_picker" style={{display:'none'}} onChange={e => this.pickFileForPhoto(e)}></input>
						{!selectedBlog.picture ? <div className="no-image v-r v-c h-c">
							<label htmlFor="image_picker"><ReactSVG src={addCircle} className="svg-icon" /></label>
						</div> : <div className="image-container v-r">
							<img src={selectedBlog.picture} alt="selectedImg" />
							<label htmlFor="image_picker"><p>Edit</p></label>
						</div>}
					</div>
					<div className="option-group v-r">
						<h2 className="option-title">Content</h2>
						<RichTextEditor
							value={selectedBlog.content || RichTextEditor.createEmptyValue()}
							onChange={this.onChangeEditor}
						/>
					</div>
					{/* <div className="option-group v-r">
						<h2 className="option-title">Excerpt</h2>
						<textarea value={selectedBlog.excerpt} placeholder="Type excerpt" onChange={val => this.setState({selectedBlog: {...selectedBlog, excerpt: val.target.value}})}/>
					</div> */}
					<div className="option-container btn-container">
						<button className={`btn-2 btn-2-outline ${this.props.update_blog_loading ? 'disabled-btn' : ''}`} onClick={e => this.saveBlog('draft')}>
							{this.props.update_blog_loading && saving === 'draft' ? <div className="spinner small" /> : 'Save as Draft'}
						</button>
						<button className={`btn-2 primary-btn ${this.props.update_blog_loading ? 'disabled-btn' : ''}`} onClick={e => this.saveBlog('published')}>
							{this.props.update_blog_loading && saving === 'published' ? <div className="spinner small" /> : 'Submit to Review'}
						</button>
					</div>
				</div>}
			</div>
		</div>
		);
	}
}

function mapStateToProps(state) {
  return {
	category_list: state.Other.category_list,
	topic_list: state.Other.topic_list,
    blog_list: state.Other.blog_list,

    update_blog_loading: state.Other.update_blog_loading,
    update_blog_success: state.Other.update_blog_success,
    update_blog_msg: state.Other.update_blog_msg,

    user: state.Authentication.user
  }
}

export default connect(mapStateToProps, { getBlogs, updateBlog, getCategories, getTopics })(withRouter(BlogPage));