import { Api } from './Api';


export function* getPaymentHistory() {
    const jsonData = yield Api.get("/payments");
    return jsonData;
}

export function* getMonthlyReport(period) {
    const jsonData = yield Api.get("/payments?" + period);
    return jsonData;
}

export function* getBankAccount() {
    const jsonData = yield Api.get("/payments/bankaccount");
    return jsonData;
}

export function* setBankAccount(data) {
    const jsonData = yield Api.post("/payments/bankaccount", data);
    return jsonData;
}

export function* withdrawMoney(data) {
    const jsonData = yield Api.post("/payments/withdraw", data);
    return jsonData;
}