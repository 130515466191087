import React from 'react';
import './MonthlyReportPage.scss';
import { RoundButton } from '../../Components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import left from '../../Assets/Icon/left.svg';
import right from '../../Assets/Icon/right.svg';
import { getMonthlyReport } from '../../Redux/Actions';
import moment from 'moment';

class MonthlyReportPage extends React.Component {
    state = {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        data: []
    }

    static getDerivedStateFromProps(props, state) {
        return {
            data: props.monthly_report
        }
    }

    componentDidMount() {
        const { month, year } = this.state;
        this.callApi(month, year);
    }

    changeDate = (flag) => {
        let { month, year } = this.state;
        if (flag === 'previous') {
            year = month === 0 ? year - 1 : year;
            month = month === 0 ? 11 : month - 1;
        } else {
            year = month === 11 ? year + 1 : year;
            month = month === 11 ? 0 : month + 1;
        }
        this.setState({ month, year });
        this.callApi(month, year);
    }

    callApi = (month, year) => {
        let current = moment();
        current.year(year);
        current.month(month);
        const start = current.startOf('month').format("YYYY-MM-DD");
        const end = current.endOf("month").format("YYYY-MM-DD");

        let param = `fromDate=${start}&toDate=${end}`;
        this.props.getMonthlyReport(param);
    }

    getAmount = (amount) => {
        let val = parseFloat(amount);
        return val >= 0 ? `$${val.toFixed(2)}` : `-$${(-val).toFixed(2)}`;
    }

    getSum = () => {
        let sum = 0;
        for (let item of this.state.data) {
            sum += parseFloat(item.amount);
        }
        return this.getAmount(sum);
    }

    render() {
        const { month, year, data } = this.state;
        return (
            <div className="monthlyreport-page">
                <div className="content-area v-r activate">
                    <div className="week-content v-r">
                        <div className="date-area v-c h-c">
                            <RoundButton icon={left} className="arrow-btn" onClick={e => this.changeDate('previous')}/>&nbsp;&nbsp;
                            {/* <p>{Months[month]}</p>&nbsp;
                            <p>{year}</p>&nbsp;&nbsp; */}
                            <p>{moment().year(year).month(month).format("MMMM YYYY")}</p>
                            <RoundButton icon={right} className="arrow-btn" onClick={e => this.changeDate('next')}/>
                        </div>
                        <div className="data-area v-r">
                            <div className="data-header show-web-flex v-c">
                                <div className="cell cell-1">DATE</div>
                                <div className="cell cell-2">PAID WITH</div>
                                <div className="cell cell-3">DESCRIPTION</div>
                                <div className="cell cell-4">EARN</div>
                            </div>
                            <div className="data-list show-web-flex v-r">
                                {data.map((item, index) => <div className="data-row price-row" key={index}>
                                    <div className="cell cell-1">{moment(item.created_at).format("MMMM-d")}</div>
                                    <div className="cell cell-2">{item.pay_with}</div>
                                    <div className="cell cell-3">{item.description}</div>
                                    <div className="cell cell-4">{this.getAmount(item.amount)}</div>
                                </div>)}
                                <div className="data-row">
                                    <div className="cell cell-1">Total</div>
                                    <div className="cell cell-2" />
                                    <div className="cell cell-3" />
                                    <div className="cell cell-4">{this.getSum()}</div>
                                </div>
                            </div>
                            <div className="show-mobile-flex v-r">
                                {data.map((item, index) => <div className="mobile-row price-row v-r" key={index}>
                                    <div className="data-row">
                                        <div className="left-col v-r"> <div className="col-header">DATE</div>{moment(item.created_at).format("MMMM-d")}</div>
                                        <div className="right-col v-r"> <div className="col-header">PAID WITH</div>{item.pay_with}</div>
                                    </div>
                                    <div className="data-row">
                                        <div className="left-col v-r"> <div className="col-header">DESCRIPTION</div>{item.description}</div>
                                        <div className="right-col v-r"> <div className="col-header">EARN</div>{this.getAmount(item.amount)}</div>
                                    </div>
                                </div>)}
                                <div className="mobile-row price-row v-r">
                                    <div className="data-row">
                                        <div className="left-col v-r"> <div className="col-header">Total</div></div>
                                        <div className="right-col v-r"> <div className="col-header">{this.getSum()}</div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        monthly_report: state.Payment.monthly_report
    }
}

export default connect(mapStateToProps, { getMonthlyReport })(withRouter(MonthlyReportPage));