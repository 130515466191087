import React from 'react';
import './Header.scss';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg'
import hamburger from '../../Assets/Icon/hamburger.svg';
import left from '../../Assets/Icon/arrow-left.svg';
import down from '../../Assets/Icon/down-arrow.svg';
import giftbox from '../../Assets/Icon/giftbox.svg';
import { toggleMobileSidebar, toggleCreditModal, signOut } from '../../Redux/Actions';
import _ from 'lodash'

class Header extends React.Component {
    state = {
        titleArray: [],
        depth: 0,
        showAccountMenu: false,
        blured: false,
    }

    componentDidMount() {
        this.setState({
            titleArray: this.props.titleArray,
            depth: this.props.depth
        });
    };

    openSidebar = () => {
        this.props.toggleMobileSidebar(true);
    };

    static getDerivedStateFromProps(props, state) {
        return {
            titleArray: props.titleArray,
            depth: props.depth
        };
    };

    goBack = () => {
        if (this.props.match.params && Object.keys(this.props.match.params).length > 0) {
            const prevURL = "/" + this.props.match.url.split("/")[1];
            this.props.history.replace(prevURL);
        }
        this.props.updateTitle('pop');
    };

    toggleAccountMenu = () => {
        if (this.state.blured) {
            this.setState({ blured: false });
            return;
        }
        this.setState({ showAccountMenu: true });
    };

    blurMenu = () => {
        this.setState({ showAccountMenu: false, blured: true });
        setTimeout(() => {
            this.setState({ blured: false })
        }, 300);
    };

    componentDidUpdate() {
        if (this.refs.accountMenu) this.refs.accountMenu.focus();
    };

    navigateAccountMenu = (menu) => {
        this.setState({ showAccountMenu: false });
        if (menu !== 'logout') {
            if(menu.startsWith('/'))this.props.history.push(menu);
            else this.props.history.push('/profile?section=' + menu);
        } else {
            this.props.signOut();
            setTimeout(() => {
                window.location.href = `${process.env.REACT_APP_PUBLIC_DOMAIN}/signout`;
            }, 300);
        }
    };

    effortScore = () => {
        this.props.history.push('/help');
    };

    render() {
        const { titleArray, depth, showAccountMenu } = this.state;
        const { isOpen, user } = this.props;
        return (
            <div className={`header-component v-r ${!isOpen ? 'expanded' : ''}`}>
                <div className="header-wrapper v-c">
                    <div className="title-area v-c">
                        {depth > 0 ? <ReactSVG src={left} className="inactive-icon show-mobile-flex svg-icon" onClick={e => this.goBack()} />
                            : <ReactSVG src={hamburger} className="inactive-icon show-mobile-flex svg-icon" onClick={e => this.openSidebar()} />}
                        <div className="title show-web">{titleArray[0]}</div>
                        {this.props.location.pathname === '/payment-history' && <div className="credit-area show-web-flex v-c">
                            <span>Your credit</span>
                            <div className="credit-box v-c h-c">${this.props.user.credit}</div>
                        </div>}
                        <div className="title show-mobile">{titleArray[depth]}</div>
                    </div>
                    <div className="action-area v-c">
                        <div className="get-30 v-c show-web-flex" onClick={e => this.props.toggleCreditModal(true)}>
                            <p>GET $25</p>
                            <ReactSVG src={giftbox} className="svg-icon" />
                        </div>
                        <NavLink to="/schedule-lesson" className="btn-2 primary-btn show-web-flex v-c">Schedule a lesson</NavLink>
                        <div className="avatar-area v-c" onClick={e => this.toggleAccountMenu()}>
                            {!_.isEmpty(user.picture) && <img className="avatar" alt="user" src={`${process.env.REACT_APP_IMAGE_DOMAIN}${user.picture}`} />}
                            <ReactSVG src={down} className="inactive-icon" />
                            {showAccountMenu && <div className="account-menu v-r" tabIndex="0" ref="accountMenu" onBlur={e => this.blurMenu()}>
                                <p onMouseDown={e => this.navigateAccountMenu('name')}>Hi {user.name || user.first_name}!</p>
                                <p onMouseDown={e => this.navigateAccountMenu('profile')}>Profile</p>
                                <p onMouseDown={e => this.navigateAccountMenu('education')}>Education</p>
                                <p onMouseDown={e => this.navigateAccountMenu('qualifications')}>Qualifications</p>
                                <p onMouseDown={e => this.navigateAccountMenu('/block-schedule')}>Block Schedule</p>
                                <p onMouseDown={e => this.navigateAccountMenu('password')}>Password</p>
                                <p
                                    className={`${user.effort_score < 75 ? "item-red" : "item-green"}`}
                                    onMouseDown={e => this.effortScore()}>Effort score:{user.effort_score}%</p>
                                <p onMouseDown={e => this.navigateAccountMenu('logout')}>Log Out</p>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isOpen: state.Sidebar.isOpen,
        user: state.Authentication.user
    }
}

export default connect(mapStateToProps, { toggleMobileSidebar, toggleCreditModal, signOut })(withRouter(Header));
