export const TimeList = [
    {availability: true, time24: '00:00:00', value: 0},
    {availability: true, time24: '00:30:00', value: 1},
    {availability: true, time24: '01:00:00', value: 2},
    {availability: true, time24: '01:30:00', value: 3},
    {availability: true, time24: '02:00:00', value: 4},
    {availability: true, time24: '02:30:00', value: 5},
    {availability: true, time24: '03:00:00', value: 6},
    {availability: true, time24: '03:30:00', value: 7},
    {availability: true, time24: '04:00:00', value: 8},
    {availability: true, time24: '04:30:00', value: 9},
    {availability: true, time24: '05:00:00', value: 10},
    {availability: true, time24: '05:30:00', value: 11},
    {availability: true, time24: '06:00:00', value: 12},
    {availability: true, time24: '06:30:00', value: 13},
    {availability: true, time24: '07:00:00', value: 14},
    {availability: true, time24: '07:30:00', value: 15},
    {availability: true, time24: '08:00:00', value: 16},
    {availability: true, time24: '08:30:00', value: 17},
    {availability: true, time24: '09:00:00', value: 18},
    {availability: true, time24: '09:30:00', value: 19},
    {availability: true, time24: '10:00:00', value: 20},
    {availability: true, time24: '10:30:00', value: 21},
    {availability: true, time24: '11:00:00', value: 22},
    {availability: true, time24: '11:30:00', value: 23},
    {availability: true, time24: '12:00:00', value: 24},
    {availability: true, time24: '12:30:00', value: 25},
    {availability: true, time24: '13:00:00', value: 26},
    {availability: true, time24: '13:30:00', value: 27},
    {availability: true, time24: '14:00:00', value: 28},
    {availability: true, time24: '14:30:00', value: 29},
    {availability: true, time24: '15:00:00', value: 30},
    {availability: true, time24: '15:30:00', value: 31},
    {availability: true, time24: '16:00:00', value: 32},
    {availability: true, time24: '16:30:00', value: 33},
    {availability: true, time24: '17:00:00', value: 34},
    {availability: true, time24: '17:30:00', value: 35},
    {availability: true, time24: '18:00:00', value: 36},
    {availability: true, time24: '18:30:00', value: 37},
    {availability: true, time24: '19:00:00', value: 38},
    {availability: true, time24: '19:30:00', value: 39},
    {availability: true, time24: '20:00:00', value: 40},
    {availability: true, time24: '20:30:00', value: 41},
    {availability: true, time24: '21:00:00', value: 42},
    {availability: true, time24: '21:30:00', value: 43},
    {availability: true, time24: '22:00:00', value: 44},
    {availability: true, time24: '22:30:00', value: 45},
    {availability: true, time24: '23:00:00', value: 46},
    {availability: true, time24: '23:30:00', value: 47},
];