import React from 'react';
import './ScheduleLessonPage.scss';
import search from '../../Assets/Icon/search.svg';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { InputBox, Avatar, TimeZone } from '../../Components';
import { ReactSVG } from 'react-svg';
import left from '../../Assets/Icon/left.svg';
import right from '../../Assets/Icon/right.svg';
import success from '../../Assets/Icon/success.png';
import Calendar from 'react-calendar';
import moment from 'moment-timezone';
import { getAllClients, scheduleLesson, getAvailability } from '../../Redux/Actions';
import { TimeList } from './TimeList';
import Helper from '../../Utils/Helper';
import _ from 'lodash'

const TutorRepeats = [
  {id: "1", name: "", value: 0},
  {id: "2", name: "2 Weeks", value: 1},
  {id: "3", name: "3 Weeks", value: 2},
  {id: "4", name: "1 Month", value: 3},
  {id: "5", name: "2 Months", value: 7},
  {id: "6", name: "3 Months", value: 11},
  {id: "7", name: "6 Months", value: 23},
  {id: "8", name: "9 Months", value: 35},
  {id: "9", name: "1 year", value: 51},
];

class ScheduleLessonPage extends React.Component {
  state = {
    timeList: TimeList,
    Clients: [],
    searchText: '',
    depth: 0,
    selectedClient: {},
    repeat_until: 0,

    instructor_date: '',
    max_repeat_value: 0,
    selected_time: '',

    current_timezone: moment.tz.guess(true)
  }

  componentDidMount() {
    this.props.getAllClients();
    if (this.props.match.params.client_id) {
      let index = this.state.Clients.findIndex(temp => temp.id === parseInt(this.props.match.params.client_id, 10));
      if (index !== -1) {
        this.selectClient(this.state.Clients[index]);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.client_list.length === 0 && this.props.client_list.length > 0) {
      if (this.props.match.params.client_id) {
        let index = this.state.Clients.findIndex(temp => temp.id === parseInt(this.props.match.params.client_id, 10));
        if (index !== -1) {
          this.selectClient(this.state.Clients[index]);
        }
      }
    }

    if (prevProps.schedule_lesson_loading && !this.props.schedule_lesson_loading) {
      if(this.props.schedule_lesson_success){
        Helper.showToast('success', 3000, this.props.schedule_lesson_message)
        this.props.updateTitle('push', 'Schedule a lesson', 3);
      } else {
        Helper.showToast('error', 3000, this.props.schedule_lesson_message)
        return;
      }
    }

    if (prevProps.get_availability_loading && !this.props.get_availability_loading) {
      Helper.hideSpinner();
      const {availabilityTime} = this.props
      const { timeList } = this.state
      if(availabilityTime){
        timeList.map((data, index)=>{
          timeList[index].availability = true
          availabilityTime.availability.map((availability_data, availability_index)=>{
            if(data.value === availability_index && !availability_data){
              timeList[index].availability = false
            }
          })
        })
        this.setState({timeList})
      }
    };
  }

  formatInitialData = () => {
    this.setState({
      instructor_date: '',
      selected_time: '',
    })
  }

  static getDerivedStateFromProps(props, state) {
    return {
      depth: props.depth,
      Clients: props.client_list
    }
  }

  selectClient = async (instructor) => {
    let temp = instructor || {};
    this.setState({ 
      selectedClient: temp,
    });
    this.formatInitialData();
    await this.props.formatTitle();
    this.props.updateTitle('push', 'Schedule a lesson', 2);
  }

  selectTimezone = (val) => {
    this.setState({current_timezone: val})
    this.getAvailabilityTime(moment(this.state.instructor_date).format('YYYY-MM-DD'), val);
  };

  selectDate = (date) => {
    if(Math.floor((new Date() - new Date(date))) / 1000 <= 24*3600 ){
      let max_repeat_value = 0;
      if(this.state.selectedClient.can_book_until_date){
        max_repeat_value = Math.floor((new Date(this.state.selectedClient.can_book_until_date) - new Date(date)) / (7*24*3600*1000));
        if(max_repeat_value < 0)max_repeat_value = 0;
      }
      this.setState({ 
        instructor_date : date,
        max_repeat_value: max_repeat_value,
        selected_time: '',
      });
      this.getAvailabilityTime(moment(date).format('YYYY-MM-DD'), this.state.current_timezone);
    } else {
      this.setState({        
        instructor_date : '',
        selected_time: '',
      })
    };
  }

  getAvailabilityTime = (day, timezone) => {
    Helper.showSpinner()
    const deploy ={
        tutor_id: this.state.selectedClient.id,
        day: day,
        timezone: moment.tz(moment.utc(), timezone).utcOffset()/60
    }
    this.props.getAvailability(deploy);
  };

  doSchedule = (repeat_until = 0) => {
    const { selectedClient, instructor_date, selected_time, current_timezone } = this.state;
    // let temp = moment(instructor_date);
    // temp.set({'hour': selected_time.time.split(":")[0], 'minute': selected_time.time.split(":")[1]});

    // let tz_offset = moment.tz.zone(current_timezone).utcOffset(new Date().getMilliseconds()) / 60;
    // let param = {
    //   student_id: selectedClient.id,
    //   start_time: temp.format("YYYY-MM-DD HH:mm:ss"),
    //   timezone: tz_offset
    // }
    // this.props.scheduleLesson(param);

    const formData = new FormData()
    formData.append('student_id', selectedClient.id);
    formData.append('sched', `${moment(instructor_date).format('YYYY-MM-DD')} ${selected_time}`);
    formData.append('timezone', moment.tz(moment.utc(), current_timezone).utcOffset()/60);
    formData.append('hours', 1);
    formData.append('repeat_until', repeat_until);     
    formData.append('client_note', '');
    this.props.scheduleLesson(formData)
    
    this.setState({ repeat_until });
  }

  render() {
    const { searchText, depth, selectedClient, Clients, instructor_date, selected_time, current_timezone, timeList, repeat_until, max_repeat_value } = this.state;
    const list = Clients.filter(client => client.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 && client.group_class == '');
    const groupclasses = [];
    const classnames = [];
    for(const client of Clients.filter(client => client.group_class != ''))if(!classnames.includes(client.group_class)){
      groupclasses.push(client);
      classnames.push(client.group_class);
    }
    return (
      <div className="schedulelesson-page">
        <div className={`instructor-list v-r ${depth === 0 ? 'activate' : ''}`}>
          <InputBox className="search-contact" value={searchText} icon={search} placeholder="Search..." onChange={val=>this.setState({searchText:val})} />
          <div className="instructors v-r scroll-area">
            {list.map((item, index) => <div className={`instructor-container ${selectedClient.id === item.id ? 'selected' : ''}`} key={index} onClick={e => this.selectClient(item)}>
              <div className="instructor v-c">
                <Avatar className="avatar" info={item} />
                <div className="info">
                  <h4>{item.name}</h4>
                  { item.tutor_can_book !== 1 && <p>Cannot book</p> }
                  { item.tutor_can_book === 1 && item.can_book_trial == 1 && <p>Trial available</p> }
                </div>
              </div>
            </div>)}
            {groupclasses.map((item, index) => <div className={`instructor-container ${selectedClient.id === item.id ? 'selected' : ''}`} key={'groupclass'+index} onClick={e => this.selectClient(item)}>
              <div className="instructor v-c">
                <Avatar className="avatar" info={item} />
                <div className="info">
                  <h4>{item.group_class}</h4>
                  <p>Group class</p>
                </div>
              </div>
            </div>)}
          </div>
        </div>
        <div className={`content-area scroll-area v-r ${depth >= 1 ? 'activate' : ''}`}>
          { selectedClient.tutor_can_book !== 1 && <h3>You cannot book on this student's behalf</h3> }
          { selectedClient.can_book_trial == 1 && <h3>Book a trial lesson with this student</h3> }
          { selectedClient.tutor_can_book === 1 && depth === 1 && <div className="date-content">
            <div className="date-select-area v-r">
              <Calendar
                className="calendar"
                onChange={this.selectDate}
                value={instructor_date || new Date()}
                calendarType="US"
                prevLabel={<ReactSVG src={left} className="svg-icon" />}
                nextLabel={<ReactSVG src={right} className="svg-icon" />}
              />
              <TimeZone value={current_timezone} onChange={val => this.selectTimezone(val)}/>
            </div>
            {instructor_date.toString().length > 0 && <div className="time-select v-r">
              <div className="time-header v-c v-r">
                <h1>{moment(instructor_date).format('dddd, MMMM D, YYYY')}</h1>
                <p>Please select start time</p>
              </div>
              <div className="time-list v-r">
                {timeList.map((item, index) => 
                  // _.find(this.props.user.schedule, {day: moment(instructor_date).format("ddd"), hour: item.value})
                  !item.availability
                  ? null
                  : <div className={`time-container v-c ${selected_time === item.time24 ? 'active' : ''}`} key={index}>
                    <div className="time-item sub-item v-c h-c" onClick={e => this.setState({selected_time: item.time24})}>{moment(item.time24, ["HH:mm"]).format("hh:mm A")}</div>
                    {TutorRepeats.filter(item => (!selectedClient.can_book_trial && item.value <= max_repeat_value) || (selectedClient.can_book_trial && item.value == 0)).map(item =>
                    <div className={`confirm-item sub-item v-c h-c ${this.props.schedule_lesson_loading ? 'disabled' : ''}`} onClick={e => this.doSchedule(item.value)}>
                      {this.props.schedule_lesson_loading && repeat_until == item.value ? <div className="spinner" /> : (selectedClient.can_book_trial ? 'Book Trial Lesson' : 'Book '+item.name)}
                    </div>
                    )}
                  </div>
                )}
              </div>
            </div>}
          </div>}
          {depth >= 2 && <div className="schedule-options v-r">
            <div className="selected-date v-c">
              <ReactSVG src={left} className="svg-icon show-web-flex" onClick={e => this.props.updateTitle('pop')} />
              <h2>{moment(instructor_date).format('dddd, MMMM D')}, Start Time: {selected_time} {current_timezone}</h2>
            </div>
            <div className="success-area v-c h-c">
              <div className="success-content v-r h-c v-c">
                <h2>Success!</h2>
                <img src={success} alt="success" />
                <p>Thanks for scheduling a lesson. Your student will be notify by email and SMS.</p>
              </div>
            </div>
          </div>}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
		user: state.Authentication.user,

    client_list: state.Client.client_list,
    schedule_lesson_success: state.Lesson.schedule_lesson_success,
    schedule_lesson_loading: state.Lesson.schedule_lesson_loading,
    schedule_lesson_message: state.Lesson.schedule_lesson_message,

    get_availability_loading: state.Lesson.get_availability_loading,
    availabilityTime: state.Lesson.availabilityTime,
  }
}

export default connect(mapStateToProps, { 
  getAllClients,
  scheduleLesson,
  getAvailability
})(withRouter(ScheduleLessonPage));