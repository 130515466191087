import { MOBILE_SIDEBAR_TOGGLE, SIDEBAR_TOGGLE, COURSE_SIDEBAR_TOGGLE } from '../Type';

export const toggleMobileSidebar = (isMobileOpen) => {
  return {
    type: MOBILE_SIDEBAR_TOGGLE,
    data: { isMobileOpen }
  }
}

export const toggleSidebar = (isOpen) => {
  return {
    type: SIDEBAR_TOGGLE,
    data: { isOpen }
  }
}

export const toggleCourseSidebar = (isCourseOpen, id = 0) => {
  return {
    type: COURSE_SIDEBAR_TOGGLE,
    data: { isCourseOpen, id }
  }
}