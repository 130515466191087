import React from 'react';
import './CourseDetail.scss';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleCourseSidebar } from '../../Redux/Actions';
import left from '../../Assets/Icon/left.svg';
import out from '../../Assets/Icon/out.svg';
import confirm from '../../Assets/Icon/confirm.svg';
import { ReactSVG } from 'react-svg';
import { CourseCategories } from '../../Constant';
import Media from 'react-media';
import Loadable from '@loadable/component';
import { CarouselLeftButton, CarouselRightButton } from '../../Constant';
import $ from 'jquery';
const OwlCarousel = Loadable(() => import('react-owl-carousel'));

function innerFunc(item) {
    return {__html: item}
}

class CourseDetail extends React.Component {
    state = {
        display_count: 7,
        selected_img: '',
        imgIndex: 0,
        CourseCategories: CourseCategories
    }

    closeSidebar = () => {
        this.props.toggleCourseSidebar(false);
    }

    clickImage = async (img, index, margin) => {
        const itemWidth = $(`#carousel${index}`)[0].offsetWidth;
        const transformValue = -Math.floor((($('.owl-stage')[0].style.transform.split("(")[1]).split(',')[0]).slice(0,-2));
        const passWidth = itemWidth + margin;
        const passedCount = Math.round(transformValue / passWidth);
        const itemCount = Math.round($(`#carousel${index}`)[0].offsetParent.offsetLeft / passWidth);
        const finalIndex = index - itemCount + passedCount;

        await this.setState({ selected_img: img, imgIndex: finalIndex })
        await this.setState({ selected_img: img, imgIndex: finalIndex })
    }


    render() {
        const { className, id, showHeader } = this.props;
        const { display_count, selected_img, imgIndex } = this.state;
        let index = this.props.course_list.findIndex(temp => temp.id === id);
        const info = this.props.course_list[index];
        return (
            <div className={`coursedetail-component v-r ${className || ''}`} >
                {showHeader && <div className="detail-header">
                    <div className="container v-c">
                        <div className="back-btn v-c">
                            <ReactSVG src={left} className="svg-icon" onClick={e => this.closeSidebar()}/>
                            <h1>{info.name}</h1>
                        </div>
                    </div>
                </div>}
                <div className="main-content">
                    <div className="course-content v-r">
                        <div className="container v-r">
                            <div className="content-group">
                                <h2>Description</h2>
                                <div className="group-body" dangerouslySetInnerHTML={innerFunc(info.description)} />
                                <div className="image-container selected_img">
                                    <img src={selected_img || info.images[0]} alt="selected_img" />
                                </div>
                                <Media queries={{
                                    lg: "(min-width: 992px)",
                                    md: "(min-width: 768px)",
                                    }}>
                                    {matches => (
                                        <OwlCarousel
                                            className="owl-theme"
                                            rewind
                                            nav
                                            stagePadding={0}
                                            margin={matches.lg ? 10 : matches.md ? 13 : 16}
                                            items={matches.lg ? 7 : matches.md ? 5 : 3}
                                            dots={false}
                                            navText={[CarouselLeftButton, CarouselRightButton]}
                                            startPosition={imgIndex}
                                            >
                                            { info.images.map((item, index) => <div className="image-container thumb-container" key={index}  id={`carousel${index}`}>
                                                <img src={item} className={`thumb ${selected_img === item ? 'active' : ''}`} alt="img" onClick={e => this.clickImage(item,index, matches.lg ? 10 : matches.md ? 13 : 16)}/>
                                            </div>)}
                                        </OwlCarousel>
                                    )}
                                </Media>
                                
                            </div>
                            <div className="content-group">
                                <h2>What Students Learn</h2>
                                <div className="group-body">
                                    {info.what_student_learn.map((item, index) => (index < display_count) && <div className="learn-item v-c" key={index}>
                                        <ReactSVG src={confirm} className="svg-icon" />
                                        <p>{item}</p>
                                    </div>)}
                                    {display_count < info.what_student_learn.length && <p className="show-more-btn" onClick={e => this.setState({display_count: display_count + 3})}>More</p>}
                                </div>
                            </div>
                            <div className="content-group">
                                <h2>Technical Requirements</h2>
                                <div className="group-body">
                                    <p className="raw-text">{info.technical_requirement}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`course-summary v-r ${!showHeader ? 'give-padding' : ''}`}>
                        <div className="container v-r">
                            {/* <h2 className={`${showHeader ? 'show-web' : ''}`}>{CourseCategories[categoryIndex].name}</h2> */}
                            <h2 className={`sub-summary-title ${showHeader ? 'show-web' : ''}`}>{info.name}</h2>
                            <div className="image-container">
                                <img src={info.images[0]} alt="thumb" />
                            </div>
                            <p className="summary-p">{info.short_description}</p>
                            <div className="summary-group">
                                <h3>Course Summary</h3>
                                <ul>
                                {(Array.isArray(info.summary) ? info.summary : Object.keys(info.summary)).map((item, index) => <li>
                                    {Array.isArray(info.summary) ? item : info.summary[item] + ' '+ item}
                                </li>)}
                                </ul>
                            </div>
                            <div className="summary-group">
                                <h3>Course Includes</h3>
                                {info.course_include.map((item, index) => <div className="summary-item v-c" key={index}>
                                    <ReactSVG src={confirm} className="svg-icon first-item" />
                                    <p>{item}</p>
                                </div>)}
                            </div>
                            <div className="summary-group">
                                <h3>Prerequisites</h3>
                                <div className="summary-item v-c">
                                    <p className="raw-text">{info.prerequisite}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};


function mapStateToProps(state) {
    return {
        course_list: state.Lesson.course_list,
    }
}

export default connect(mapStateToProps, { toggleCourseSidebar })(withRouter(CourseDetail));