import React from 'react';
import './MyClientsPage.scss';
import search from '../../Assets/Icon/search.svg';
import { InputBox, Avatar } from '../../Components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllClients } from '../../Redux/Actions';

class MyClientsPage extends React.Component {
    state = {
        Clients: [],
        searchText: '',
        depth: 0,
        selectedClient: {},
    }

    static getDerivedStateFromProps(props, state) {
        return {
            depth: props.depth,
            Clients: props.client_list
        }
    }

    componentDidMount() {
        this.props.getAllClients();
        if (this.props.match.params.id) {
            let index = this.state.Clients.findIndex(temp => temp.id === parseInt(this.props.match.params.id, 10));
            this.selectClient(this.state.Clients[index]);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.client_list.length === 0 && this.props.client_list.length > 0) {
            if (this.props.match.params.id) {
                let index = this.state.Clients.findIndex(temp => temp.id === parseInt(this.props.match.params.id, 10));
                if (index !== -1) {
                    this.selectClient(this.state.Clients[index]);
                }
            }
        }
    }

    selectClient = async (client) => {
        let temp = client || {};
        this.setState({ 
            selectedClient: temp,
        });
        await this.props.formatTitle();
        this.props.updateTitle('push', temp.name, 2);
    }

    scheduleLesson = (instructor_id) => {
        this.props.history.push(`/schedule-lesson/${instructor_id}`);
    }

    render() {
        const { searchText, depth, selectedClient, Clients } = this.state;
        const list = Clients.filter(client => client.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
        return (
            <div className="myinstructors-page">
                <div className={`instructor-list v-r ${depth === 0 ? 'activate' : ''}`}>
                    <InputBox className="search-contact" value={searchText} icon={search} placeholder="Search..." onChange={val=>this.setState({searchText:val})} />
                    <div className="instructors v-r scroll-area">
                        {list.map((item, index) => <div className={`instructor-container ${selectedClient.id === item.id ? 'selected' : ''}`} key={index} onClick={e => this.selectClient(item)}>
                            <div className="instructor v-c">
                                <Avatar className="avatar" info={item} />
                                <div className="info">
                                    <h4>{item.name}</h4>
                                    { item.plan_status == 'active' && <p>Subscription Active</p> }
                                    { item.group_class != '' && <p>Group class: {item.group_class}</p> }
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
                <div className={`content-area v-r ${depth >= 1 ? 'activate' : ''}`}>
                    {selectedClient.id > 0 && <div className="instructor-content v-r">
                        <div className="client-info v-c show-web-flex client-header">
                            <h4>{selectedClient.name}</h4>
                            { selectedClient.tutor_can_book === 1 &&
                            <button className="btn-2 btn-2-outline show-web-flex" style={{marginLeft:'10px'}} onClick={e => this.scheduleLesson(selectedClient.id)}>Book</button>
                            }
                        </div>
                        <div className="client-info">
                            <p className="client-property">Parent Name</p>
                            <p className="client-value">
                                {selectedClient.name}
                            </p>
                            { selectedClient.tutor_can_book === 1 &&
                            <button className="btn-2 btn-2-outline show-mobile-flex book-btn" onClick={e => this.scheduleLesson(selectedClient.id)}>Book</button>
                            }
                        </div>
                        <div className="client-info">
                            <p className="client-property">Subscription Status</p>
                            <p className="client-value">{selectedClient.plan_status}</p>
                        </div>
                        <div className="client-info">
                            <p className="client-property">Student Name</p>
                            <p className="client-value">{selectedClient.student_name}</p>
                        </div>
                        <div className="client-info">
                            <p className="client-property">Email</p>
                            <p className="client-value">{selectedClient.email}</p>
                        </div>
                        <div className="client-info">
                            <p className="client-property">Address</p>
                            <p className="client-value">{selectedClient.address}</p>
                        </div>
                        <div className="client-info">
                            <p className="client-property">Age</p>
                            <p className="client-value">{selectedClient.age}</p>
                        </div>
                        <div className="client-info v-c">
                            <p className="client-property">Phone</p>
                            <p className="client-value">{selectedClient.phone_with_country_code}</p>
                        </div>
                        <div className="client-info">
                            <p className="client-property">Grade Level</p>
                            <p className="client-value">{selectedClient.grade_level}</p>
                        </div>
                        <div className="client-info">
                            <p className="client-property">Parent Email</p>
                            <p className="client-value">{selectedClient.parent_email}</p>
                        </div>
                        <div className="client-info">
                            <p className="client-property">About Student</p>
                            <p className="client-value">{selectedClient.about_student}</p>
                        </div>
                        <div className="client-info">
                            <p className="client-property">Supervisor Info</p>
                            <div className='v-r'>
                                <p className="client-value">{selectedClient.supervisor.name}</p>
                                <p className="client-value">{selectedClient.supervisor.phone}</p>
                                <p className="client-value">{selectedClient.supervisor.email}</p>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        client_list: state.Client.client_list
    }
}

export default connect(mapStateToProps, { getAllClients })(withRouter(MyClientsPage));