import React from 'react';
import './PendingLessonPage.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { InputBox, RoundButton, SelectBox, ConfirmModal, SubmitNoteModal } from '../../Components';
import search from '../../Assets/Icon/search.svg';
import left from '../../Assets/Icon/left.svg';
import right from '../../Assets/Icon/right.svg';
import play_button from '../../Assets/Icon/play-button.svg';
import moment from 'moment';
import { ReactSVG } from 'react-svg';
import { toggleVideoModal, toggleJoinLesson, getPendingLesson, cancelLesson, leaveNote } from '../../Redux/Actions';
import Helper from '../../Utils/Helper';
import _ from 'lodash'

function innerFunc(item) {
    return {__html: item}
}

class PendingLessonPage extends React.Component {
    timer = null
    
    state = {
        id: '',
        data: [],
        
        searchText: '',
        currentPage: 1,
        totalPage: 1,
        displayCount: 10,

        depth: 0,
        selectedLesson: {},

        incomingLessonInfo: {},

        cancel_id: 0,
        instructor_note: '',
        isCancelLessonModal: false,
        isSubmitNoteModal: false,
    }

    static getDerivedStateFromProps(props, state) {
        const id = (props.match.params.id && props.match.params.id.length > 0) ? props.match.params.id : '';
        return {
            id: id,
            depth: props.depth,
            incomingLessonInfo: props.incomingLessonInfo,
            data: props.pending_lesson_list
        };
    }

    componentDidMount() {
        this.props.getPendingLesson();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }

    componentDidUpdate(prevProps, prevState) {
        const newID = this.props.match.params.id;
        if (newID && this.state.depth == 0) {
            let index = this.state.data.findIndex(item => item.id === parseInt(newID, 10));
            let lesson = this.state.data[index];
            if(lesson){
                this.setState({
                    selectedLesson: lesson,
                    depth: 1
                });
                this.props.updateTitle('push', 'Pending Lessons', 2);
            }
        }
        if (prevProps.pending_lesson_list.length !== this.props.pending_lesson_list.length) {
            this.filterData(this.state.searchText, this.state.displayCount);
        }
        if (prevProps.cancel_lesson_loading && !this.props.cancel_lesson_loading) {
            if (this.props.cancel_lesson_success) {
                Helper.showToast('success', 3000, this.props.cancel_lesson_message);
                this.props.getPendingLesson();
            } else {
                Helper.showToast('error', 3000, this.props.cancel_lesson_message);
            }
        }

        if (prevProps.leave_note_loading && !this.props.leave_note_loading) {
            if(this.props.leave_note_success){
                Helper.showToast('success', 3000, this.props.leave_note_message);
                this.props.getPendingLesson();
                this.props.history.push('/pending-lesson')
            } else {
                Helper.showToast('error', 3000, this.props.leave_note_message);
            };
        }
    }

    changeDisplayCount = (val) => {
        const { searchText } = this.state;
        this.filterData(searchText, val);
    }

    changeSearchText = (val) => {
        const { displayCount } = this.state;
        this.filterData(val, displayCount);
    }

    changeCurrentPage = (direction) => {
        const { currentPage, totalPage } = this.state;
        if ((direction === 'previous' && currentPage === 1) || (direction === 'next' && currentPage === totalPage)) return;
        this.setState({
            currentPage: direction === 'next' ? currentPage + 1 : currentPage - 1
        })
    }

    filterData = (searchText, displayCount) => {
        const { data } = this.state;
        const totalPage = Math.ceil(data.length / displayCount);

        this.setState({
            searchText: searchText,
            totalPage: totalPage,
            displayCount: displayCount,
            currentPage: 1
        })
    }

    viewItem = (e, item) => {
        this.setState({selectedLesson: item});
        this.setState({
            instructor_note: ''
        })

        e.stopPropagation();
        this.props.history.push(`/pending-lesson/${item.id}`);
    }    

    selectLesson = (e, item) => {
        e.stopPropagation();
        // if (!item.cancelled && !item.actions !== 'error' && !this.state.incomingLessonInfo.id) {
        //     this.props.toggleJoinLesson(true, {id: item.id});
        // }
    }

    navigateToInstructor = (e, instructor_id) => {
        e.stopPropagation();
        this.props.history.push(`/clients/${instructor_id}`);
    }

    formatTime = (sec) => {
        let second = sec;
        const hour = Math.floor(second / 3600);
        second = second - hour * 3600;
        const minute = Math.floor(second / 60);
        second = second - minute * 60;
        return `${hour}:${minute < 10 ? '0'+minute : minute}:${second < 10 ? '0'+second : second}`;
    }

    joinLesson = (item) => {
        window.open(item.tutor_online_link);

        // clearInterval(this.timer);
        // this.timer = null;
        // this.setState({seconds: 0});
    }

    checkLessonExpired = (lesson_date) => {
		let lesson = moment(lesson_date);
		return moment().diff(lesson) > 0;
    };

    cancelLesson = (item) => {
        this.setState({cancel_id: item.id});
        this.setState({isCancelLessonModal: true})
    }

    deleteConfirm =(deleteStatus, reason) => {
        this.setState({isCancelLessonModal: false});
        if(deleteStatus){
            const formData = new FormData();
            formData.append('action', 'cancel');
            formData.append('reason', reason);
            formData.append('_method', 'PUT');
            this.props.cancelLesson(formData, this.state.cancel_id);
        }
    }

    confirmSubmitNote = () => {
        if(this.state.instructor_note.length < 300){
            Helper.showToast('error', 3000, "Error: 300 character count or more")
        } else {
            this.setState({isSubmitNoteModal: true});
        }
    }

    submitNote = (status) => {
        const { selectedLesson, instructor_note } = this.state;
        this.setState({isSubmitNoteModal: false});
        if(status){            
            const formData = new FormData()        
            formData.append('tutor_note', instructor_note);
            formData.append('_method', "PUT");
            this.props.leaveNote(selectedLesson.id, formData);
        } else {
            this.cancelLesson(selectedLesson)
        }
    }

    closeModal = () => {
        this.setState({isCancelLessonModal: false, isSubmitNoteModal: false});
    }

    seeRecording = (item) => {
        if(item.recorded_online_link){
            window.open(item.recorded_online_link);
        }
    }

    render() {
        const { searchText, displayCount, data, currentPage, totalPage, depth, selectedLesson, cancel_id, instructor_note } = this.state;
        const startIndex = displayCount * (currentPage - 1);
        const displayList = data.slice(startIndex, startIndex + displayCount);

        const newID = (this.props.match.params.id && this.props.match.params.id.length > 0) ? this.props.match.params.id : '';

        return (
            <div className="pendinglesson-page v-r">
                {depth === 0 && <div className="options-area">
                    <InputBox className="search-input" value={searchText} icon={search} placeholder="Search..." onChange={val=>this.changeSearchText(val)} />
                    <div className="count-area v-c">
                        <RoundButton icon={left} className="show-mobile-flex" onClick={e => this.changeCurrentPage('previous')}/>
                        <p className="page-count">Page {currentPage} of {totalPage}</p>
                        <RoundButton icon={left} className="arrow-btn show-web-flex" onClick={e => this.changeCurrentPage('previous')}/>
                        <RoundButton icon={right} className="arrow-btn" onClick={e => this.changeCurrentPage('next')}/>
                        <div className="combo-area v-c">
                            <p>Items</p>
                            <SelectBox value={displayCount} onChange={val => this.changeDisplayCount(val)}/>
                        </div>
                    </div>
                </div>}
                {depth === 0 && <div className="data-area v-r">
                    <div className="data-header show-web-flex v-c">
                        <div className="cell cell-1">CLIENT</div>
                        <div className="cell cell-2">SCHEDULE</div>
                        <div className="cell cell-3">DURATION</div>
                        {/* <div className="cell cell-4">TYPE</div> */}
                        <div className="cell cell-5">CANCELLED</div>
                        <div className="cell cell-6">ACTIONS</div>
                    </div>
                    <div className="data-list show-web-flex v-r">
                        {displayList.map((item, index) => <div className={`data-row ${Math.floor((new Date(item.sched) - new Date()) / 1000) > 0 && Math.floor((new Date(item.sched) - new Date()) / 1000) < 86400 ? 'selected' : ''}`} key={index} onClick={e => this.selectLesson(e, item)} tabIndex={index}>
                            <div className="cell cell-1"><p style={{cursor:'pointer'}} onClick={e => this.navigateToInstructor(e, item.student.id)}>{item.student.name}</p></div>
                            <div className="cell cell-2"><span style={{cursor:'pointer'}} onClick={e => this.viewItem(e, item)}>{moment(item.sched).local().format('MMM D, YYYY hh:mm a')}</span></div>
                            <div className="cell cell-3">{item.hours} hr</div>
                            {/* <div className="cell cell-4">ONLINE</div> */}
                            <div className="cell cell-5">{item.cancel_time ? 'YES' : 'NO'}</div>
                            <div className="cell ">
                                { Math.floor((new Date() - new Date(item.sched)) / 1000) <= 0 && <p>{`Lesson starts ${moment(item.sched).fromNow()}`}</p>
                                    /*<div className="v-c" onClick={e => this.seeRecording(item)}>
                                        <ReactSVG src={play_button} className="svg-icon"/> {item.recorded_online_link? "See Recording" : "No link to online session"}
                                    </div>
                                    :*/
                                }
                            </div>
                            <div className="cell cell-6 v-c">
                                {(!item.cancel_time && Math.floor((new Date() - new Date(item.sched)) / 1000) > 0) && <button className="btn-2 btn-2-outline cancel-btn outline-btn" onClick={e => this.viewItem(e,item)}>Leave note</button>}
                                {Math.floor((new Date(item.sched) - new Date()) / 1000) > 0 && Math.floor((new Date(item.sched) - new Date()) / 1000) < 1800 && <button className="btn-2 btn-2-outline cancel-btn outline-btn note-btn" onClick={e => this.joinLesson(item)}>Join</button>}
                                {/*<button className={`btn-2 btn-2-outline cancel-btn outline-btn ${this.props.cancel_lesson_loading ? 'disabled' : ''}`} onClick={e => this.cancelLesson(item)}>
                                    {(cancel_id === item.id && this.props.cancel_lesson_loading) ? <div className="spinner" style={{width: '15px', height:'15px', borderWidth:'2px'}} /> : 'Cancel'}
                                </button>*/}
                            </div>
                        </div>)}
                    </div>
                    <div className="show-mobile-flex v-r">
                        {displayList.map((item, index) => <div className="mobile-row v-r" key={index}>
                            <div className="data-row">
                                <div className="left-col v-r"> <div className="col-header">CLIENT</div> <p className="active" onClick={e => this.navigateToInstructor(e, item.student.id)}>{item.student.name}</p> </div>
                                <div className="right-col v-r"> <div className="col-header">SCHEDULE</div> <p className="active" onClick={e => this.viewItem(e, item)}>{moment(item.sched).format('MMM D, YYYY hh:mm a')}</p> </div>
                            </div>
                            <div className="data-row">
                                <div className="left-col v-r"> <div className="col-header">DURATION</div> <p>{item.hours}</p> </div>
                                {/* <div className="right-col v-r"> <div className="col-header">TYPE</div> <p>ONLINE</p> </div> */}
                            </div>
                            <div className="data-row">
                                <div className="left-col v-r"> <div className="col-header">CANCELLED</div> <p>{item.cancel_time ? item.cancel_time : 'NO'}</p> </div>
                                <div className="right-col v-r"> 
                                    { Math.floor((new Date() - new Date(item.sched)) / 1000) <= 0 && <p>{`Lesson starts ${moment(item.sched).fromNow()}`}</p> }
                                 </div>
                            </div>
                            {Math.floor((new Date() - new Date(item.sched)) / 1000) >  0 && <button className="btn-2 btn-2-outline cancel-btn outline-btn" onClick={e => this.viewItem(e,item)}>Leave note</button>}
                            {Math.floor((new Date(item.sched) - new Date()) / 1000) > 0 && Math.floor((new Date(item.sched) - new Date()) / 1000) < 1800 && <button className="btn-2 btn-2-outline cancel-btn outline-btn note-btn" onClick={e => this.joinLesson(item)}>Join</button>}
                            <button className={`btn-2 btn-2-outline cancel-btn outline-btn ${this.props.cancel_lesson_loading ? 'disabled' : ''}`} onClick={e => this.cancelLesson(item)}>
                                {(cancel_id === item.id && this.props.cancel_lesson_loading) ? <div className="spinner" /> : 'Cancel'}
                            </button>
                        </div>)}
                    </div>
                </div>}
                {depth === 1 && selectedLesson && selectedLesson != {} && <div className="pending-detail v-r">
                    <h1 className="show-web">Session Detail</h1>
                    {/* {(!selectedLesson.cancelled && seconds > 0) && <div className="notification show-mobile-flex v-c">
                        <p>Online instructing starts in {this.formatTime(seconds)}</p>
                        <button className="join-btn" onClick={e => this.joinLesson()}>Join</button>
                    </div>} */}
                    <div className="detail-list v-r">
                        <div className="detail-row v-c">
                            <div className="detail-title">Client</div>
                            <div className="detail-content name"><p style={{cursor:'pointer'}} onClick={e => this.navigateToInstructor(e, selectedLesson.tutor.id)}>{selectedLesson.student.name}</p></div>
                        </div>
                        <div className="detail-row v-c">
                            <div className="detail-title">Start Time</div>
                            <div className="detail-content">{moment(selectedLesson.sched).format('MMM D, YYYY hh:mm a')}</div>
                        </div>
                        <div className="detail-row v-c">
                            <div className="detail-title">Duration</div>
                            <div className="detail-content">{selectedLesson.hours} hr</div>
                        </div>
                        {/* <div className="detail-row v-c">
                            <div className="detail-title">At home</div>
                            <div className="detail-content">Yes</div>
                        </div> */}
                        <div className="detail-row v-c">
                            <div className="detail-title">Booked on</div>
                            <div className="detail-content">{moment(selectedLesson.created_at).format('MMM D, YYYY hh:mm a')}</div>
                        </div>
                        <div className="detail-row v-c">
                            <div className="detail-title">Pay</div>
                            <div className="detail-content">${selectedLesson.amount} { selectedLesson.is_trial ? '(Trial lesson)' : ''}</div>
                        </div>
                        <div className="detail-row v-c">
                            <div className="detail-title">Cancellation</div>
                            <div className="detail-content"><button className="btn-2 btn-2-outline cancel-btn outline-btn" onClick={e => this.cancelLesson(selectedLesson)}>Cancel</button></div>
                        </div>
                    </div>
                    <h1> Description </h1>
                    <div className="group">
                        <h2>Client Note</h2>
                        <div>{ selectedLesson.client_note }</div>
                    </div>
                    <div className="group">
                        <h2>Leave your note (at least 300 characters)</h2>
                        <textarea className="textarea-note" value={instructor_note} onChange={e => this.setState({instructor_note: e.target.value})} placeholder="Note guide: &#13;&#10;1.What did you accomplished during this lesson? &#13;&#10;2.What were the student strengths and weaknesses? &#13;&#10;3.What do you have planned for your next session?"/>
                        <button className={`btn-2 primary-btn submit-btn ${this.props.leave_note_loading ? 'disabled-btn' : ''}`} onClick={e => this.confirmSubmitNote()}>
                            {this.props.leave_note_loading ? <div className="spinner small" /> : 'Submit'}
                        </button>
                    </div>
                </div>}
                <ConfirmModal
                    isCancelLessonModal={this.state.isCancelLessonModal}
                    deleteConfirm={(e, r) => this.deleteConfirm(e, r)}
                    closeModal={()=>this.closeModal()}
                    cancelTitle = "Delete lesson?"
                />
                <SubmitNoteModal
                    isSubmitNoteModal={this.state.isSubmitNoteModal}
                    submitNote={(e) => this.submitNote(e)}
                    closeModal={()=>this.closeModal()}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        incomingLessonInfo: state.Lesson.info || {},

        pending_lesson_list: state.Lesson.pending_lesson_list,

        cancel_lesson_success: state.Lesson.cancel_lesson_success,
        cancel_lesson_loading: state.Lesson.cancel_lesson_loading,
        cancel_lesson_message: state.Lesson.cancel_lesson_message,        

        leave_note_success: state.Lesson.leave_note_success,
        leave_note_loading: state.Lesson.leave_note_loading,
        leave_note_message: state.Lesson.leave_note_message,        
    }
}

export default connect(mapStateToProps, { toggleVideoModal, toggleJoinLesson, getPendingLesson, cancelLesson, leaveNote })(withRouter(PendingLessonPage));