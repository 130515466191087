import {
    GET_CLIENT_LIST, GET_CLIENT_LIST_SUCCESS, GET_CLIENT_LIST_FAIL, GET_SCHOOL_LIST_SUCCESS, GET_SCHOOL_LIST_FAIL, GET_SCHOOL_LIST,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
	getAllClients as getAllClientsApi,
	getSchools as getSchoolsApi,
} from '../../Api';

function* getAllClients(payload){
    try {
        const result = yield getAllClientsApi();
        if (result.data && result.data.length > 0) {
            yield put({ type: GET_CLIENT_LIST_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_CLIENT_LIST_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_CLIENT_LIST_FAIL, data: [] })
    }
}

export function* watchGetAllClients(){
    yield takeLatest(GET_CLIENT_LIST, getAllClients)
}

function* getSchools(payload){
    try {
        const result = yield getSchoolsApi();
        if (result.data) {
            yield put({ type: GET_SCHOOL_LIST_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_SCHOOL_LIST_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_SCHOOL_LIST_FAIL, data: [] })
    }
}

export function* watchGetSchools(){
    yield takeLatest(GET_SCHOOL_LIST, getSchools)
}