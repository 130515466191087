import { GET_MY_INFO, GET_MY_INFO_SUCCESS, GET_MY_INFO_FAIL, UPDATE_MY_INFO, UPDATE_MY_INFO_SUCCESS, UPDATE_MY_INFO_FAIL, UPDATE_PASSWORD, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAIL } from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
    getMyInfo as getMyInfoApi,
    updateMyInfo as updateMyInfoApi,
    updatePassword as updatePasswordApi,
} from '../../Api';

function* getMyInfo(payload){
    try {
        const result = yield getMyInfoApi();
        if (result && result.id > 0) {
            yield put({ type: GET_MY_INFO_SUCCESS, data: result })
        } else {
            yield put({ type: GET_MY_INFO_FAIL, data: {} })
        }
    } catch (err) {
        yield put({ type: GET_MY_INFO_FAIL, data: {} })
    }
}

export function* watchGetMyInfo(){
    yield takeLatest(GET_MY_INFO, getMyInfo)
}

function* updateMyInfo(payload){
    try {
        const result = yield updateMyInfoApi(payload.data, payload.isForm);
        if (result && result.id > 0) {
            yield put({ type: UPDATE_MY_INFO_SUCCESS, data: result })
        } else {
            yield put({ type: UPDATE_MY_INFO_FAIL, message: result.error})
        }
    } catch (err) {
        yield put({ type: UPDATE_MY_INFO_FAIL, data: {} })
    }
}

export function* watchUpdateMyInfo(){
    yield takeLatest(UPDATE_MY_INFO, updateMyInfo)
}

function* updatePassword(payload){
    try {
        const result = yield updatePasswordApi(payload.data);
        if (result && result.id > 0) {
            yield put({ type: UPDATE_PASSWORD_SUCCESS, data: result })
        } else {
            yield put({ type: UPDATE_PASSWORD_FAIL, data: {} })
        }
    } catch (err) {
        yield put({ type: UPDATE_PASSWORD_FAIL, data: {} })
    }
}

export function* watchUpdatePassword(){
    yield takeLatest(UPDATE_PASSWORD, updatePassword)
}