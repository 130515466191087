import { AUTH_UPDATE_TOKEN, GET_MY_INFO, UPDATE_MY_INFO, UPDATE_PASSWORD, UPDATE_QUALIFICATION, AUTH_SIGN_OUT } from '../Type';

export const updateToken = (token) => {
    return {
        type: AUTH_UPDATE_TOKEN,
        data: { token }
    }
}

export const getMyInfo = () => {
    return {
        type: GET_MY_INFO,
        data: {}
    }
}

export const updateMyInfo = (data, isForm) => {
    return {
        type: UPDATE_MY_INFO,
        data: data,
        isForm: isForm
    }
}

export const updatePassword = (data) => {
    return {
        type: UPDATE_PASSWORD,
        data: data
    }
}

export const updateQualification = (index, data) => {
	return {
		type: UPDATE_QUALIFICATION,
		data: { index, data }
	}
}

export const signOut = () => {
	return {
		type: AUTH_SIGN_OUT,
		data: {}
	}
}