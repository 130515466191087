import React from 'react';
import './TrainingPage.scss';
import arrowLeft from '../../Assets/Icon/arrow-left.png';
import arrowRight from '../../Assets/Icon/arrow-right.png';
import playBtn from '../../Assets/Icon/music-player-play.png';
import { RadioButton } from '../../Components';
import { Sections } from './Sections';
import { PlayArrow } from '@material-ui/icons';

const SectionIndexes = [1, 2, 3, 4, 5, 6, 7, 8, 9];


const MoveDistance = 55;

export default class TrainingPage extends React.Component {
    state = {
        section_id: 9,
        playing: false,
        SectionData : Sections,
        answers: 0,
    }

    constructor(props) {
        super(props);
        this.sectionContainer = React.createRef();
    }

    componentDidMount() {
        this.sectionContainer.current.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
        this.sectionContainer.current.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = (e) => {
        this.setState({...this.state})
    }

    showArrow = (direction) => {
        return this.sectionContainer.current && (direction === 'left' ?  this.sectionContainer.current.scrollLeft > 0 : (this.sectionContainer.current.offsetWidth + this.sectionContainer.current.scrollLeft) < this.sectionContainer.current.scrollWidth);
    }

    scrollContainer = (direction) => {
        if (direction === 'left') {
            this.sectionContainer.current.scrollTo({left: this.sectionContainer.current.scrollLeft - Math.min(this.sectionContainer.current.scrollLeft, MoveDistance), behavior: 'smooth'})
        } else {
            let remainDistance = this.sectionContainer.current.scrollWidth - (this.sectionContainer.current.offsetWidth + this.sectionContainer.current.scrollLeft);
            this.sectionContainer.current.scrollTo({left: this.sectionContainer.current.scrollLeft + Math.min(remainDistance, MoveDistance), behavior: 'smooth'})
        }
    }

    playVideo = (e) => {
        this.videoPlayer.play();
    }

    setAnswer = (qBlockIndex, question) => {
        let { section_id, SectionData } = this.state;
        let sectionIndex = SectionData.findIndex(temp => temp.id === section_id);
        let CurrentSection = SectionData[sectionIndex];
        CurrentSection.questions[qBlockIndex].answer = question;
        this.setState({ SectionData });
    }

    doSubmitAnswers = () => {
        const { SectionData } = this.state;
        let count = 0;
        for (let item of SectionData) {
            for (let question of item.questions) {
                if (question.answer.length > 0) count++;
            }
        }
        this.setState({ answers: count});
    }

    showQuestions = () => {
        let { SectionData, section_id } = this.state;
        
        if(section_id !== 9){
            this.setState({
                section_id: section_id +1, 
                playing: false
            });
        }
    }

    render() {
        const { answers, section_id, playing, SectionData } = this.state;
        const sectionIndex = SectionData.findIndex(temp => temp.id === section_id);
        const CurrentSection = SectionData[sectionIndex];
        return (
            <div className="training-page v-r v-c">
                <div className="sub-section v-r">
                    <h3>{CurrentSection.title}</h3>
                    <p>{CurrentSection.description}</p>
                    <div className="section-index-row v-c">
                        {this.showArrow('left') && <div className="arrow-btn v-r h-c v-c" onClick={e => this.scrollContainer('left')}><img src={arrowLeft} alt="arrow" /></div>}
                        <div className="section-index-container v-c" ref={this.sectionContainer}>
                            {SectionIndexes.map((item, index) => <div className={`section-index v-r v-c h-c ${section_id === item ? 'activated' : ''}`} key={index} onClick={e => this.setState({section_id: item, playing: false})}>
                                {item}
                            </div>)}
                        </div>
                        {this.showArrow('right') && <div className="arrow-btn v-r h-c v-c" onClick={e => this.scrollContainer('right')}><img src={arrowRight} alt="arrow" /></div>}
                    </div>
                    <div className="section-content v-r">
                        <p>The password for the videos is <b>"instructor"</b></p>
                        <div className="video-area">
                            <div className="player-area">
                                {/* {!playing && <img className="play-btn" onClick={e => this.playVideo(e)} src={playBtn} alt="play-btn"/>} */}
                                {!playing && 
                                    <div className="play-btn" onClick={e => this.playVideo(e)}>
                                        <PlayArrow className="play-icon"/>
                                    </div>
                                }
                                <video width="100%" height="100%" key={CurrentSection.video_src} preload="metadata" ref={c => this.videoPlayer = c} controls={playing} onPlay={e => this.setState({playing: true})} onEnded={e => this.showQuestions()}>
                                    <source src={`${CurrentSection.video_src}#t=0.01`} type="video/mp4" />
                                </video>
                            </div>
                        </div>
                        {/* <p style={{color: '#7B88A0'}}>The questions will appear after you have watched the video.</p>
                        {CurrentSection.show_questions && <React.Fragment>
                            <div className="question-area v-r">
                                {CurrentSection.questions.map((qBlock, qBlockIndex) => <div className="question-section v-r" key={qBlockIndex}>
                                    <h4>{qBlockIndex+1}) {qBlock.main}</h4>
                                    {qBlock.sub.map((question, questionIndex) => <RadioButton value={question} price={qBlock.answer} className="sub-question" key={questionIndex} onClick={e => this.setAnswer(qBlockIndex, question)}/>)}
                                </div>)}
                            </div>
                            <button className="btn-2 primary-btn" onClick={e => this.doSubmitAnswers()}>Submit</button>
                        </React.Fragment>} */}
                    </div>
                </div>
            </div>
        );
    }
}