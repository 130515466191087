import React from 'react';
import './WithdrawPage.scss';
import { InputBox, SelectBox } from '../../Components';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { getBankAccount, setBankAccount, withdrawMoney, getMyInfo } from '../../Redux/Actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Helper from '../../Utils/Helper';
import _ from 'lodash'
import { State } from '../../Constant';

const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const Days = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'];

class WithdrawPage extends React.Component {
    state = {
        depth: 0,
        YEARS: [],

        month: 'January',
        day: '01',
        year: new Date().getFullYear() - 20,
        address: '',
        city: '',
        state: this.props.user.state || "",
        zip_code: '',

        routing_number: '',
        account_number: '',
        account_type: 'CHECKING',

        withdraw_amount: '0'
    }

    componentDidMount() {
        Helper.showSpinner();
        this.props.getBankAccount();
        let currentYear = new Date().getFullYear();
        let temp = [];
        for (let i = 0; i <= 80; i++) {
            temp.push(currentYear - i);
        }
        this.setState({
			YEARS: temp,
			address: this.props.user.address,
			city: this.props.user.city,
			zip_code: this.props.user.address_zipcode
        })
    }

    // static getDerivedStateFromProps(props, state) {
    //     return {
    //         depth: props.depth
    //     };
    // }

    componentDidUpdate(prevProps) {  
        
        if (prevProps.get_bank_account_loading && !this.props.get_bank_account_loading) {
            Helper.hideSpinner();
            if(this.props.get_bank_account_success){
                const {bank_account} = this.props
                if(!_.isEmpty(bank_account)){
                    this.setState({
                        depth: 2,
                        routing_number: bank_account.routing,
                        account_number: bank_account.bankAccountId,
                    })
                }
            }
        }

        if (prevProps.set_bank_account_loading && !this.props.set_bank_account_loading) {
            if(this.props.set_bank_account_success){
                this.props.updateTitle('push', 'Withdraw');
                this.setState({depth: 2})
            } else {
                // this.props.updateTitle('push', 'Withdraw');
                Helper.showToast('error', 3000, this.props.set_bank_account_message)
            };
        }
        
        if (prevProps.withdraw_money_loading && !this.props.withdraw_money_loading) {
            if(this.props.withdraw_money_success){
                Helper.showToast('success', 3000, this.props.withdraw_money_message)
                this.props.getMyInfo();
            } else {
                Helper.showToast('error', 3000, this.props.withdraw_money_message)
            };
        }
    }

    moveToNextStep = () => {
        let { depth, address, city, state, zip_code, year, month, day, routing_number, account_number, account_type } = this.state;
        if (depth === 0) {
            if (!address|| !zip_code || !state) {
                Helper.showToast('error', 3000, "Please enter all information")
                return;
            }
            this.setState({depth: 1})
        }
        if (depth === 1) {
            if (routing_number.length === 0 || account_number.length === 0) {
                Helper.showToast('error', 3000, "Please enter all information")
                return;
            }
            let monthIndex = Months.indexOf(month) + 1;
            let date_of_birth = `${year}-${monthIndex <10 ? "0" : ""}${monthIndex}-${day}`;
            let param = {
                date_of_birth,
                address,
                city,
                state,
                zipcode: zip_code,
                bank_account: account_number,
                routing: routing_number,
                account_type: account_type
            }
            this.props.setBankAccount(param);
            return;
        }
        // this.props.updateTitle('push', 'Withdraw');
    }

    handle = (props) => {
        const { value, dragging, index, ...restProps } = props;
        const Handle = Slider.Handle;
        return (
            <Handle value={value} {...restProps}>
                <div className="inner-handle" />
            </Handle>
        );
    };

    withdraw = () => {
        if (this.state.withdraw_amount === 0) {
            Helper.showToast('error', 3000, "Please input amount")
            return;
        }
        this.props.withdrawMoney({
            amount: this.state.withdraw_amount
        })
    }

    render() {
        const { depth, month, day, year, YEARS, address, city, state, zip_code, routing_number, account_number, account_type, withdraw_amount } = this.state;
        return (
            <div className="withdraw-page v-r v-c">
                {depth === 0 && <div className="sub-section v-r" id="section-1">
                    <h3>Register your info</h3>
                    <p className="section-info name">Name: <b>{this.props.user.name || this.props.user.first_name}</b></p>
                    <div className="option-list v-r">
                        <div className="option-group v-r">
                            <h2 className="option-title">Birthdate</h2>
                            <div className="date-area">
                                <SelectBox className="option-select" value={month} options={Months} onChange={val => this.setState({month: val})}/>
                                <SelectBox className="option-select" value={day} options={Days} onChange={val => this.setState({day: val})}/>
                                {YEARS.length > 0 && <SelectBox className="option-select" value={year} options={YEARS} onChange={val => this.setState({year: val})}/>}
                            </div>
                        </div>
                        <div className="option-row">
                            <div className="option-group v-r">
                                <h2 className="option-title">Address</h2>
                                <InputBox value={address} placeholder="Type your address" onChange={val => this.setState({address: val})}/>
                            </div>
                            <div className="option-group v-r">
                                <h2 className="option-title">City</h2>
                                <InputBox value={city} placeholder="Type your city" onChange={val => this.setState({city: val})}/>
                            </div>
                        </div>
                        <div className="option-row">
                            <div className="option-group v-r">
                                <h2 className="option-title">State</h2>
                                {/* <InputBox value={state} placeholder="Type your state" onChange={val => this.setState({state: val})}/> */}
                                <SelectBox className="option-select" value={state} options={State} onChange={val => this.setState({state: val})}/>
                            </div>
                            <div className="option-group v-r">
                                <h2 className="option-title">Zip Code</h2>
                                <InputBox value={zip_code} placeholder="Type your zip code" onChange={val => this.setState({zip_code: val})}/>
                            </div>
                        </div>
                    </div>
                    <button className="btn-2 primary-btn" onClick={e => this.moveToNextStep()}>Submit</button>
                </div>}
                {depth === 1 && <div className="sub-section v-r" id="section-1">
                    <h3>Add Your Bank Account</h3>
                    <div className="option-list v-r">
                        <div className="option-group v-r">
                            <h2 className="option-title">Routing Number (9 digits)</h2>
                            <InputBox value={routing_number} placeholder="Type routing number (9 digits)" onChange={val => this.setState({routing_number: val})}/>
                        </div>
                        <div className="option-group v-r">
                            <h2 className="option-title">Bank Account Number</h2>
                            <InputBox value={account_number} placeholder="Type bank account number" onChange={val => this.setState({account_number: val})}/>
                        </div>
                        <div className="option-group v-r">
                            <h2 className="option-title">Account Type</h2>
                            <SelectBox className="option-select" value={account_type} options={['CHECKING', 'SAVINGS']} onChange={val => this.setState({account_type: val})}/>
                        </div>
                    </div>
                    <button className={`btn-2 primary-btn ${this.props.set_bank_account_loading ? 'disabled' : ''}`} onClick={e => this.moveToNextStep()}>
                        {this.props.set_bank_account_loading ? <div className="spinner small"></div> : 'Submit'}
                    </button>
                </div>}
                {depth === 2 && <div className="sub-section v-r" id="section-1">
                    <h3>Bank Account</h3>
                    <p className="section-info">Status: <b>Activated</b></p>
                    <p className="section-info name">Account #: <b>{account_number} (routing #: {routing_number})</b></p>
                    <p className="sub-title">Withdraw</p>
                    <p>
                        There is $1 fee per withdraw.<br />
                        It can take upto 3 business days for the money to get to your bank account.
                    </p>
                    <Slider 
                        min={0} 
                        max={parseFloat(this.props.user.credit - 1 )} 
                        value={withdraw_amount}
                        handle={(props) => this.handle(props)} 
                        onChange={e => this.setState({withdraw_amount: e})}>
                    </Slider>
                    <div className="option-list v-r">
                        <div className="option-group v-r">
                            <h2 className="option-title">Withdraw amount</h2>
                            <p className="mb-2">{`You have $${this.props.user.credit} in your account available for withdraw.`}</p>
                            <InputBox value={withdraw_amount} placeholder="Type withdraw amount" type="number" onChange={val => {if (val <= parseFloat(this.props.user.credit)) this.setState({withdraw_amount: val})}}/>
                        </div>
                    </div>
                    <button className={`btn-2 primary-btn ${this.props.withdraw_money_loading ? 'disabled' : ''}`} onClick={this.withdraw}>
                        {this.props.withdraw_money_loading ? <div className="spinner small"></div> : 'Withdraw'}
                    </button>
                    <p>Payment processing is done by HyperWallet. To read HW legal terms of service and privacy policy, <a href="https://www.hyperwallet.com/privacy-policy/" target="_blank" rel="noopener noreferrer">please click</a></p>
                </div>}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {        
        bank_account: state.Payment.bank_account,
        get_bank_account_success: state.Payment.get_bank_account_success,
        get_bank_account_loading: state.Payment.get_bank_account_loading,

        set_bank_account_loading: state.Payment.set_bank_account_loading,
        set_bank_account_success: state.Payment.set_bank_account_success,
        set_bank_account_message: state.Payment.set_bank_account_message,
        

        withdraw_money_loading: state.Payment.withdraw_money_loading,
        withdraw_money_success: state.Payment.withdraw_money_success,
        withdraw_money_message: state.Payment.withdraw_money_message,        
		
		user: state.Authentication.user
    }
}

export default connect(mapStateToProps, { 
    getBankAccount, 
    setBankAccount, 
    withdrawMoney,
    getMyInfo
})(withRouter(WithdrawPage));