import { 
    GET_PAYMENT_HISTORY, GET_PAYMENT_HISTORY_FAIL, GET_PAYMENT_HISTORY_SUCCESS, GET_MONTHLY_REPORT, GET_MONTHLY_REPORT_FAIL, GET_MONTHLY_REPORT_SUCCESS, 
    GET_BANK_ACCOUNT, GET_BANK_ACCOUNT_FAIL, GET_BANK_ACCOUNT_SUCCESS, SET_BANK_ACCOUNT, SET_BANK_ACCOUNT_FAIL, SET_BANK_ACCOUNT_SUCCESS, 
    WITHDRAW_MONEY, WITHDRAW_MONEY_FAIL, WITHDRAW_MONEY_SUCCESS 
} from '../Type';

const INITIAL = {
    payment_history: [],
    payment_history_success: false,
    payment_history_loading: true,

    monthly_report: [],
    monthly_report_success: false,

    bank_account: {},
    get_bank_account_loading: false,
    get_bank_account_success: false,

    set_bank_account_loading: false,
    set_bank_account_success: false,
    set_bank_account_message: '',

    withdraw_money_loading: false,
    withdraw_money_success: false,
}

export default (state = INITIAL, action) => {
    switch (action.type) {
        case GET_PAYMENT_HISTORY: {
            return {
                ...state,
                payment_history_success: false,
                payment_history_loading: true,
            }
        }
        case GET_PAYMENT_HISTORY_FAIL: {
            return {
                ...state,
                payment_history_success: false,
                payment_history_loading: false,
            }
        }
        case GET_PAYMENT_HISTORY_SUCCESS: {
            return {
                ...state,
                payment_history: action.data || [],
                payment_history_success: true,
                payment_history_loading: false,
            }
        }
        case GET_MONTHLY_REPORT: 
        case GET_MONTHLY_REPORT_FAIL: {
            return {
                ...state,
                monthly_report_success: false,
            }
        }
        case GET_MONTHLY_REPORT_SUCCESS: {
            return {
                ...state,
                monthly_report: action.data || [],
                monthly_report_success: true,
            }
        }
        case GET_BANK_ACCOUNT: {
            return {
                ...state,
                get_bank_account_success: false,
                get_bank_account_loading: true
            }
        }
        case GET_BANK_ACCOUNT_FAIL: {
            return {
                ...state,
                get_bank_account_success: false,
                get_bank_account_loading: false
            }
        }
        case GET_BANK_ACCOUNT_SUCCESS: {
            return {
                ...state,
                bank_account: action.data || {},
                get_bank_account_success: true,
                get_bank_account_loading: false
            }
        }
        case SET_BANK_ACCOUNT: {
            return {
                ...state,
                set_bank_account_loading: true,
                set_bank_account_success: false,
                set_bank_account_message: '',
            }
        }
        case SET_BANK_ACCOUNT_FAIL: {
            return {
                ...state,
                set_bank_account_loading: false,
                set_bank_account_success: false,
                set_bank_account_message: action.message,
            }
        }
        case SET_BANK_ACCOUNT_SUCCESS: {
            return {
                ...state,
                set_bank_account_loading: false,
                set_bank_account_success: true,
                set_bank_account_message: action.message,
            }
        }
        case WITHDRAW_MONEY: {
            return {
                ...state,
                withdraw_money_loading: true,
                withdraw_money_success: false,
                withdraw_money_message: '',
            }
        }
        case WITHDRAW_MONEY_FAIL: {
            return {
                ...state,
                withdraw_money_loading: false,
                withdraw_money_success: false,
                withdraw_money_message: action.message,                
            }
        }
        case WITHDRAW_MONEY_SUCCESS: {
            return {
                ...state,
                withdraw_money_loading: false,
                withdraw_money_success: true,
                withdraw_money_message: action.message,
            }
        }

        default:
            return state;
    }
}