import React from 'react';
import './AuthCheck.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateToken, getMyInfo, getAllCourses, getSchoolList } from '../../Redux/Actions';

class AuthCheck extends React.Component {
    state = {
        session_state: 0,
        token: ''
    }

    componentDidMount() {
        if (this.props.location.search && this.props.location.search.length > 0) {
            const params = new URLSearchParams(this.props.location.search);
            const token = params.get('token');

            if (token) {
                this.setState({token})
                this.props.updateToken(token);                
            }
        } else {
            this.setState({token: this.props.token});
        }
    }
    
    componentDidUpdate() {
        if (this.state.token === this.props.token && this.state.token.length > 0) {
            this.props.getMyInfo();
			this.props.getSchoolList();
            this.props.getAllCourses();
            this.props.history.push('/');
        } else {
            if (this.state.session_state === 0) {
                this.setState({session_state: 1});
            }
        }
    }

    render() {
        const { session_state } = this.state;
        return (
            <div className={`authcheck-component v-r v-c h-c ${session_state === 0 ? 'loading' : ''}`}>
                {session_state === 0 && <div className="spinner" />}
                {session_state === 1 && <p>Session ended. Please login again.</p>}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return (state.Authentication.token && state.Authentication.token.length > 0) ? {
        token: state.Authentication.token
    } : { token: ''}
}

export default connect(mapStateToProps, { 
    updateToken, 
    getMyInfo,
    getAllCourses,
    getSchoolList
})(withRouter(AuthCheck));