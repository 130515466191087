import React from 'react';
import './SearchBox.scss';
import { ReactSVG } from 'react-svg'
import down from '../../Assets/Icon/arrow-down.svg'

export default class SearchBox extends React.Component {
    state = {
        showMenu: false,
        value: '',
        className: '',
        icon: '',
        placeholder: '',
        options: [],
	}
	
	constructor(props) {
		super(props);
		this.inputRef = React.createRef();
	}

    componentDidMount() {
        this.setState({
            value: this.props.value || '',
            className: this.props.className || '',
            icon: this.props.icon || '',
            placeholder: this.props.placeholder || '',
        })
	}

	componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({value: this.props.value})
        }
    }
	
	static getDerivedStateFromProps(props, state) {
		return {
			options: props.options || []
		}
	}

    showMenu = (event, flag) => {
        event.stopPropagation();
        this.setState({
            showMenu: flag
        })
    }

    selectItem = (item) => {
        this.setState({
            value: item.name,
            showMenu: false
        })
        this.props.onChange(item);
    }

    changeText = (e) => {
        this.setState({
            value: e.currentTarget.value
        })
        if(this.props.isCity){
            this.props.changeTxt(e.target.value);
        }
    }

    render() {
        const { className, icon, placeholder, options, showMenu, value } = this.state;
        const filteredOptions = options.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) || [];
        return (
            <div className={`searchbox-component ${className}`}>
                <div className="searchbox-container">
                    {icon && <ReactSVG src={icon} className="svg-icon start-icon"/>}
					<input type="text" className="search-text" placeholder={placeholder} value={value} ref={this.inputRef}
							onFocus={e => this.showMenu(e, true)} 
							onBlur={e => this.showMenu(e, false)} 
							onChange={e => this.changeText(e)}/>
                    <ReactSVG src={down} className="svg-icon last-icon" onClick={e => {showMenu ? this.inputRef.current.blur() : this.inputRef.current.focus()}}/>
                </div>
                {showMenu && <div className="options-area"><div>
                    {filteredOptions.map((item, index) => <div className="option" key={index} onMouseDown={e => this.selectItem(item)}>
                        {item.name}
                    </div>)}
                </div></div>}
            </div> 
        );
    }
}
