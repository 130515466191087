import React from 'react';
import './MessagePage.scss';
import search from '../../Assets/Icon/search.svg';
import { InputBox, Avatar, SelectBox } from '../../Components';
import { ReactSVG } from 'react-svg';
import add from '../../Assets/Icon/add-circular-outlined-button.svg';
import cancel from '../../Assets/Icon/cancel.svg';
import moment from 'moment';
import send from '../../Assets/Icon/send.svg';
import bin from '../../Assets/Icon/rubbish-bin.svg';
import autosize from 'autosize';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getMessageUserList, getMessageDetail, sendMessage } from '../../Redux/Actions';
import Pusher from 'pusher-js';

const MessageBoxOption = [
    {id: 1, name: 'INBOX'},
    {id: 2, name: 'OUTBOX'},
]

class MessagePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Tutors: [],
            AvailableTutors: [],
            Messages: [],
            searchText: '',
            depth: 0,
            selectedTutor: {},
            showBox: 'INBOX',

            new_message: false,
            new_searchText: '',

            messageUserList: [],        
            messageData: [],
            send_message: '',
            selected_id: '',
            sender_id: '',
            sender_name: '',
            selectedUserIndex: '',
        }
    }

    componentDidMount(){
        this.props.getMessageUserList();
        this.listenForChanges();      
    }

    listenForChanges = () => {
        const token = sessionStorage.getItem('token') || '';
        var pusher = new Pusher('1ff741845f2601f639ff', {
            cluster: 'mt1',
            authEndpoint: 'https://api.icodeedu.com/api/broadcasting/auth',
            auth: {
                headers: { "Authorization": `Bearer ${token}` },
            },
        });

        var channel = pusher.subscribe(`private-App.User.${this.props.user.id}`);
        channel.bind('App\\Events\\NewMessageEvent', data => {
            this.updateMessage(data)
        });
    };

    updateMessage=(data)=> {
        if(data.sender.id === this.state.selected_id){
            this.state.messageData.splice(0, 0, data)
            this.setState({refresh: true})
        } else {
            let index = this.state.messageUserList.findIndex(temp => temp.sender.id === data.sender.id);
            this.state.messageUserList[index].unread = true;
            this.setState({refresh: true})
        }
    }

    componentDidUpdate(prevProps) {
        autosize(this.message_input);
        const {
            get_message_user_list_lodaing, get_message_user_list_success, messageUserList, user,
            get_message_detail_loading, get_message_detail_success, messageData,
            send_message_loading, send_message_success, sendMessageStatus
        } = this.props;
        if (prevProps.get_message_user_list_lodaing && !get_message_user_list_lodaing) {
            if(get_message_user_list_success){
                var newData =[];
                messageUserList.map((data, index)=>{               
                    if(data.sender.id !== user.id){
                        newData[index] = data;
                        newData[index].userItem = data.sender;
                    } else {
                        newData[index] = data;
                        newData[index].userItem = data.receiver;
                    }
                });
                
                this.setState({
                    messageUserList: newData,
                    sender_id: user.id,
                    sender_name: user.name
                })
            }
        }

        if (prevProps.get_message_detail_loading && !get_message_detail_loading) {
            if(get_message_detail_success){
                this.setState({messageData})
            }
        }
        
        let { sender_id, selectedUserIndex } = this.state;

        if (prevProps.send_message_loading && !send_message_loading) {
            console.log("===sendMessageStatus=======", sendMessageStatus)

            if(send_message_success){
                this.state.messageData.unshift(sendMessageStatus)

                this.state.messageUserList[selectedUserIndex] = sendMessageStatus
                if(sendMessageStatus.sender.id !== sender_id){
                    this.state.messageUserList[selectedUserIndex].userItem = sendMessageStatus.sender;
                } else {
                    this.state.messageUserList[selectedUserIndex].userItem = sendMessageStatus.receiver;
                }
                this.setState({
                    messageData,
                    send_message: '',
                })
            }
        }

    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.sendMessage();
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            depth: props.depth
        }
    }

    selectTutor = (tutor, index) => {
        let { Tutors, Messages } = this.state;

        this.props.getMessageDetail(tutor.id);

        this.state.messageUserList[index].unread = false

        this.setState({ 
            selected_id: tutor.id,
            Messages: Messages,
            selectedTutor: tutor,
            Tutors: Tutors,
            new_message: false,
            send_message: '',
            selectedUserIndex: index
        });
        this.props.updateTitle('push', tutor.name, 2);
    }

    startNewMessage = () => {
        this.setState({
            new_message: true,
        })
        this.props.updateTitle('push', 'New Message', 2);
    }

    deleteConversation = () => {
        let { selectedTutor, Tutors } = this.state;
        const index = Tutors.findIndex(item => item.id === selectedTutor.id);
        Tutors.splice(index, 1);
        this.setState({
            Tutors: Tutors,
            selectedTutor: {}
        })
    }

    sendMessage = () => {
        let { Tutors, Messages, selectedTutor, messageData, selected_id, send_message, sender_id, sender_name, messageUserList, selectedUserIndex } = this.state;
        if (send_message.length === 0) return;
        let created_time = moment().format('YYYY-MM-DD HH:mm:ss');

        let template = {
            sender: {id: sender_id, name: sender_name},
            created_at: {date: new Date()},
            is_new: false,
            content: send_message
        }

        const formData = new FormData()        
        formData.append('title', "title");
        formData.append('content', send_message);
        formData.append('receiver_id', selected_id);
        if(send_message){            
            this.props.sendMessage(formData);
        }
        this.message_input.focus();
    }

    showDate = (msgIndex, data) => {
        let today = moment();
        let yesterday = moment().subtract(1, 'day');
        let now = moment(data[msgIndex].created_at.data);
       
        if (msgIndex < data.length - 1) {
            let before = moment(data[msgIndex + 1].created_at.data);
            if (now.isSame(before, 'day')) return false;
        }
        
        return now.isSame(today, 'day') ? 'Today' :
               now.isSame(yesterday, 'day') ? 'Yesterday' : now.format('YYYY-MM-DD')
    }

    checkHasNewMessage = (tutor_id) => {
        let { Messages } = this.state;
        let messageIndex = Messages.findIndex(item => item.partner === tutor_id);
        if (messageIndex === -1) return false;

        let data = Messages[messageIndex].data || [];
        let index = data.findIndex(item => item.is_new === true);
        return index > -1 ? true : false;
    }

    showNewMessage = (msgIndex, data) => {
        if (!data[msgIndex].is_new || (msgIndex < data.length - 1 && data[msgIndex + 1].is_new)) return false;
        return true;
    }

    getLastMessage = (tutor_id) => {
        const { messageData } = this.state;
        let index = messageData.findIndex(item => item.partner === tutor_id);
        if (index === -1) return {};
        let data = messageData[index].data || [];
        if (data.length === 0) return {};
        const content = data[0].content;
        return { message: content[content.length - 1], created_time: data[0].created_at.date };
    }

    render() {
        const {messageUserList, messageData,  searchText, depth, selectedTutor, Tutors, showBox, new_message, new_searchText, AvailableTutors, send_message, Messages, sender_id } = this.state;
        const list = messageUserList.filter(item => item.userItem.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
        const availableList = messageUserList.filter(item => item.userItem.name.toLowerCase().indexOf(new_searchText.toLowerCase()) > -1);
        // const messages = (Messages.filter(item => item.partner === selectedTutor.id)[0] || { data: [] }).data;

        return (
            <div className="messagepage-page">
                <div className={`tutor-list v-r ${depth === 0 ? 'activate' : ''}`}>
                    <InputBox className="search-contact" value={searchText} icon={search} placeholder="Search..." onChange={val=>this.setState({searchText:val})} />
                    <div className="filter-area v-c h-c">
                        <SelectBox value={showBox} className="box-select" onChange={val => this.setState({showBox: val})} options={MessageBoxOption}/>
                        <div className="new-message v-c" onClick={e => this.startNewMessage()}> <ReactSVG src={add} className="svg-icon" /> NEW MESSAGE </div>
                    </div>
                    <div className="tutors v-r scroll-area">
                        {list.map((item, index) => 
                            <div className={`tutor-container ${selectedTutor.id === item.userItem.id ? 'selected' : ''}`} key={index} onClick={e => this.selectTutor(item.userItem, index)}>
                                <div className="tutor v-c h-c">
                                    <Avatar info={item.userItem} className="avatar" />
                                    {item.unread && <div className="unread-message"/>}
                                    <div className="info v-r h-c">
                                        <div className={`name-area v-c ${this.checkHasNewMessage(item.id) ? 'has-new' : ''}`}>
                                            <h4 className="v-c">{item.userItem.name} <span>&middot;&nbsp;{item.userItem.role}</span></h4>
                                            {/* {this.getLastMessage(item.id).created_time && <p>{moment(this.getLastMessage(item.id).created_time).format('h:mm a')}</p>} */}
                                            {item.created_at && <p>{moment(item.created_at.date).format('h:mm a')}</p>}
                                        </div>
                                        {/* {this.getLastMessage(item.id).message && <p className="last-message">{this.getLastMessage(item.id).message}</p>} */}
                                        <p className="last-message">{item.content}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={`content-area v-r ${depth >= 1 ? 'activate' : ''}`}>
                    {(new_message || selectedTutor.id > 0) && <div className="content-header show-web-flex v-c">
                        <div className="content-title"> {new_message ? 'New Message' : selectedTutor.name} </div>
                        {new_message ? <ReactSVG src={cancel} className="svg-icon" onClick={e => this.setState({new_message: false})} />
                                     : <ReactSVG src={bin} className="svg-icon" onClick={e => this.deleteConversation()}  />}
                    </div>}
                    {new_message && <div className="search-area v-c">
                        <InputBox className="search-contact" value={new_searchText} icon={search} placeholder="Find a start a conversation" onChange={val=>this.setState({new_searchText:val})} />
                    </div>}
                    {new_message ? <div className="searched-tutors v-r">
                        {availableList.map((item, index) => 
                            <div className="tutor-container" key={index} onClick={e => this.selectTutor(item.userItem, index)}>
                                <div className="tutor v-c h-c">
                                    <Avatar info={item.userItem} className="avatar" />
                                    <div className="info v-r h-c">
                                        <div className={`name-area v-c ${this.checkHasNewMessage(item.id) ? 'has-new' : ''}`}>
                                            <h4 className="v-c">{item.userItem.name} <span>&middot;&nbsp;{item.userItem.role}</span></h4>
                                        </div>
                                        <p className="last-message">{item.content}</p>
                                    </div>
                                </div>
                            </div>
                        )}</div> 
                    : selectedTutor.id > 0 && <div className="chat-area v-r">
                        <div className="message-list">
                            {messageData.length === 0 ? <div className="no-message v-c">
                                <div>Start Your Conversation with &nbsp;<span>{selectedTutor.name}</span></div></div>
                            : messageData.map((message, msgIndex) => <div className="message-container v-r" key={msgIndex}>
                                {this.showDate(msgIndex, messageData) && <div className="message-date v-c">
                                    <div className="line" />
                                    <div className="mark h-c v-c">{this.showDate(msgIndex, messageData)}</div>
                                </div>}
                                {/* {this.showNewMessage(msgIndex, messageData) && <div className="new-message v-c">
                                    <div className="line" />
                                    <div className="mark h-c v-c">New message</div>
                                </div>} */}
                                <div className="message">
                                    <Avatar className={`msg-avatar ${message.sender.id !== sender_id ? 'other' : ''}`} info={message.sender} />
                                    <div className="message-body v-r">
                                        <div className="sender">{message.sender.name}, {moment(message.created_at.date).format('hh:mm a')}</div>
                                        {/* {message.content.map((data, dataIndex) => <p key={dataIndex}>{data}</p>)} */}
                                        <p>{message.content}</p>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                        <div className="message-input v-c">
                            <textarea className="message-text" rows={1} ref={c => (this.message_input = c)} placeholder="Type Message" value={send_message} onKeyDown={this._handleKeyDown} onChange={e => this.setState({send_message:e.target.value})} />
                            <ReactSVG src={send} className="svg-icon" onClick={e => this.sendMessage()}/>
                        </div>
                    </div>}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.Authentication.user,
        
        
        messageUserList: state.Chat.messageUserList,
        get_message_user_list_success: state.Chat.get_message_user_list_success,
        get_message_user_list_lodaing: state.Chat.get_message_user_list_lodaing,

        messageData: state.Chat.messageData,
        get_message_detail_success: state.Chat.get_message_detail_success,
        get_message_detail_loading: state.Chat.get_message_detail_loading,


        sendMessageStatus: state.Chat.sendMessageStatus,
        send_message_success: state.Chat.send_message_success,
        send_message_loading: state.Chat.send_message_loading,


		user: state.Authentication.user

    }
}

export default connect(mapStateToProps, {
    getMessageUserList,
    getMessageDetail,
    sendMessage
})(withRouter(MessagePage));