import React from 'react';
import './BlockSchedulePage.scss';
import Calendar from 'react-calendar';
import left from '../../Assets/Icon/left.svg';
import right from '../../Assets/Icon/right.svg';
import { ReactSVG } from 'react-svg';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import bin from '../../Assets/Icon/rubbish-bin.svg';
import { getBlockedSchedules, blockSchedule, deleteBlockSchedule } from '../../Redux/Actions';
import _ from 'lodash'
import Helper from '../../Utils/Helper';

const HelpParams = [
    /*'booked', */'available', 'unavailable'
];

const Hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

class BlockSchedulePage extends React.Component {
    state = {
        selected_date: null,
        selected_week: null,
        week_data: [],
		schedule_data: [],
		week_schedule: '',
		saving: false,
    }

    componentDidMount() {
		Helper.showSpinner();
        this.selectDate(new Date());
	}
	
	componentDidUpdate(prevProps) {
		if (prevProps.blocked_list_loading && !this.props.blocked_list_loading) {		
			Helper.hideSpinner();
			const { blocked_list } = this.props;
			var schedule_data = [];
			if(!_.isEmpty(blocked_list)){
                blocked_list.map((data, index)=>{
                    schedule_data[index] = {
						id: data.id, 
						day: moment(data.sched).format("YYYY-MM-DD"), 
						hour: parseInt(moment(data.sched).format("HH"), 10), 
						selected: false, 
						status: "unavailable"
					}
                })
            }
            this.setState({schedule_data});
		}

		if(prevProps.block_schedule_loading && !this.props.block_schedule_loading){
			Helper.hideSpinner();
			if(this.props.block_schedule_success){
				Helper.showToast('success', 3000, this.props.block_schedule_message)
			} else {
				Helper.showToast('error', 3000, this.props.block_schedule_message)
			}
		}

		if(prevProps.delete_block_schedule_loading && !this.props.delete_block_schedule_loading){
			Helper.hideSpinner();
			if(this.props.delete_block_schedule_success){
				Helper.showToast('success', 3000, this.props.delete_block_schedule_message)
			} else {
				Helper.showToast('error', 3000, this.props.delete_block_schedule_message)
			}
		}
	}

    selectDate = (date) => {
        let week = [];
        let selected_week = moment(date).startOf('week');
        for (let i = 0; i < 7; i++) {
            let temp = moment(date).startOf('week').add(i, 'days');
            week.push({
                whole_day: temp.format("YYYY-MM-DD"),
                day_only: temp.format("D"),
                week_day: temp.format("ddd")
            })
        }
        this.setState({ 
            selected_date : date,
            selected_week : selected_week.format("YYYY-MM-DD"),
            week_data: week
        });
        if(this.state.selected_week != selected_week.format("YYYY-MM-DD")){
        	this.props.getBlockedSchedules(
        		selected_week.format("YYYY-MM-DD 00:00:00"),
        		selected_week.add(7, 'days').format("YYYY-MM-DD 00:00:00")
        	);
        }
    }

    checkSchedule = (d, h, w) => {
		let { schedule_data, week_schedule } = this.state;

		let current = '';
		let prev = '';
		let next = '';
		let has_week = false;
		
		let weekIndex = w * 24 + h;
		if (week_schedule[weekIndex] === '1') {
			has_week = true;
			current = 'booked';
			prev = h === 8 ? '' : week_schedule[weekIndex - 1] === '1' ? 'booked' : '';
			next = h === 23 ? '' : week_schedule[weekIndex + 1] === '1' ? 'booked' : '';
		}

		let currentIndex = 0;
		if (!has_week) {
			currentIndex = schedule_data.findIndex(temp => temp.day === d && temp.hour === h);
			current = currentIndex === -1 ? '' : schedule_data[currentIndex].status;
		}
		if (prev === '') {
			if (h !== 8) {
				let prevIndex = schedule_data.findIndex(temp => temp.day === d && temp.hour === h - 1);
				prev = prevIndex === -1 ? '' : schedule_data[prevIndex].status;
			}
		}
		if (next === '') {
			if (h !== 23) {
				let nextIndex = schedule_data.findIndex(temp => temp.day === d && temp.hour === h + 1);
				next = nextIndex === -1 ? '' : schedule_data[nextIndex].status;
			}
		}
        
        let last_time = null;
        
        if (current !== prev && current === next && current.length > 0) {
            for (let i = h + 1; i < 24; i++) {
				weekIndex = w * 24 + i;
				if (week_schedule[weekIndex] !== '0') {
					last_time = i;
				} else {
					let tempIndex = schedule_data.findIndex(temp => temp.day === d && temp.hour === i);
					let tempVal = tempIndex === -1 ? '' : schedule_data[tempIndex].status;
					if (current === tempVal) {
						last_time = i;
					} else {
						break;
					}
				}
            }
        }
        return {
            current, prev, next, last_time, has_week,
            selected: current === '' ? false : has_week ? false : schedule_data[currentIndex].selected,
        }
    }

    updateSchedule = (d, h, current, has_week) => {
        if (current.length > 0 || has_week) return;
        let { schedule_data } = this.state;
        schedule_data.push({
            day: d,
            hour: h,
            status: 'unavailable',
            selected: false
        })
		this.setState({schedule_data})
		this.updateBlockSchedule(d, h);
    }

    selectSchedule = (e, d, h, current, has_week) => {
        e.stopPropagation();
        if (current === 'booked' || has_week) return;
        let { schedule_data } = this.state;
        let index = schedule_data.findIndex(temp => temp.day === d && temp.hour === h);
        schedule_data[index].selected = !schedule_data[index].selected;
        this.setState({schedule_data});
    }

    delSchedule = (d, h) => {
        let { schedule_data } = this.state;
		let index = schedule_data.findIndex(temp => temp.day === d && temp.hour === h);
		let deleteId = schedule_data[index].id;
		schedule_data.splice(index, 1);
		this.setState({schedule_data, saving: false});
		this.deleteBlockSchedule(deleteId);
	}
	
	deleteSelected = () => {
        let { schedule_data } = this.state;
        let filtered = schedule_data.filter(temp => temp.selected === true) || [];
        if (filtered.length > 0) {
            for (let item of filtered) {
                let index = schedule_data.findIndex(temp => temp.day === item.day && temp.hour === item.hour);
                schedule_data.splice(index, 1);
			}
			this.setState({schedule_data, saving: false});
			let origins = filtered.filter(temp => temp.id > 0) || [];
			if (origins.length > 0) {
				let temp = [];
				for (let item of origins) {
					temp.push({
						id: item.id,
						action: 'cancel',
						reason: 'reason',
						_method: 'PUT'
					})
				}
				this.props.blockSchedule(temp)
			}
        }
	}

    getHour = (hour) => {
        return `${hour <= 12 ? hour : hour === 24 ? 0 : hour - 12} ${hour < 12 ? 'am' : hour === 24 ? 'am' : 'pm'}`;
    }

    checkSelectedCount = () => {
        let { schedule_data } = this.state;
        return (schedule_data.filter(temp => temp.selected === true) || []).length;
	}
	
	checkSaveCount = () => {
		const { schedule_data } = this.state;
		let newOnes = schedule_data.filter(item => item.id === 0) || [];
		return newOnes.length > 0;
	}

	updateBlockSchedule = (d, h) => {
        Helper.showSpinner();
        var deploy = {
            student_id: this.props.user.id,
            timezone: moment.tz(moment.utc(), moment.tz.guess(true)).utcOffset()/60 ,
            sched: `${d} ${h}:00`, 
            hours: 1,
            client_note: "note"
        }
        this.props.blockSchedule(deploy);
    }

	deleteBlockSchedule = (deleteId) => {
        Helper.showSpinner();

        const formData = new FormData();
        formData.append('action', 'cancel');
        formData.append('reason', 'reason');
        formData.append('_method', 'PUT');

        this.props.deleteBlockSchedule(formData, deleteId);
    }

    render() {
		const { selected_date, week_data, saving } = this.state;
        return (
            <div className="blockschedule-page">
                <div className="pre-setting v-r">
                    <div className="calendar-area">
                        <Calendar
                            className="sub-calendar"
                            onChange={this.selectDate}
                            value={selected_date || new Date()}
                            calendarType="US"
                            prevLabel={<ReactSVG src={left} className="svg-icon" />}
                            nextLabel={<ReactSVG src={right} className="svg-icon" />}
                        />
                        <div className="help-area v-r">
                            {HelpParams.map((item, index) => <div className="help-item v-c" key={index}>
                                <div className={`help-color ${item}`} />
                                <p>{item}</p>
                            </div>)}
							{/* {this.checkSaveCount() && <button className={`btn-2 primary-btn save-btn ${this.props.block_schedule_loading && saving ? 'disabled' : ''}`} onClick={e => this.saveInfo()}>
								{this.props.block_schedule_loading && saving ? <div className="spinner small" /> : 'Save Changes'}
							</button>} */}
							{/* {this.checkSelectedCount() > 0 && <div className="v-c delete-all" onClick={e => this.deleteSelected()}>
								<ReactSVG src={bin} className="svg-icon" />
								<p>Delete Selected</p>
							</div>} */}
                        </div>
                    </div>
                    <p className="pre-explain">If you can't tutor for a particular time, mark yourself as unavailable here. So your clients won't book you at that time.</p>
                </div>
                <div className="setting-area">
                    {week_data.length > 0 && <table className="setting-table">
                        <thead>
                            <tr>
                                <th className="start-col"></th>
                                {week_data.map((item, index) => <th key={index} className={`header-col ${item.whole_day === moment(selected_date).format("YYYY-MM-DD") ? 'active' : ''}`}>
                                    <p>{item.week_day}</p>
                                    <p className="show-web">{item.day_only}</p>
                                </th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {Hours.map((hour, hrIndex) => <tr key={hrIndex}>
                                <td className="start-col">{this.getHour(hour)}</td>
                                {week_data.map((item, index) => {
                                    let data = this.checkSchedule(item.whole_day, hour, index);
                                    return (
                                    <td key={index} className={`${data.current}`} onClick={e => this.updateSchedule(item.whole_day, hour, data.current, data.has_week)}>
                                        {data.current.length > 0 && <div onClick={e => this.selectSchedule(e, item.whole_day, hour, data.current, data.has_week)}
                                            className={`inner-schedule ${data.current} ${data.current === data.prev ? 'has-prev' : ''} ${data.current === data.next ? 'has-next' : ''} ${data.selected ? 'selected' : ''} `} >
                                            {data.current !== data.prev && <p>
                                                <span>{this.getHour(hour)} - </span>
                                                <span>{this.getHour(data.last_time ? data.last_time + 1 : hour + 1)}</span>
                                            </p>}
                                            {data.selected && <div className="del-btn" onMouseDown={e => this.delSchedule(item.whole_day, hour)}><ReactSVG className="svg-icon" src={bin}/></div>}
                                        </div>}
                                    </td>)
                                })}
                            </tr>)}
                        </tbody>
                    </table>}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
	return {
		blocked_list: state.Other.blocked_list,
		blocked_list_loading: state.Other.blocked_list_loading,

		block_schedule_loading: state.Other.block_schedule_loading,
		block_schedule_success: state.Other.block_schedule_success,
		block_schedule_message: state.Other.block_schedule_message,

		delete_block_schedule_loading: state.Other.delete_block_schedule_loading,
		delete_block_schedule_success: state.Other.delete_block_schedule_success,
		delete_block_schedule_message: state.Other.delete_block_schedule_message,
		
		user: state.Authentication.user
	}
}
  
export default connect(mapStateToProps, { 
	  getBlockedSchedules, 
	  blockSchedule,
	  deleteBlockSchedule
})(withRouter(BlockSchedulePage));